import React, { useState } from 'react';
import { TextField, Button, IconButton, Alert, Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import { Add, Remove, ExpandMore } from '@mui/icons-material';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import DeleteIcon from '@mui/icons-material/Delete';

const DetailAmenities = ({ formik }) => {
  const { values, setFieldValue } = formik;
  const [newArrayName, setNewArrayName] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  React.useEffect(() => {
    if (!values.preporteyInformation) {
      setFieldValue('preporteyInformation', {});
    }
  }, []);

  const handleAddArray = () => {
    if (!newArrayName) {
      setErrorMessage('Please fill in the property information');
      return;
    }
    if (!values.preporteyInformation?.[newArrayName]) {
      setFieldValue(`preporteyInformation.${newArrayName}`, ['']);
      setNewArrayName('');
      setErrorMessage('');
    }
  };

  const handleAddItem = (arrayName) => {
    setFieldValue(`preporteyInformation.${arrayName}`, [...(values.preporteyInformation?.[arrayName] || []), '']);
  };

  const handleRemoveItem = (arrayName, index) => {
    if (!values.preporteyInformation?.[arrayName]) return;
    setFieldValue(
      `preporteyInformation.${arrayName}`, 
      values.preporteyInformation[arrayName].filter((_, i) => i !== index)
    );
  };

  const handleChangeItem = (arrayName, index, value) => {
    if (!values.preporteyInformation?.[arrayName]) return;
    const newArray = [...values.preporteyInformation[arrayName]];
    newArray[index] = value;
    setFieldValue(`preporteyInformation.${arrayName}`, newArray);
  };

  const handleRemoveArray = (arrayName) => {
    if (!values.preporteyInformation) return;
    const { [arrayName]: _, ...newPreporteyInformation } = values.preporteyInformation;
    setFieldValue('preporteyInformation', newPreporteyInformation);
  };

  return (
    <div className="w-full p-4">
      <div className="flex mb-4 gap-2">
        <TextField
          value={newArrayName}
          onChange={(e) => setNewArrayName(e.target.value)}
          placeholder="New Property Information"
          variant="outlined"
          fullWidth
        />
        <Button
          variant="contained"
          className="!bg-medium-aquamarine !text-white"
          onClick={handleAddArray}
        >
          <AddCircleIcon />
        </Button>
      </div>
      {errorMessage && <Alert severity="error" className="!mb-4">{errorMessage}</Alert>}
      {values.preporteyInformation && Object.keys(values.preporteyInformation).map((arrayName) => (
        <Accordion key={arrayName} className="mb-4">
          <AccordionSummary
            expandIcon={<ExpandMore className="!text-white" />}
            aria-controls={`${arrayName}-content`}
            id={`${arrayName}-header`}
            className="!bg-[#6fd1bd] text-white"
          >
            <div className="flex gap-2 items-center">
              <span className="text-sm ml-2 bg-white text-[#6fd1bd] px-2 py-1 rounded-full">
                {values.preporteyInformation[arrayName].length}
              </span>
              <span className="text-md font-semibold">{arrayName}</span>
            </div>
          </AccordionSummary>
          <AccordionDetails>
            <div>
              <div className="flex justify-between gap-2 my-4">
                <Button 
                  variant="outlined" 
                  color="success" 
                  startIcon={<Add />} 
                  onClick={() => handleAddItem(arrayName)}
                  className="!border-medium-aquamarine !text-medium-aquamarine"
                >
                </Button>
                <Button 
                  variant="outlined" 
                  color="error" 
                  startIcon={<DeleteIcon />} 
                  onClick={() => handleRemoveArray(arrayName)}
                  className="!border-red-500 !text-red-500"
                >
                </Button>
              </div>
              {values.preporteyInformation[arrayName].map((item, index) => (
                <div key={index} className="flex items-center mb-2">
                  <textarea
                    value={item}
                    onChange={(e) => handleChangeItem(arrayName, index, e.target.value)}
                    placeholder={`Item ${index + 1}`}
                    className="w-full p-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 resize-none overflow-hidden !text-sm"
                    rows={1}
                    onInput={(e) => {
                      e.target.style.height = 'auto';
                      e.target.style.height = `${e.target.scrollHeight}px`;
                    }}
                  />
                  <IconButton onClick={() => handleRemoveItem(arrayName, index)} color="error">
                    <Remove />
                  </IconButton>
                </div>
              ))}
            </div>
          </AccordionDetails>
        </Accordion>
      ))}
    </div>
  );
};

export default DetailAmenities;