import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';

const UnmappedReservationDialog = ({
  open,
  onClose,
  onConfirm,
  loading,
  reservationId
}) => {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        {"Resolve Unmapped Reservation"}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          Are you sure you want to resolve this unmapped reservation?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} className='!text-gray-500'>
          Cancel
        </Button>
        <Button
          onClick={() => onConfirm(reservationId)}
          className="!bg-medium-aquamarine hover:!bg-medium-aquamarine-dark !text-white"
          variant="contained"
          disabled={loading}
          startIcon={loading ? <CircularProgress size={20} /> : null}
        >
          {loading ? 'Resolving...' : 'Resolve'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default UnmappedReservationDialog;
