import React, { useState, useEffect } from 'react';
import { getTaskConfigs, deleteTaskConfig, getReminders, updateReminder } from './services/serverApi.task';
import GlobalTable from 'components/GlobalTable/GlobalTable';
import TaskConfigModal from './components/TaskConfigModal/TaskConfigModal';
import { toast, ToastContainer } from 'react-toastify';
import EditOffIcon from '@mui/icons-material/EditOff';
import DeleteSweepIcon from '@mui/icons-material/DeleteSweep';
import { CircularProgress, Typography, Button, TextField } from '@mui/material';
import DashboardLayout from 'components/LayoutContainers/DashboardLayout';
import DashboardNavbar from 'components/Navbars/DashboardNavbar';
import { Save } from 'lucide-react';


function TaskConfigs() {
    const [isLoading, setIsLoading] = useState(true);
    const [taskConfigs, setTaskConfigs] = useState([]);
    const [modalOpen, setModalOpen] = useState(false);
    const [selectedTaskConfig, setSelectedTaskConfig] = useState(null);
    const [error, setError] = useState(null);
    const [reminder, setReminder] = useState({
        reminderEveryHour: 0,
        reminderMax: 0
    });

    useEffect(() => {
        Promise.all([fetchTaskConfigs(), fetchReminder()]);
    }, []);

    const fetchTaskConfigs = async () => {
        try {
            const response = await getTaskConfigs();
            if (Array.isArray(response)) {
                setTaskConfigs(response);
            } else {
                throw new Error('Invalid response format');
            }
        } catch (error) {
            console.error('Error fetching task configs:', error);
            toast.error('Failed to fetch task configurations');
        } finally {
            setIsLoading(false);
        }
    };

    const fetchReminder = async () => {
        try {
            const response = await getReminders();
            if (response && response.data) {
                setReminder({
                    reminderEveryHour: response.data.reminderEveryHour || 0,
                    reminderMax: response.data.reminderMax || 0
                });
            }
        } catch (error) {
            console.error('Error fetching reminder:', error);
            setReminder({
                reminderEveryHour: 0,
                reminderMax: 0
            });
        }
    };

    const handleReminderInputChange = (field, value) => {
        const newValue = parseInt(value) || 0;
        setReminder(prev => ({
            ...prev,
            [field]: newValue
        }));
    };

    const handleUpdateReminder = async () => {
        try {
            await updateReminder(reminder);
            toast.success('Reminder updated successfully');
        } catch (error) {
            console.error('Error updating reminder:', error);
            toast.error('Failed to update reminder');
        }
    };

    const handleOpenModal = (taskConfig = null) => {
        setSelectedTaskConfig(taskConfig);
        setModalOpen(true);
    };

    const handleCloseModal = () => {
        setSelectedTaskConfig(null);
        setModalOpen(false);
    };

    const handleDeleteTaskConfig = async (taskConfigId) => {
        if (window.confirm('Are you sure you want to delete this task configuration?')) {
            try {
                await deleteTaskConfig(taskConfigId);
                setTaskConfigs(prevConfigs => prevConfigs.filter(config => config._id !== taskConfigId));
                toast.success('Task configuration deleted successfully');
            } catch (error) {
                console.error('Error deleting task configuration:', error);
                toast.error('Failed to delete task configuration');
            }
        }
    };

    const columns = [
        { header: "Task", body: (rowData) => <span>{rowData.task || 'N/A'}</span> },
        {
            header: "Name",
            body: (rowData) => (
                <div>
                    <div><strong>FR:</strong> {rowData.name?.Francais || 'N/A'}</div>
                    <div><strong>DA:</strong> {rowData.name?.Darija || 'N/A'}</div>
                </div>
            )
        },
        {
            header: "Subtasks",
            body: (rowData) => (
                <div>
                    <div className="flex flex-wrap gap-2">
                        {rowData.subs && rowData.subs.map((sub, index) => (
                            <div key={sub._id || index} className="flex-1 min-w-[250px] p-2 border rounded">
                                <div className="flex flex-wrap gap-2">
                                    <strong>{sub.type || 'N/A'}:</strong>
                                    <div><strong>FR:</strong> {sub.name?.Francais || 'N/A'}</div>
                                    <div><strong>DA:</strong> {sub.name?.Darija || 'N/A'}</div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            )
        },
        {
            header: "Action",
            body: (rowData) => (
                <div className="flex gap-1">
                    <button className="px-2 py-1 bg-medium-aquamarine !rounded-md" onClick={() => handleOpenModal(rowData)}>
                        <EditOffIcon className="text-white" />
                    </button>
                    <button className="px-2 py-1 bg-[#df5454] !rounded-md" onClick={() => handleDeleteTaskConfig(rowData._id)}>
                        <DeleteSweepIcon className="text-white" />
                    </button>
                </div>
            ),
        },
    ];

    if (error) {
        return <div className="w-full h-64 flex justify-center items-center text-red-500">{error}</div>;
    }

    return (
        <DashboardLayout>
            <DashboardNavbar />
            <ToastContainer />
            <div className="card p-4">
                <Typography variant="h4" component="h1" className="mb-4" gutterBottom>
                    Task Configuration Management
                </Typography>

                <div className="mb-6 p-4 border rounded-lg bg-gray-50 mb-4">
                    <Typography variant="h6" className="mb-3">
                        Reminder Settings
                    </Typography>
                    <div className="flex flex-col gap-4">
                        <div className="flex gap-4">
                            <TextField
                                label="Reminder Every Hour"
                                type="number"
                                value={reminder.reminderEveryHour}
                                onChange={(e) => handleReminderInputChange('reminderEveryHour', e.target.value)}
                                InputProps={{ inputProps: { min: 0 } }}
                                className="flex-1 bg-white"
                            />
                            <TextField
                                label="Max Reminders"
                                type="number"
                                value={reminder.reminderMax}
                                onChange={(e) => handleReminderInputChange('reminderMax', e.target.value)}
                                InputProps={{ inputProps: { min: 0 } }}
                                className="flex-1 bg-white"
                            />
                            <Button
                                onClick={handleUpdateReminder}
                                variant="contained"
                                className="!bg-medium-aquamarine"
                            >
                                <Save className="mr-2 !text-white" />
                            </Button>
                        </div>

                    </div>
                </div>

                <div className="mb-4">
                    <Button onClick={() => handleOpenModal()} className="float-right !bg-medium-aquamarine text-white">
                        Create New Task Config
                    </Button>
                </div>

                <div>
                    <div className="w-full">
                        {isLoading ? (
                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                                <CircularProgress style={{ color: '#00b4b4' }} />
                            </div>
                        ) : (
                            <GlobalTable
                                data={taskConfigs}
                                columns={columns}
                                hasPagination={false}
                            />
                        )}
                    </div>
                </div>

                <TaskConfigModal
                    open={modalOpen}
                    onClose={handleCloseModal}
                    setTaskConfigs={setTaskConfigs}
                    taskConfig={selectedTaskConfig}
                />
            </div>
        </DashboardLayout>
    );
}

export default TaskConfigs;