import React, { useRef, useEffect } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import Pagination from './Pagination';
import TotalRevenueTable from 'features/financial/components/rentalActivity/TotalRevenueTable';
import styled from 'styled-components';

const TableContainer = styled.div`
  .table-wrapper {
    width: 100%;
    overflow-x: auto;
  }

  .totals-wrapper {
    width: 100%;
    overflow-x: auto;
    border-top: 1px solid #dfe1e6;
  }

  @media screen and (max-width: 768px) {
    .table-wrapper,
    .totals-wrapper {
      -webkit-overflow-scrolling: touch;
    }

    table {
      min-width: 768px;
    }
  }

  table {
    table-layout: fixed;
  }
    
  tr:nth-child(even),
  tr:nth-child(odd) {
    background-color: white;
    color: black;
  }

  tr:nth-child(even) {
    background-color: #f9f9f9;
  }

  td,
  th {
    font-size: 14px;
    border: none;
    white-space: nowrap; 
    text-align: start;
    padding: 8px; 
    overflow: hidden;
    text-overflow: ellipsis; 
  }

  td {
    height: 60px;
  }

  th {
    background: white;
    border-bottom: 1px solid #dfe1e6;
    min-width: 150px;
    width: 150px;
    max-width: 150px;
  }

  @media screen and (max-width: 768px) {
    th,
    td {
      min-width: 106px;
      width: 106px;
      max-width: 106px;
    }
  }

  .p-sortable-column .p-sortable-column-icon {
    color: #808080; 
  }

  .p-sortable-column:hover .p-sortable-column-icon {
    color: #606060; 
  }

  .p-sortable-column.p-highlight .p-sortable-column-icon {
    color: #404040;
  }
`;

const GlobalTable = ({
  data,
  columns,
  page,
  onPageChange,
  isNextDisabled,
  hasPagination,
  limit,
  onLimitChange,
  rowsPerPageOptions,
  totalRevenue,
  totals,
  onRowClick
}) => {
  const mainTableRef = useRef(null);
  const totalsRef = useRef(null);

  useEffect(() => {
    const syncScroll = (source, target) => {
      if (source && target) {
        target.scrollLeft = source.scrollLeft;
      }
    };

    const mainTableWrapper = mainTableRef.current?.querySelector('.table-wrapper');
    const totalsWrapper = totalsRef.current;

    if (mainTableWrapper && totalsWrapper) {
      mainTableWrapper.addEventListener('scroll', () => syncScroll(mainTableWrapper, totalsWrapper));
      totalsWrapper.addEventListener('scroll', () => syncScroll(totalsWrapper, mainTableWrapper));

      return () => {
        mainTableWrapper.removeEventListener('scroll', () => syncScroll(mainTableWrapper, totalsWrapper));
        totalsWrapper.removeEventListener('scroll', () => syncScroll(totalsWrapper, mainTableWrapper));
      };
    }
  }, []);

  const rowClassName = (rowData) => {
    return {
      '!bg-red-100': rowData.isUnmapped,
      '!cursor-pointer': rowData.isUnmapped
    };
  };

  return (
    <TableContainer>
      <div ref={mainTableRef}>
        <div className="table-wrapper">
          <DataTable 
            value={data} 
            className="p-datatable-sm"
            rowClassName={rowClassName} 
            sortField="isUnmapped" 
            sortOrder={-1}
            onRowClick={(e) => onRowClick && onRowClick(e.data)}
          >
            {columns.map((col, index) => (
              <Column
                key={index}
                field={col.field}
                header={col.header}
                body={col.body}
                headerClassName={col.headerClassName}
                sortable 
                headerStyle={col.headerStyle} 
                className='w-99%'
              />
            ))}
          </DataTable>
        </div>
      </div>

      {totalRevenue !== undefined && (
        <div ref={totalsRef} className="totals-wrapper">
          <TotalRevenueTable 
            columns={columns} 
            totalRevenue={totalRevenue} 
            totals={totals}
          />
        </div>
      )}

      {hasPagination && (
        <Pagination
          page={page}
          onPageChange={onPageChange}
          isNextDisabled={isNextDisabled}
          limit={limit}
          onLimitChange={onLimitChange}
          rowsPerPageOptions={rowsPerPageOptions}
        />
      )}
    </TableContainer>
  );
};

export default GlobalTable;