import React, { useState, useEffect } from 'react';
import { getUnits, deleteUnit, getProjects } from '../services/serverApi.ProjectUnits';
import GlobalTable from 'components/GlobalTable/GlobalTable';
import UnitModal from '../components/UnitModal';
import { toast, ToastContainer } from 'react-toastify';
import EditOffIcon from '@mui/icons-material/EditOff';
import DeleteSweepIcon from '@mui/icons-material/DeleteSweep';
import { CircularProgress, Typography, Button, Tooltip, Paper, Box } from '@mui/material';
import DashboardLayout from 'components/LayoutContainers/DashboardLayout';
import DashboardNavbar from 'components/Navbars/DashboardNavbar';
import { useNavigate } from 'react-router-dom';
import ImageGalleryTrigger from 'components/ImageGalleryModal/ImageGalleryModal';

function Units() {
    const [isLoading, setIsLoading] = useState(true);
    const [units, setUnits] = useState([]);
    const [modalOpen, setModalOpen] = useState(false);
    const [selectedUnit, setSelectedUnit] = useState(null);
    const [error, setError] = useState(null);
    const [page, setPage] = useState(0);
    const [limit, setLimit] = useState(10);
    const [total, setTotal] = useState(0);
    const navigate = useNavigate();
    const [projects, setProjects] = useState([]);


    useEffect(() => {
        fetchUnits();
        fetchProjects();
    }, [page, limit]);

    const handleOpenCreateEdit = (unit = null) => {
        if (unit) {
            navigate(`/admin/units/edit/${unit._id}`);
        } else {
            navigate('/admin/units/create');
        }
    };

    const fetchUnits = async () => {
        try {
            const response = await getUnits({ page, limit });
            if (response.data && response.data.units && Array.isArray(response.data.units)) {
                const normalizedUnits = response.data.units.map(unit => ({
                    ...unit,
                    id: unit.id || unit._id,
                    _id: unit._id || unit.id
                }));
                setUnits(normalizedUnits);
                setTotal(response.data.total);
            } else {
                throw new Error('Invalid response format');
            }
        } catch (error) {
            console.error('Error fetching units:', error);
        } finally {
            setIsLoading(false);
        }
    };

    const fetchProjects = async () => {
        const response = await getProjects({ page: 0, limit: 1000 });
        if (response.data && response.data.projects && Array.isArray(response.data.projects)) {
            setProjects(response.data.projects);
        } else {
            throw new Error('Invalid response format');
        }
    };



    const handleCloseModal = () => {
        setSelectedUnit(null);
        setModalOpen(false);
    };

    const handleDeleteUnit = async (unitId) => {
        if (window.confirm('Are you sure you want to delete this unit?')) {
            try {
                await deleteUnit(unitId);

                setUnits(prevUnits => prevUnits.filter(unit =>
                    unit.id !== unitId && unit._id !== unitId
                ));

                toast.success('Unit deleted successfully');

            } catch (error) {
                console.error('Error deleting unit:', error);
                toast.error(error.response?.data?.message || 'Failed to delete unit');
            } finally {
                setIsLoading(false);
            }
        }
    };

    const handlePageChange = (newPage) => {
        setPage(newPage);
    };

    const handleLimitChange = (newLimit) => {
        setLimit(newLimit);
        setPage(0);
    };

    const columns = [
        {
            header: "Images",
            body: (rowData) => (
                <Box className="flex justify-center">
                    <ImageGalleryTrigger images={rowData.images} />
                </Box>
            )
        },
        {
            header: "Unit Name",
            body: (rowData) => (
                <Tooltip title={rowData.unitName} placement="top">
                    <Typography className="!text-sm !font-medium !text-gray-800">
                        {rowData.unitName}
                    </Typography>
                </Tooltip>
            )
        },
        {
            header: "Property Type",
            body: (rowData) => (
                <span className="px-3 py-1 rounded-full text-sm bg-blue-100 text-blue-800">
                    {rowData.propertyType}
                </span>
            )
        },
        {
            header: "Location",
            body: (rowData) => (
                <div className="space-y-1">
                    <div className="text-gray-700 font-medium">{rowData.city}</div>
                    <div className="text-sm text-gray-500">{rowData.district}</div>
                </div>
            )
        },
        {
            header: "Project",
            body: (rowData) => (
                <div>
                    {rowData.projectId ? (
                        <Tooltip title={projects.find(project => project.id === rowData.projectId)?.projectName} placement="top">
                            <span className="px-3 py-1 rounded-full text-sm bg-teal-100 text-teal-800">
                                {(projects.find(project => project.id === rowData.projectId)?.projectName || '').slice(0, 15)}..
                            </span>
                        </Tooltip>
                    ) : (
                        <span className="px-3 py-1 rounded-full text-sm bg-gray-100 text-gray-600">
                            Independent
                        </span>
                    )}
                </div>
            )
        },
        {
            header: "Area Details",
            body: (rowData) => (
                <div className="space-y-2">
                    <div className="flex items-center gap-2">
                        <span className="text-sm font-medium text-gray-600">Total:</span>
                        <span className="text-gray-800">{rowData.totalArea}m²</span>
                    </div>
                    <div className="flex items-center gap-2">
                        <span className="text-sm font-medium text-gray-600">Living:</span>
                        <span className="text-gray-800">{rowData.livingArea}m²</span>
                    </div>
                    {rowData.balcony && (
                        <div className="flex items-center gap-2">
                            <span className="text-sm font-medium text-gray-600">Balcony:</span>
                            <span className="text-gray-800">{rowData.balconyArea}m²</span>
                        </div>
                    )}
                </div>
            )
        },
        {
            header: "Price",
            body: (rowData) => (
                <div className="space-y-2">
                    <div className="text-lg font-semibold text-teal-600">
                        ${rowData.salePrice.toLocaleString()}
                    </div>
                    <div className="text-sm text-gray-500">
                        ${rowData.pricePerSqm.toLocaleString()}/m²
                    </div>
                </div>
            )
        },
        {
            header: "Features",
            body: (rowData) => (
                <div className="space-y-2">
                    <div className="flex items-center gap-2 mb-1">
                        <span className="px-2 py-1 rounded-md bg-purple-100 text-purple-800 text-sm">
                            {rowData.numberOfRooms} Rooms
                        </span>
                    </div>
                    <div className="flex items-center gap-2 mb-1">
                        <span className="px-2 py-1 rounded-md bg-blue-100 text-blue-800 text-sm">
                            {rowData.numberOfBedrooms} Beds
                        </span>
                    </div>
                    <div className="flex items-center gap-2 mb-1">
                        <span className="px-2 py-1 rounded-md bg-green-100 text-green-800 text-sm">
                            {rowData.numberOfBathrooms} Baths
                        </span>
                    </div>
                </div>
            )
        },
        {
            header: "Unit Visibility",
            body: (rowData) => (
                <div className="flex flex-col gap-2">
                    {rowData.projectId ? (
                        <>
                            <Tooltip title={rowData.displayAsUnit ? "Visible in project" : "Hidden in project"}>
                                <span className={`px-3 py-1 rounded-full text-xs font-medium w-auto text-center ${rowData.displayAsUnit
                                        ? "bg-green-100 text-green-800"
                                        : "bg-red-100 text-red-800"
                                    }`}>
                                    {rowData.displayAsUnit ? "In Project ✓" : "In Project ✗"}
                                </span>
                            </Tooltip>
                            <Tooltip title={rowData.displayAsProject ? "Listed independently" : "Not listed independently"}>
                                <span className={`px-3 py-1 rounded-full text-xs font-medium w-auto text-center ${rowData.displayAsProject
                                        ? "bg-green-100 text-green-800"
                                        : "bg-red-100 text-red-800"
                                    }`}>
                                    {rowData.displayAsProject ? "Independent ✓" : "Independent ✗"}
                                </span>
                            </Tooltip>
                        </>
                    ) : (
                        <span className="px-3 py-1 rounded-full text-xs font-medium bg-blue-100 text-blue-800 w-auto text-center">
                            Independent Unit
                        </span>
                    )}
                </div>
            )
        },
        {
            header: "Action",
            body: (rowData) => (
                <div className="flex gap-2">
                    <Tooltip title="Edit Unit">
                        <button
                            className="p-2 rounded-full hover:text-teal-600 rounded-full text-medium-aquamarine transition-colors"
                            onClick={() => handleOpenCreateEdit(rowData)}
                        >
                            <EditOffIcon className="w-5 h-5" />
                        </button>
                    </Tooltip>
                    <Tooltip title="Delete Unit">
                        <button
                            className="p-2 rounded-full hover:text-red-600 text-red-600 transition-colors"
                            onClick={() => handleDeleteUnit(rowData.id || rowData._id)}
                        >
                            <DeleteSweepIcon className="w-5 h-5" />
                        </button>
                    </Tooltip>
                </div>
            ),
        }
    ];

    if (error) {
        return <div className="w-full h-64 flex justify-center items-center text-red-500">{error}</div>;
    }

    return (
        <DashboardLayout>
            <DashboardNavbar />
            <ToastContainer />
            <Paper className="!p-6 !card !shadow-lg !rounded-xl">
                <div className="!flex !justify-between items-center !mb-6">
                <Typography variant="h4" className="text-gray-800 font-semibold !text-lg">
                Units Management
                    </Typography>
                    <Button
                        variant="contained"
                        onClick={() => handleOpenCreateEdit()}
                        className="!bg-medium-aquamarine hover:!bg-medium-aquamarine-dark !text-white transition-colors !text-sm"
                        startIcon={<span className="!text-lg">+</span>}
                    >
                        Create New Unit
                    </Button>
                </div>

                {isLoading ? (
                    <Box className="flex justify-center items-center h-64">
                        <CircularProgress className="text-teal-600" />
                    </Box>
                ) : (
                    <div className="overflow-hidden rounded-xl">
                        <GlobalTable
                            data={units}
                            columns={columns}
                            hasPagination={true}
                            page={page}
                            onPageChange={handlePageChange}
                            limit={limit}
                            onLimitChange={handleLimitChange}
                            isNextDisabled={page >= Math.ceil(total / limit) - 1}
                            rowsPerPageOptions={[10, 20, 50]}
                        />
                    </div>
                )}

                <UnitModal
                    open={modalOpen}
                    onClose={handleCloseModal}
                    setUnits={setUnits}
                    unit={selectedUnit}
                />
            </Paper>
        </DashboardLayout>
    );
}

export default Units;