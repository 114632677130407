import React, { useState, useEffect } from 'react';
import ChannelDialog from './ChannelDialog';
import { getChannels } from '../services/serverApi.adminConfig';
import { Box, Button, CircularProgress } from '@mui/material';
import { ToastContainer, toast } from 'react-toastify';
import { AddCircleOutline as AddIcon } from '@mui/icons-material';

const Channels = () => {
    const [channels, setChannels] = useState([]);
    const [showDialog, setShowDialog] = useState(false);
    const [selectedChannel, setSelectedChannel] = useState(null);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = async () => {
        try {
            const data = await getChannels();
            setChannels(data || []);
        } catch (error) {
            console.error('Error fetching channels:', error.message);
            setChannels([]);
        } finally {
            setIsLoading(false);
        }
    };

    const handleCreateChannel = () => {
        setSelectedChannel(null);
        setShowDialog(true);
    };

    const handleUpdateChannel = (channel) => {
        setSelectedChannel(channel);
        setShowDialog(true);
    };

    const handleChannelChange = (updatedChannel, isDeleted) => {
        if (isDeleted) {
            setChannels(prevChannels => prevChannels.filter(ch => ch._id !== updatedChannel._id));
        } else if (selectedChannel) {
            setChannels(prevChannels => prevChannels.map(ch =>
                ch._id === updatedChannel._id ? updatedChannel : ch
            ));
        } else {
            setChannels(prevChannels => [...prevChannels, updatedChannel]);
        }
    };

    return (
        <>
            <ToastContainer />
            <div className="card p-4">
                <Box className="text-center mb-4">
                    <Button
                        variant="contained"
                        className="!bg-medium-aquamarine text-white"
                        endIcon={<AddIcon />}
                        onClick={handleCreateChannel}
                    >
                        Add Channel
                    </Button>
                </Box>
                {isLoading ? (
                    <div className="flex justify-center items-center h-full">
                        <CircularProgress style={{ color: '#00b4b4' }} />
                    </div>
                ) : (
                    <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-5 lg:grid-cols-7 xl:grid-cols-9 gap-2 sm:gap-4">
                        {channels.map(channel => (
                            <div
                                key={channel._id}
                                onClick={() => handleUpdateChannel(channel)}
                                className="border rounded p-2 sm:p-4 flex justify-center text-black flex-col w-full min-h-[3rem] items-center cursor-pointer hover:text-white hover:shadow-md hover:bg-medium-aquamarine transition duration-300 hover:animate-pulse"
                            >
                                <h6 className="font-bold m-0 text-xs sm:text-sm text-center">{channel.name}</h6>
                            </div>
                        ))}
                    </div>
                )}
                <ChannelDialog
                    showDialog={showDialog}
                    onClose={() => setShowDialog(false)}
                    channel={selectedChannel}
                    onChannelChange={handleChannelChange}
                />
            </div>
        </>
    );
};

export default Channels;
