import React from 'react';
import { TextField, Button, FormControl, InputLabel, Select, MenuItem } from '@mui/material';

const FilterSection = ({ filters, onFilterChange, pagination, onPaginationChange, onSearch, cities, countries }) => (
    <div className="flex flex-wrap gap-4 mb-4 sm:flex-row flex-col">
        <div className="flex gap-4 w-full sm:w-auto">
            <TextField
                label="Search"
                name="name"
                value={filters.name}
                onChange={onFilterChange}
                size="small"
                className="w-1/2 sm:w-auto"
            />
            <TextField
                label="Limit"
                type="number"
                value={pagination.limit}
                onChange={(e) => onPaginationChange({ ...pagination, limit: parseInt(e.target.value) })}
                size="small"
                className="w-1/2 sm:w-auto"
            />
        </div>
        <div className="flex gap-4 w-full sm:w-auto">
            <FormControl size="small" className="w-1/2 sm:w-auto" style={{ minWidth: 120 }}>
                <InputLabel>Country</InputLabel>
                <Select
                    name="country"
                    value={filters.country}
                    onChange={onFilterChange}
                    label="Country"
                >
                    <MenuItem value=""><em>None</em></MenuItem>
                    {countries.map((country) => (
                        <MenuItem key={country._id} value={country.name}>
                            {country.name}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
            <FormControl size="small" className="w-1/2 sm:w-auto" style={{ minWidth: 120 }}>
                <InputLabel>City</InputLabel>
                <Select
                    name="city"
                    value={filters.city}
                    onChange={onFilterChange}
                    label="City"
                >
                    <MenuItem value=""><em>None</em></MenuItem>
                    {cities.map((city) => (
                        <MenuItem key={city._id} value={city.name}>
                            {city.name}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        </div>
        <div className="flex gap-2 w-full sm:w-auto">
            <Button 
                variant="contained" 
                className="!bg-medium-aquamarine !hover:bg-dark-aquamarine !text-white flex-1 sm:flex-initial"
                onClick={onSearch}
            >
                Search
            </Button>
            <Button 
                variant="outlined" 
                className="!border-gray-400 !text-gray-400 flex-1 sm:flex-initial"
                onClick={() => {
                    onFilterChange({ target: { name: 'name', value: '' } });
                    onFilterChange({ target: { name: 'city', value: '' } });
                    onFilterChange({ target: { name: 'country', value: '' } });
                    onPaginationChange({ page: 0, limit: 10 });
                }}
            >
                Clear
            </Button>
        </div>
    </div>
);

export default FilterSection;
