import { useEffect, useRef } from 'react';
import io from 'socket.io-client';
import { 
  SOCKET_URL, 
  SOCKET_PATH 
} from '../services/serverApi.messageConfig';

const useMessagesSocket = () => {
  const socketRef = useRef(null);

  useEffect(() => {
    const createSocketConnection = () => {
      const newSocket = io(SOCKET_URL, {
        path: SOCKET_PATH,
        transports: ['websocket'],
        autoConnect: true
      });

      newSocket.on('connect', () => {
        console.log('Messages WebSocket connected');
        newSocket.emit('JOIN_ROOM', 'CHANNEXMESSAGE');
      });

      newSocket.on('disconnect', () => {
        console.log('Messages WebSocket disconnected');
      });

      socketRef.current = newSocket;

      return newSocket;
    };

    const socket = createSocketConnection();

    const handleBeforeUnload = () => {
      if (socketRef.current) {
        socketRef.current.disconnect();
      }
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
      
      if (socketRef.current) {
        socketRef.current.disconnect();
      }
    };
  }, []);

  const sendSocketMessage = (event, data) => {
    if (socketRef.current && socketRef.current.connected) {
      socketRef.current.emit(event, data);
    }
  };

  const disconnectSocket = () => {
    if (socketRef.current) {
      socketRef.current.disconnect();
    }
  };

  return {
    socket: socketRef.current,
    sendSocketMessage,
    disconnectSocket
  };
};

export default useMessagesSocket;