import React, { useRef, useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import { ChevronLeft, ChevronRight, CalendarSearch } from 'lucide-react';
import { DateRange } from 'react-date-range';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';

const SidebarProperty = ({
  onPreviousPeriod,
  onNextPeriod,
  onTodayClick,
  onDateRangeChange,
  formattedDateRange,
  dateRange,
  showDatePicker,
  setShowDatePicker
}) => {
  const datePickerRef = useRef(null);
  const triggerRef = useRef(null);
  const [position, setPosition] = useState({ top: 0, left: 0 });

  useEffect(() => {
    const updatePosition = () => {
      if (triggerRef.current && showDatePicker) {
        const rect = triggerRef.current.getBoundingClientRect();
        const scrollX = window.scrollX || window.pageXOffset;
        const scrollY = window.scrollY || window.pageYOffset;

        setPosition({
          top: rect.bottom + scrollY,
          left: rect.left + scrollX,
        });
      }
    };

    updatePosition();
    window.addEventListener('resize', updatePosition);
    window.addEventListener('scroll', updatePosition);

    return () => {
      window.removeEventListener('resize', updatePosition);
      window.removeEventListener('scroll', updatePosition);
    };
  }, [showDatePicker]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        datePickerRef.current && 
        !datePickerRef.current.contains(event.target) &&
        !triggerRef.current.contains(event.target)
      ) {
        setShowDatePicker(false);
      }
    };

    if (showDatePicker) {
      document.addEventListener('mousedown', handleClickOutside);
    }
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, [showDatePicker, setShowDatePicker]);

  const handleDateRangeClick = () => {
    setShowDatePicker(!showDatePicker);
  };

  const handleRangeChange = (ranges) => {
    onDateRangeChange(ranges.selection);
  };

  return (
    <div className="w-full">
      <div className="flex flex-wrap justify-center items-center gap-1 sm:gap-4 p-1 sm:p-2">
        <div className="flex justify-center items-center gap-1 sm:gap-3">
          <ChevronLeft
            onClick={onPreviousPeriod}
            className="cursor-pointer hover:bg-gray-100 rounded-full p-1 w-5 h-5 sm:w-6 sm:h-6"
          />
          <div
            ref={triggerRef}
            className="flex items-center gap-1 cursor-pointer hover:bg-gray-100 p-1 rounded-md"
            onClick={handleDateRangeClick}
          >
            <span className="text-[10px] sm:text-sm font-bold whitespace-nowrap">{formattedDateRange}</span>
            <CalendarSearch className="text-teal-500 w-5 h-5 sm:w-6 sm:h-6" />
          </div>
          <div
            onClick={onTodayClick}
            className="px-2 py-1 bg-white border border-teal-500 rounded-md cursor-pointer hover:bg-teal-600 hover:text-white"
          >
            <span className="text-[10px] sm:text-xs text-teal-500 hover:text-white">Now</span>
          </div>
          <ChevronRight
            onClick={onNextPeriod}
            className="cursor-pointer text-teal-500 hover:bg-gray-100 rounded-full p-1 w-5 h-5 sm:w-6 sm:h-6"
          />
        </div>
        {showDatePicker && createPortal(
          <div
            ref={datePickerRef}
            className="fixed shadow-xl bg-white rounded-lg z-[9999] left-1/2 transform -translate-x-1/2"
            style={{
              top: `${position.top}px`,
              maxWidth: '95vw',
            }}
          >
            <DateRange
              onChange={handleRangeChange}
              moveRangeOnFirstSelection={false}
              ranges={[dateRange]}
              direction="horizontal"
              preventSnapRefocus={true}
              calendarFocus="backwards"
              months={window.innerWidth < 768 ? 1 : 2}
              className="rounded-lg overflow-hidden scale-[0.85] sm:scale-100"
            />
          </div>,
          document.body
        )}
      </div>
    </div>
  );
};

export default SidebarProperty;