import React, { useRef } from 'react';
import {
  List,
  ListItem,
  Typography,
  Avatar,
  Box,
  Chip,
  Divider,
  CircularProgress,
  TextField,
  Tabs,
  Tab

} from '@mui/material';
import StarIcon from '@mui/icons-material/Star';
import airbnb from "assets/images/airbnb.png";
import booking from "assets/images/booking.png";

function ReviewsList({
  reviews,
  onSelectReview,
  selectedReview,
  isLoading,
  hasMore,
  onScroll,
  searchQuery,
  onSearchChange,
  replyFilter,
  onTabChange
}) {
  const listRef = useRef(null);

  const handleScroll = () => {
    const bottom =
      listRef.current.scrollHeight - listRef.current.scrollTop ===
      listRef.current.clientHeight;
    if (bottom && hasMore && !isLoading) {
      onScroll();
    }
  };

  const formatDate = (dateStr) => {
    const date = new Date(dateStr);
    return new Intl.DateTimeFormat('en-US', {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit'
    }).format(date);
  };

  const getReviewerName = (review) => {
    if (review.reservation?.guestName) {
      return review.reservation.guestName;
    }
    return review.ota_meta?.reviewer?.name || 'Anonymous';
  };

  const getScore = (review) => {
    return review.ota_meta?.scoring?.review_score || review.ota_meta?.overall_rating * 2 || '-';
  };

  return (
    <Box className="flex flex-col h-full w-full">
      <Box
        sx={{ backgroundColor: '#F9FAFB', px: 2.5, pt: 2, borderBottom: '1px solid', borderColor: '#d3d3d3', }} >
        <Tabs
          value={replyFilter}
          onChange={onTabChange}
          indicatorColor="primary"
          textColor="primary"
          sx={{
            '& .MuiTab-root': {
              textTransform: 'none',
              color: '#536F95',
              fontSize: '16px',
              '&.Mui-selected': {
                color: '#000000',
              },
            },
            '& .MuiTabs-indicator': {
              backgroundColor: '#000000',
              height: '2px',
            },
          }}
        >
          <Tab label="Not Replied" value={false} />
          <Tab label="Replied" value={true} />
        </Tabs>
      </Box>


      {isLoading && reviews.length === 0 ? (
        <Box display="flex" justifyContent="center" alignItems="center" minHeight="200px">
          <CircularProgress />
        </Box>
      ) : reviews.length === 0 ? (
        <Box display="flex" justifyContent="center" alignItems="center" minHeight="200px">
          <Typography>No reviews found</Typography>
        </Box>
      ) : (
        <List
          ref={listRef}
          onScroll={handleScroll}
          className="overflow-y-scroll scrollbar-thin scrollbar-thumb-medium-aquamarine scrollbar-track-gray-100 mt-1"
          style={{
            maxHeight: 'calc(100vh - 200px)',
            overflowY: 'scroll',
            scrollbarWidth: 'thin',
            scrollbarGutter: 'stable',
            msOverflowStyle: 'auto'
          }}
        >
          {reviews.map((review) => (
            <React.Fragment key={review._id}>
              <ListItem
                button
                selected={selectedReview?._id === review._id}
                onClick={() => onSelectReview(review)}
                className="hover:bg-gray-50 transition-colors px-4 py-2"
                sx={{
                  '&.Mui-selected': {
                    backgroundColor: '#f3f4f6',
                    '&:hover': {
                      backgroundColor: '#e5e7eb',
                    }
                  }
                }}
              >
                <Box className="flex flex-col w-full gap-1.5">
                  <Box className="flex justify-between items-start">
                    <Box className="flex gap-2 items-center min-w-0">
                      {review.ota === 'BookingCom' ? (
                        <Avatar sx={{ width: 28, height: 28 }} src={booking} />
                      ) : review.ota === 'AirBNB' ? (
                        <Avatar sx={{ width: 28, height: 28 }} src={airbnb} />
                      ) : (
                        <Avatar sx={{ width: 28, height: 28 }}>
                          {getReviewerName(review)[0]}
                        </Avatar>
                      )}

                      <Box className="min-w-0">
                        <Typography variant="body2" className="font-medium truncate">
                          {getReviewerName(review)}
                        </Typography>
                        <Typography variant="caption" color="textSecondary" className="truncate block">
                          {review.listing?.name}
                        </Typography>
                      </Box>
                    </Box>
                    <Box className="flex items-center gap-1 shrink-0">
                      <StarIcon className="text-yellow-400" sx={{ fontSize: '0.875rem' }} />
                      <Typography variant="caption">{review.overall_score}</Typography>
                    </Box>
                  </Box>

                  <Box className="flex justify-between items-center mt-0.5">
                    <Typography variant="caption" color="textSecondary">
                      {formatDate(review.createdAt)}
                    </Typography>
                    <Chip
                      size="small"
                      label={review.ota === 'BookingCom' ? 'Booking' : review.ota}
                      className="bg-blue-50 text-blue-600"
                      sx={{ height: 20, '& .MuiChip-label': { px: 1, fontSize: '0.675rem' } }}
                    />
                  </Box>
                </Box>
              </ListItem>
              <Divider
                sx={{
                  borderColor: '#e5e7eb',
                  margin: '6px 0'
                }}
              />
            </React.Fragment>
          ))}
          {isLoading && hasMore && (
            <Box display="flex" justifyContent="center" p={2}>
              <CircularProgress size={24} />
            </Box>
          )}
        </List>
      )}
    </Box>
  );
}

export default ReviewsList; 