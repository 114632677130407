import axios from 'axios';
import { MICROSERVICE_BASE_URL } from 'config/backendServer.config';


export function getReviews(limit, page, reply = false) {
    return axios.get(`${MICROSERVICE_BASE_URL.SRV_RESERVATION}/review/get?limit=${limit}&page=${page}&reply=${reply}`);
}

export function replyToReview(reply, reviewId) {
    return axios.post(`${MICROSERVICE_BASE_URL.SRV_RESERVATION}/channex/replay-to-review`, {
        reply,
        review_id: reviewId
    });
}
