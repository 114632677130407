import axios from 'axios';
import { MICROSERVICE_BASE_URL } from 'config/backendServer.config';


export function CreateStaff(data) {
    return axios.post(`${MICROSERVICE_BASE_URL.SRV_USER}/user/register`, data);
}
export function deleteStaff(clerkId) {
    return axios.delete(`${MICROSERVICE_BASE_URL.SRV_USER}/user/delete-user/${clerkId}`);
}

export function updateStaff(clerkId, data) {
    return axios.put(`${MICROSERVICE_BASE_URL.SRV_USER}/user/update/${clerkId}`, data);
}

export function getStaff(params = {}) {
    const { page = 0, limit = 25, search_text = '' } = params;
    const queryParams = new URLSearchParams({
        page,
        limit,
        paged: true,
        search_text
    }).toString();

    return axios.get(`${MICROSERVICE_BASE_URL.SRV_TASK}/staff/approved-staff?${queryParams}`)
        .then(response => {
            if (!response.data) {
                throw new Error('No data received from server');
            }
            return response.data;
        })
        .catch(error => {
            console.error('Error in getStaff:', error);
            throw error;
        });
}


export function getLanguage() {
    return axios.get(`${MICROSERVICE_BASE_URL.LANGUAGE}?page=0&limit=20&paged=false&search_text`);

}


//----------------------------------Admin--------------------------------------------

export function getAdmins(params = {}) {
    const { page = 0, limit = 25, username = '', deleted = false, banned = false } = params;
    const queryParams = new URLSearchParams({
        page,
        limit,
        paged: true,
        username,
        deleted,
        banned
    }).toString();

    return axios.get(`${MICROSERVICE_BASE_URL.SRV_ADMIN}/admin/get-admins?${queryParams}`)
        .then(response => {
            if (!response.data) {
                throw new Error('No data received from server');
            }
            return response.data;
        })
        .catch(error => {
            console.error('Error in getAdmins:', error);
            throw error;
        });
}
export function getClient(params = {}) {
    const { page = 0, limit = 25, username = '', deleted = false, banned = false } = params;
    const queryParams = new URLSearchParams({
        page,
        limit,
        paged: true,
        username,
        deleted,
        banned
    }).toString();

    return axios.get(`${MICROSERVICE_BASE_URL.SRV_RESERVATION}/client/get-clients?${queryParams}`)
        .then(response => {
            if (!response.data) {
                throw new Error('No data received from server');
            }
            return response.data;
        })
        .catch(error => {
            console.error('Error in getAdmins:', error);
            throw error;
        });
}