import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { useCalendar } from '../../hooks/useCalendar';
import './Calendar.css';
import { CalendarHeader } from './CalendarHeader';
import TableTask from './TableTask';
import { getTasksByStaff } from '../../services/serverApi.task';
import Listing from 'features/listing/pages/listing.page';

export const Schedull = ({ staff, staging }) => {
    const [staffWithTasks, setStaffWithTasks] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);
 
    const {
        currentDate,
        goToNextPeriod,
        goToPreviousPeriod,
        daysInView,
        setViewType,
        viewType,
        goToToday,
        setCurrentDate,
        changeMonthView,
        scrollBackward,
        scrollForward,
    } = useCalendar(new Date());

    const startDate = moment(daysInView[0]).format('YYYY-MM-DD');
    const endDate = moment(daysInView[daysInView.length - 1]).format('YYYY-MM-DD');
    const formattedDateRange = `${moment(startDate).format('ddd D MMM')} – ${moment(endDate).format('ddd D MMM, YYYY')}`;

    useEffect(() => {
        const fetchTasksForStaff = async () => {
            setIsLoading(true);
            try {
                const staffIds = staff.map(s => s._id);
                const tasksData = await getTasksByStaff({
                    startDate,
                    endDate,
                    staffIds,
                    staging
                });



                const updatedStaff = staff.map(staffMember => {
                    const staffTasks = tasksData[staffMember._id] || [];
                    return {
                        ...staffMember,
                        tasks: staffTasks.map(task => ({
                            id: task._id,
                            date: new Date(task.startDate),
                            time: task.TS_SEL && task.TS_SEL.length > 0 && task.TS_SEL[0].start && task.TS_SEL[0].end ? `${task.TS_SEL[0].start}h-${task.TS_SEL[0].end}h` : 'N/A',
                            title: task.subType,
                            status: task.status,
                            reservationCode: task.reservationNumber,
                            assignmentStatus: task.assignmentStatus,
                            subType: task.subType,
                            type: task.type,
                            Staff: task.staff,
                            children: task.reservation.children,
                            guestName: task.reservation.guestName,
                            reservationCode: task.reservation.reservationNumber,
                            startDate: new Date(task.reservation.arrivalDate),
                            endDate: new Date(task.reservation.departureDate),
                            checkInTime: task.reservation.checkInTime,
                            checkOutTime: task.reservation.checkOutTime,
                            price: task.price,
                            paid: task.paid,
                            numberOfGuests: task.reservation.numberOfGuests,
                            adults: task.reservation.adults,
                            emergency: task.emergency,
                            presence: task.presence,
                            duration: task.duration,
                            ListingName: task.listing.name,
                            listing: task.listing,
                        }))
                    };
                });

                setStaffWithTasks(updatedStaff);
                setIsLoading(false);
            } catch (error) {
                console.error('Error fetching tasks for staff:', error);
                setError('Failed to fetch tasks. Please try again later.');
                setIsLoading(false);
            }
        };

        fetchTasksForStaff();
    }, [staff, startDate, endDate, staging]);




    return (
        <div className="calendar2">
            <div className="calendar2-inner">
                <CalendarHeader
                    formattedDateRange={formattedDateRange}
                    goToNextPeriod={goToNextPeriod}
                    goToToday={goToToday}
                    goToPreviousPeriod={goToPreviousPeriod}
                    setViewType={setViewType}
                    viewType={viewType}
                    setCurrentDate={setCurrentDate}
                    changeMonthView={changeMonthView}
                    scrollBackward={scrollBackward}
                    scrollForward={scrollForward}
                />
                <div className="calendar2-table-wrapper">
                    <TableTask
                        staff={staffWithTasks}
                        daysInView={daysInView}
                    />
                </div>
            </div>
        </div>
    );
};