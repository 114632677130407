import React from 'react';
import Tooltip from 'components/TooltipGlobal/Tooltip';

const InvoicesAndCharges = ({ reservationDetails }) => {
    return (
        <div className="!mt-8">
            <div className="flex flex-col md:flex-row justify-between items-start md:items-center gap-2 md:gap-0">
                <h5 className="text-base font-bold mb-2 text-[#676a6c]">Invoices & charges</h5>
                <button className="px-2 py-1 bg-[#dcf6f6] !rounded-sm flex items-center gap-2">
                    <i className="pi pi-download text-[#00b4b4] text-xs"></i>
                    <span className="ladda-label flex items-center text-[13px] text-[#00b4b4] p-[3px]">Download invoice</span>
                </button>
            </div>
            <div className="!my-3">
                <h6 className="text-base font-bold mb-2 text-[#676a6c]">Payment balance</h6>
                <div type="text" className="mb-2 p-1 focus:outline-none text-[#676a6c] focus:border-gray-300 rounded flex flex-col md:flex-row justify-between w-full md:w-1/2 text-sm" style={{ borderBottom: '3px solid #ccc' }}>
                    <div>Balance due: {reservationDetails.totalPrice} {reservationDetails.currency}</div>
                    <div>Total: {reservationDetails.totalPrice} {reservationDetails.currency}</div>
                </div>
                <div className="flex gap-2 items-center mt-2">
                    <hr className="my-3  !w-1/2" />
                    <Tooltip text="There is no credit card for this reservation. You can register an offline payment or ask the guest to provide a credit card (we suggest sharing the guest portal for it) and manually charge by payment method card online." iconClass="pi pi-info-circle text-[#676a6c] text-xs" position="top" />
                </div>
                <p className="text-[#676a6c] text-xs w-1/2 flex justify-end">Remaining charges: {reservationDetails.totalPrice} {reservationDetails.currency}</p>
            </div>
        </div>
    );
};

export default InvoicesAndCharges;
