import React, { useState } from 'react';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Card,
  CardContent,
  Chip,
  IconButton,
  Typography,
  Box,
  Grid
} from '@mui/material';
import { X, Home, User, Calendar, Clock, Users, DollarSign, CreditCard, Briefcase, ChevronDown, ChevronUp } from 'lucide-react';

const DetailsTask = ({ event, open, onClose }) => {
  const [showReservationInfo, setShowReservationInfo] = useState(false);

  const formatDate = (date) => {
    const d = new Date(date);
    return d.toLocaleDateString('en-US', { 
      weekday: 'short', 
      month: 'short', 
      day: 'numeric', 
      year: 'numeric' 
    });
  };

  const formatTime = (time) => {
    return new Date(`2024-01-01T${time}:00`).toLocaleTimeString('en-US', {
      hour: '2-digit',
      minute: '2-digit'
    });
  };

  const getStatusColor = (status) => {
    switch (status) {
      case 'PENDING': return 'warning';
      case 'IN_PROGRESS': return 'primary';
      case 'COMPLETED': return 'success';
      case 'CANCELED': return 'error';
      case 'CANCELED-BY-CUST': return 'error';
      default: return 'default';
    }
  };

  const getStatusLabel = (status) => {
    switch (status) {
      case 'PENDING': return 'Pending';
      case 'IN_PROGRESS': return 'In Progress';
      case 'COMPLETED': return 'Completed';
      case 'CANCELED': return 'Cancelled';
      case 'CANCELED-BY-CUST': return 'Cancelled by Customer';
      default: return status || 'Unknown';
    }
  };

  return (
    <Dialog 
      open={open} 
      onClose={onClose}
      maxWidth="md"
      fullWidth
    >
      <DialogContent sx={{ p: 3 }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 3 }}>
          <DialogTitle sx={{ p: 0, fontSize: '1.5rem', fontWeight: 'bold' }}>
            Task Details
          </DialogTitle>
          <IconButton onClick={onClose} size="small" sx={{ borderRadius: '50%', '&:hover': { bgcolor: 'grey.100' } }}>
            <X size={20} />
          </IconButton>
        </Box>

        <Card sx={{ bgcolor: 'grey.50', mb: 4 }}>
          <CardContent sx={{ p: 3 }}>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1.5 }}>
              <Briefcase size={24} color="#2196f3" />
              <Typography variant="h6">
                {event?.type || 'Not specified'} {event?.subType && `- ${event.subType}`}
              </Typography>
            </Box>

            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1.5, mt: 2 }}>
              <User size={20} color="#666" />
              <Box>
                <Typography variant="body2" color="text.secondary">Staff</Typography>
                <Typography variant="body1" fontWeight={500}>
                  {event?.Staff?.username || 'Not assigned'}
                </Typography>
              </Box>
            </Box>

            <Box sx={{ mt: 2 }}>
              <Chip
                label={getStatusLabel(event?.assignmentStatus)}
                color={getStatusColor(event?.assignmentStatus)}
                size="small"
              />
            </Box>
          </CardContent>
        </Card>

        <Box sx={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gap: 4 }}>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
            <Box>
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, color: 'text.secondary' }}>
                <Calendar size={16} />
                <Typography variant="body2">Date</Typography>
              </Box>
              <Typography variant="body1" sx={{ mt: 0.5, fontWeight: 500 }}>
                {formatDate(event?.date)}
              </Typography>
            </Box>

            <Box>
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, color: 'text.secondary' }}>
                <Clock size={16} />
                <Typography variant="body2">Start Time</Typography>
              </Box>
              <Typography variant="body1" sx={{ mt: 0.5, fontWeight: 500 }}>
                {formatTime(event?.checkInTime)}
              </Typography>
            </Box>

            <Box>
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, color: 'text.secondary' }}>
                <User size={16} />
                <Typography variant="body2">Assignment Status</Typography>
              </Box>
              <Typography variant="body1" sx={{ mt: 0.5, fontWeight: 500 }}>
                {event?.assignmentStatus || 'Not assigned'}
              </Typography>
            </Box>

            <Box>
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, color: 'text.secondary' }}>
                <DollarSign size={16} />
                <Typography variant="body2">Total Price</Typography>
              </Box>
              <Typography variant="h6" sx={{ mt: 0.5, fontWeight: 'bold', color: 'success.main' }}>
                ${event?.price || '0'}
              </Typography>
            </Box>
          </Box>

          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
            <Box>
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, color: 'text.secondary' }}>
                <Clock size={16} />
                <Typography variant="body2">End Time</Typography>
              </Box>
              <Typography variant="body1" sx={{ mt: 0.5, fontWeight: 500 }}>
                {formatTime(event?.checkOutTime)}
              </Typography>
            </Box>

            <Box>
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, color: 'text.secondary' }}>
                <CreditCard size={16} />
                <Typography variant="body2">Payment Status</Typography>
              </Box>
              <Chip
                label={event?.paid ? 'Paid' : 'Unpaid'}
                size="small"
                sx={{ mt: 0.5 }}
                color={event?.paid ? 'success' : 'error'}
              />
            </Box>
          </Box>
        </Box>

        {event?.reservationCode && (
          <Card sx={{ bgcolor: 'grey.50', mt: 4 }}>
            <CardContent sx={{ p: 3 }}>
              <Box 
                sx={{ 
                  display: 'flex', 
                  justifyContent: 'space-between', 
                  alignItems: 'center',
                  cursor: 'pointer'
                }}
                onClick={() => setShowReservationInfo(!showReservationInfo)}
              >
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1.5 }}>
                  <Home size={24} color="#2196f3" />
                  <Typography variant="h6">
                    Reservation Information
                  </Typography>
                </Box>
                <IconButton size="small">
                  {showReservationInfo ? <ChevronUp size={20} /> : <ChevronDown size={20} />}
                </IconButton>
              </Box>

              {showReservationInfo && (
                <Box sx={{ mt: 3, pl: 4 }}>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <Typography variant="body2" color="text.secondary">Reservation Number</Typography>
                      <Typography variant="h6" fontWeight="bold">
                        {event.reservationCode}
                      </Typography>
                    </Grid>

                    <Grid item xs={12}>
                      <Typography variant="body2" color="text.secondary">Guest</Typography>
                      <Typography variant="body1" fontWeight={500}>
                        {event.guestName || 'Not specified'}
                      </Typography>
                    </Grid>

                    <Grid item xs={6}>
                      <Typography variant="body2" color="text.secondary">Check In</Typography>
                      <Typography variant="body1" fontWeight={500}>
                        {formatDate(event.startDate)}
                      </Typography>
                    </Grid>

                    <Grid item xs={6}>
                      <Typography variant="body2" color="text.secondary">Check Out</Typography>
                      <Typography variant="body1" fontWeight={500}>
                        {formatDate(event.endDate)}
                      </Typography>
                    </Grid>

                    <Grid item xs={6}>
                      <Typography variant="body2" color="text.secondary">Guests</Typography>
                      <Typography variant="body1" fontWeight={500}>
                        Adults: {event.adults || '0'}, Children: {event.children || '0'}
                      </Typography>
                    </Grid>

                    <Grid item xs={6}>
                      <Typography variant="body2" color="text.secondary">Channel</Typography>
                      <Chip 
                        label={event.channel || 'Airbnb'}
                        size="small"
                        sx={{ mt: 0.5 }}
                        color="primary"
                        variant="outlined"
                      />
                    </Grid>
                  </Grid>
                </Box>
              )}
            </CardContent>
          </Card>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default DetailsTask;