import React from 'react';
import RenderInputField from './helper/Fields/RenderInputField';
import { RenderTimeSelectField } from './helper/Fields/RenderTimeSelectField';

const AdditionalInfo = ({ formik }) => {
    return (
        <div className="w-full p-4">
            <div className='flex justify-between'>
                <span className="text-md font-semibold">Additional Info<i className="pi pi-copy text-[#77b8ab] !text-[20px] mx-1"></i></span>
            </div>
            <div className='grid grid-cols-1 md:grid-cols-3 gap-4'>
                <div className="bg-white">
                    <div className="grid grid-cols-2 md:grid-cols-1 gap-2">
                        <RenderTimeSelectField
                            formik={formik}
                            name="checkOutTime"
                            label="Check-out time"
                            onChange={(e) => formik.setFieldValue('checkOutTime', Number(e.target.value))}
                        />
                        <RenderTimeSelectField
                            formik={formik}
                            name="checkInTimeStart"
                            label="Check-in time"
                            onChange={(e) => formik.setFieldValue('checkInTimeStart', Number(e.target.value))}
                        />
                    </div>
                </div>
                <div className="bg-white">
                    <div className="grid grid-cols-2 md:grid-cols-1 gap-2">
                        <RenderInputField 
                            formik={formik} 
                            name="minNights" 
                            label="Minimum nights" 
                            placeholder="Minimum nights" 
                            isRequired={true} 
                        />
                        <RenderInputField 
                            formik={formik} 
                            name="maxNights" 
                            label="Maximum nights" 
                            placeholder="Maximum nights" 
                            isRequired={true} 
                        />
                    </div>
                </div>
                <div className="bg-white">
                    <div className="grid grid-cols-2 md:grid-cols-1 gap-2">
                        <RenderInputField 
                            formik={formik} 
                            name="syndicName" 
                            label="Syndic name" 
                            placeholder="Syndic name" 
                            isRequired={true} 
                        />
                        <RenderInputField 
                            formik={formik} 
                            name="syndicWhatsappPhone" 
                            label="Syndic whatsapp phone" 
                            placeholder="Syndic whatsapp phone" 
                            isRequired={true} 
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AdditionalInfo;