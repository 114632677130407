import axios from 'axios';
import { MICROSERVICE_BASE_URL } from 'config/backendServer.config';

export function getMessagesThreads(limit, page) {
    return axios.get(`${MICROSERVICE_BASE_URL.SRV_RESERVATION}/reservations/get-messages?limit=${limit}&page=${page}`);
}

export function sendMessage(message, reservationId) {
    return axios.post(`${MICROSERVICE_BASE_URL.SRV_RESERVATION}/channex/send-msg-by-channex`, {
        message,
        reservationId: reservationId
    });
}


export function getReservationsById(id, staging = false) {
    return axios
      .get(`${MICROSERVICE_BASE_URL.RESERVATION}/by-id/${id}/?staging=${staging}`)
      .then((response) => {
        if (response.data.success) {
          return response.data;
        } else {
          throw new Error(response.data.message);
        }
      })
      .catch((error) => {
        console.error('Error fetching reservation:', error);
        throw error;
      });
  }

// export const SOCKET_URL = 'http://localhost:4007';
export const SOCKET_URL = 'https://dev.sojori.com';
export const SOCKET_PATH = '/api/v1/sockets/connect';