import React, { useState, useRef, useEffect } from 'react';
import {
  Modal,
  Box,
  Typography,
  TextField,
  Button,
  MenuItem,
  Select,
  FormControl,
  FormControlLabel,
  Checkbox,
  IconButton,
  Switch,
  Alert,
  CircularProgress,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import {
  ArrowRight,
  Calendar,
  X,
  Home,
  Clock,
  Tags,
  Lock,
  CalendarClock,
} from 'lucide-react';
import { DateRange } from 'react-date-range';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import { useFormik } from 'formik';
import moment from 'moment';
import * as Yup from 'yup';

const validationSchema = Yup.object().shape({
  days: Yup.array(),
  price: Yup.number().when('restriction', {
    is: (val) => val === 'manualPrice',
    then: () => Yup.number().required('Price is required'),
    otherwise: () => Yup.number().nullable(),
  }),
  availability: Yup.number().when('restriction', {
    is: (val) => val === 'availability',
    then: () =>
      Yup.number()
        .required('Availability is required')
        .min(0, 'Must be at least 0'),
    otherwise: () => Yup.number().nullable(),
  }),
});

const UpdateInventory = ({
  open,
  onClose,
  roomType,
  DateInventory,
  dateRange: initialDateRange,
  selectedRestriction,
  isLoading = false,
  error = null,
  setIsInventoryUpdated,
  onInventoryUpdate,
  pendingChanges,
  showNotification,
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isTablet = useMediaQuery(theme.breakpoints.down('md'));

  const inventoryForDate = roomType?.inventory?.[DateInventory?.date] || {};
  const [showDatePicker, setShowDatePicker] = useState(false);
  const datePickerRef = useRef(null);

  const [dateRange, setDateRange] = useState(() => {
    if (DateInventory?.date) {
      const date = new Date(DateInventory.date);
      return [
        {
          startDate: date,
          endDate: date,
          key: 'selection',
        },
      ];
    }
    return [
      {
        startDate: new Date(),
        endDate: new Date(),
        key: 'selection',
      },
    ];
  });

  const getCurrentValue = (dateStr, field, roomType) => {
    const pending = pendingChanges?.find(
      (change) =>
        change.date_from === dateStr &&
        change.type === field &&
        change.roomTypeId === roomType?.id,
    );

    if (pending) {
      switch (field) {
        case 'manualPrice':
          return pending.price;
        case 'availability':
          return pending.availableRoom;
        case 'stopSell':
          return pending.stopSell;
        case 'min_stay_arrival':
          return pending.min_stay_arrival;
        case 'max_stay':
          return pending.max_stay;
        case 'closed_to_arrival':
          return pending.closed_to_arrival;
        case 'closed_to_departure':
          return pending.closed_to_departure;
        default:
          return null;
      }
    }

    const inventory = roomType?.inventory?.[dateStr] || {};
    switch (field) {
      case 'manualPrice':
        return inventory.manualPrice || inventory.basePrice || 0;
      case 'availability':
        return inventory.available || 0;
      case 'stopSell':
        return inventory.stopSell || false;
      case 'min_stay_arrival':
        return inventory.min_stay_arrival || 0;
      case 'max_stay':
        return inventory.max_stay || 0;
      case 'closed_to_arrival':
        return inventory.closed_to_arrival || false;
      case 'closed_to_departure':
        return inventory.closed_to_departure || false;
      default:
        return null;
    }
  };

  const checkStopSellInRange = (inventory, startDate, endDate) => {
    const start = moment(startDate);
    const end = moment(endDate);
    let currentDate = start.clone();
    let allTrue = true;
    let hasData = false;

    while (currentDate.isSameOrBefore(end)) {
      const dateStr = currentDate.format('YYYY-MM-DD');
      const dateInventory = inventory[dateStr];

      if (dateInventory) {
        hasData = true;
        if (!dateInventory.stopSell) {
          allTrue = false;
          break;
        }
      }
      currentDate.add(1, 'days');
    }

    return hasData ? allTrue : false;
  };

  useEffect(() => {
    if (open) {
      const initialValues = getInitialValues();
      Object.keys(initialValues).forEach((key) => {
        formik.setFieldValue(key, initialValues[key]);
      });
    }
  }, [open, DateInventory?.date, roomType, pendingChanges]);

  const getInitialValues = () => {
    const currentDate = DateInventory?.date;
    return {
      days:
        moment(dateRange[0].startDate).format('YYYY-MM-DD') !==
        moment(dateRange[0].endDate).format('YYYY-MM-DD')
          ? ['mo', 'tu', 'we', 'th', 'fr', 'sa', 'su']
          : [],
      restriction: selectedRestriction || 'manualPrice',
      price: DateInventory?.currentPriceValue || getCurrentValue(currentDate, 'manualPrice', roomType),
      availability: getCurrentValue(currentDate, 'availability', roomType),
      stopSell:
        dateRange[0].startDate.getTime() === dateRange[0].endDate.getTime()
          ? getCurrentValue(currentDate, 'stopSell', roomType)
          : checkStopSellInRange(
              roomType?.inventory || {},
              dateRange[0].startDate,
              dateRange[0].endDate,
            ),
      min_stay_arrival: getCurrentValue(
        currentDate,
        'min_stay_arrival',
        roomType,
      ),
      max_stay: getCurrentValue(currentDate, 'max_stay', roomType),
      closed_to_arrival: getCurrentValue(
        currentDate,
        'closed_to_arrival',
        roomType,
      ),
      closed_to_departure: getCurrentValue(
        currentDate,
        'closed_to_departure',
        roomType,
      ),
    };
  };

  const formik = useFormik({
    initialValues: getInitialValues(),
    enableReinitialize: true,
    validationSchema,
    onSubmit: async (values, { setSubmitting }) => {
      console.log('values', values);

      try {
        const formattedDays = values.days.map((day) =>
          day.toLowerCase().substring(0, 2),
        );
        const payload = [];
        const dateStr = moment(dateRange[0].startDate).format('YYYY-MM-DD');

        const getOriginalValue = (field) => {
          const inventory = roomType?.inventory?.[dateStr] || {};
          switch (field) {
            case 'stopSell':
              return inventory.stopSell || false;
            case 'manualPrice':
              return inventory.manualPrice || inventory.basePrice || 0;
            case 'availability':
              return inventory.available || 0;
            case 'min_stay_arrival':
              return inventory.min_stay_arrival || 0;
            case 'max_stay':
              return inventory.max_stay || 0;
            case 'closed_to_arrival':
              return inventory.closed_to_arrival || false;
            case 'closed_to_departure':
              return inventory.closed_to_departure || false;
            default:
              return null;
          }
        };

        const fieldsToCheck = [];
        switch (values.restriction) {
          case 'manualPrice':
            fieldsToCheck.push({
              type: 'manualPrice',
              newValue: values.price,
              getValue: (change) => change.price,
            });
            break;
          case 'availability':
            fieldsToCheck.push({
              type: 'availability',
              newValue: values.availability,
              getValue: (change) => change.availableRoom,
            });
            break;
          case 'stopSell':
            fieldsToCheck.push({
              type: 'stopSell',
              newValue: values.stopSell,
              getValue: (change) => change.stopSell,
            });
            break;
          case 'min_stay_arrival':
            fieldsToCheck.push({
              type: 'min_stay_arrival',
              newValue: values.min_stay_arrival,
              getValue: (change) => change.min_stay_arrival,
            });
            break;
          case 'max_stay':
            fieldsToCheck.push({
              type: 'max_stay',
              newValue: values.max_stay,
              getValue: (change) => change.max_stay,
            });
            break;
          case 'closed_to_arrival':
            fieldsToCheck.push({
              type: 'closed_to_arrival',
              newValue: values.closed_to_arrival,
              getValue: (change) => change.closed_to_arrival,
            });
            break;
          case 'closed_to_departure':
            fieldsToCheck.push({
              type: 'closed_to_departure',
              newValue: values.closed_to_departure,
              getValue: (change) => change.closed_to_departure,
            });
            break;
          case 'setUseDynamicPriceManual':
            fieldsToCheck.push({
              type: 'setUseDynamicPriceManual',
              newValue: values.setUseDynamicPriceManual,
              getValue: (change) => change.setUseDynamicPriceManual,
            });

            const priceValue = values.setUseDynamicPriceManual 
              ? roomType.inventory?.[dateStr]?.calculatedPrice
              : (roomType.inventory?.[dateStr]?.applyManual 
                ? roomType.inventory?.[dateStr]?.manualPrice 
                : roomType.inventory?.[dateStr]?.basePrice);

            fieldsToCheck.push({
              type: 'price',
              newValue: priceValue,
              getValue: (change) => change.price,
            });
            break;
          default:
            break;
        }
        console.log('fieldsToCheck', fieldsToCheck);

        fieldsToCheck.forEach(({ type, newValue, getValue }) => {
          const originalValue = getOriginalValue(type);
          const pendingChange = pendingChanges?.find(
            (change) =>
              change.date_from === dateStr &&
              change.type === type &&
              change.roomTypeId === roomType.id,
          );

          //   if (newValue !== originalValue || pendingChange) {
          const changePayload = {
            type,
            roomTypeId: roomType.id,
            date_from: dateStr,
            date_to: moment(dateRange[0].endDate).format('YYYY-MM-DD'),
            days: formattedDays,
          };

          if (type === 'stopSell') {
            changePayload.stopSell = newValue;
          } else if (type === 'manualPrice') {
            changePayload.price = newValue;
          } else if (type === 'availability') {
            changePayload.availableRoom = newValue;
          } else if (type === 'min_stay_arrival') {
            changePayload.min_stay_arrival = newValue;
          } else if (type === 'max_stay') {
            changePayload.max_stay = newValue;
          } else if (type === 'closed_to_arrival') {
            changePayload.closed_to_arrival = newValue;
          } else if (type === 'closed_to_departure') {
            changePayload.closed_to_departure = newValue;
          }

          payload.push(changePayload);

          if (pendingChange) {
            onInventoryUpdate(
              pendingChanges.filter(
                (change) =>
                  !(
                    change.date_from === dateStr &&
                    change.type === type &&
                    change.roomTypeId === roomType.id
                  ),
              ),
            );
          }
          //   }
        });
        console.log('payload', payload);

        if (payload.length > 0) {
          onInventoryUpdate(payload);
        } else {
          showNotification('No changes detected', 'info');
        }
        onClose();
      } catch (err) {
        console.error('Error preparing changes:', err);
        showNotification(
          'An error occurred while preparing the changes',
          'error',
        );
      } finally {
        setSubmitting(false);
      }
    },
  });
  const handleClose = () => {
    setIsInventoryUpdated(false);
    formik.resetForm();
    if (DateInventory?.date) {
      const date = new Date(DateInventory.date);
      setDateRange([
        {
          startDate: date,
          endDate: date,
          key: 'selection',
        },
      ]);
    } else {
      setDateRange([
        {
          startDate: new Date(),
          endDate: new Date(),
          key: 'selection',
        },
      ]);
    }
    onClose();
  };

  useEffect(() => {
    if (DateInventory?.date) {
      const date = new Date(DateInventory.date);
      setDateRange([
        {
          startDate: date,
          endDate: date,
          key: 'selection',
        },
      ]);
    }
  }, [DateInventory?.date]);

  const handleDateClick = () => {
    setShowDatePicker(!showDatePicker);
  };

  const handleRangeChange = (ranges) => {
    setDateRange([ranges.selection]);
    if (
      ranges.selection.startDate.getTime() !==
      ranges.selection.endDate.getTime()
    ) {
      const newStopSellValue = checkStopSellInRange(
        roomType?.inventory || {},
        ranges.selection.startDate,
        ranges.selection.endDate,
      );
      formik.setFieldValue('stopSell', newStopSellValue);
    }
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        datePickerRef.current &&
        !datePickerRef.current.contains(event.target)
      ) {
        setShowDatePicker(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  useEffect(() => {
    const isSameDay =
      moment(dateRange[0].startDate).format('YYYY-MM-DD') ===
      moment(dateRange[0].endDate).format('YYYY-MM-DD');

    formik.setFieldValue(
      'days',
      isSameDay ? [] : ['mo', 'tu', 'we', 'th', 'fr', 'sa', 'su'],
    );

    const newStopSellValue = isSameDay
      ? getCurrentValue(
          moment(dateRange[0].startDate).format('YYYY-MM-DD'),
          'stopSell',
          roomType,
        )
      : checkStopSellInRange(
          roomType?.inventory || {},
          dateRange[0].startDate,
          dateRange[0].endDate,
        );
    formik.setFieldValue('stopSell', newStopSellValue);
  }, [dateRange]);

  const days = [
    { label: 'Mon', value: 'mo' },
    { label: 'Tue', value: 'tu' },
    { label: 'Wed', value: 'we' },
    { label: 'Thu', value: 'th' },
    { label: 'Fri', value: 'fr' },
    { label: 'Sat', value: 'sa' },
    { label: 'Sun', value: 'su' },
  ];

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="update-inventory-modal"
    >
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: {
            xs: '95%',
            sm: '80%',
            md: 500,
          },
          maxHeight: {
            xs: '95vh',
            sm: '90vh',
          },
          overflow: 'auto',
          bgcolor: 'background.paper',
          boxShadow: '0 4px 20px rgba(0,0,0,0.1)',
          borderRadius: 3,
        }}
        className={`${showDatePicker ? '!h-[630px]' : ''}`}
      >
        <form onSubmit={formik.handleSubmit}>
          <Box
            sx={{
              p: { xs: 2, sm: 2.5 },
              bgcolor: '#f8fafc',
              borderBottom: '1px solid #e2e8f0',
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <Typography
              variant={isMobile ? 'subtitle1' : 'h6'}
              sx={{ fontWeight: 600, color: '#1e293b' }}
            >
              Update Inventory
            </Typography>
            <IconButton onClick={handleClose} size="small">
              <X size={20} />
            </IconButton>
          </Box>

          <Box sx={{ p: { xs: 2, sm: 3 } }}>
            {error && (
              <Alert severity="error" sx={{ mb: 2 }}>
                {error}
              </Alert>
            )}

            <Box sx={{ display: 'flex', alignItems: 'center', mb: 3, gap: 1 }}>
              <Home size={18} className="text-gray-400" />
              <Typography
                variant={isMobile ? 'body2' : 'subtitle2'}
                sx={{ color: '#64748b', mr: 1 }}
              >
                Room Type:
              </Typography>
              <Typography
                variant={isMobile ? 'body2' : 'body1'}
                sx={{ color: '#334155' }}
              >
                {roomType?.name}
              </Typography>
            </Box>

            <Box sx={{ mb: 3, position: 'relative' }}>
              <Box
                sx={{ display: 'flex', alignItems: 'center', gap: 1, mb: 1 }}
              >
                <Calendar size={18} className="text-gray-400" />
                <Typography
                  variant={isMobile ? 'body2' : 'subtitle2'}
                  sx={{ color: '#64748b' }}
                >
                  Date Range
                </Typography>
              </Box>
              <Box
                onClick={handleDateClick}
                sx={{
                  p: 1,
                  border: '1px solid #e2e8f0',
                  borderRadius: 1,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  gap: 2,
                  cursor: 'pointer',
                  '&:hover': { borderColor: '#94a3b8' },
                }}
              >
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                  <Typography variant={isMobile ? 'caption' : 'body2'}>
                    {moment(dateRange[0].startDate).format('MMM DD, YYYY')}
                  </Typography>
                  <ArrowRight size={16} className="text-gray-300" />
                  <Typography variant={isMobile ? 'caption' : 'body2'}>
                    {moment(dateRange[0].endDate).format('MMM DD, YYYY')}
                  </Typography>
                </Box>
                <span>
                  <CalendarClock size={16} className="text-gray-300" />
                </span>
              </Box>
              {showDatePicker && (
                <Box
                  ref={datePickerRef}
                  sx={{
                    position: 'absolute',
                    top: '100%',
                    left: 0,
                    mt: 1,
                    zIndex: 1000,
                    boxShadow: '0 4px 20px rgba(0,0,0,0.1)',
                    borderRadius: 2,
                    bgcolor: 'white',
                    transform: isMobile ? 'scale(0.9)' : 'none',
                    transformOrigin: 'top left',
                  }}
                >
                  <DateRange
                    onChange={handleRangeChange}
                    moveRangeOnFirstSelection={false}
                    ranges={dateRange}
                    direction={isMobile ? 'vertical' : 'horizontal'}
                    months={isMobile ? 1 : undefined}
                  />
                </Box>
              )}
            </Box>

            {moment(dateRange[0].startDate).format('YYYY-MM-DD') !==
              moment(dateRange[0].endDate).format('YYYY-MM-DD') && (
              <Box sx={{ mb: 3 }}>
                <Box
                  sx={{ display: 'flex', alignItems: 'center', gap: 1, mb: 1 }}
                >
                  <Clock size={18} className="text-gray-400" />
                  <Typography
                    variant={isMobile ? 'body2' : 'subtitle2'}
                    sx={{ color: '#64748b' }}
                  >
                    Apply to Days
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: 'grid',
                    gridTemplateColumns: {
                      xs: 'repeat(auto-fill, minmax(35px, 1fr))',
                      sm: 'repeat(auto-fill, minmax(43px, 1fr))',
                    },
                    gap: { xs: 1, sm: 1.5 },
                  }}
                >
                  {days.map(({ label, value }) => (
                    <FormControlLabel
                      key={value}
                      control={
                        <Checkbox
                          name="days"
                          value={value}
                          checked={formik.values.days.includes(value)}
                          onChange={(e) => {
                            const { checked, value } = e.target;
                            const newDays = checked
                              ? [...formik.values.days, value]
                              : formik.values.days.filter(
                                  (day) => day !== value,
                                );
                            formik.setFieldValue('days', newDays);
                          }}
                          sx={{
                            '&.Mui-checked': {
                              color: '#3b82f6',
                            },
                            padding: isMobile ? '4px' : '8px',
                          }}
                        />
                      }
                      label={
                        <Typography
                          variant={isMobile ? 'caption' : 'body2'}
                          sx={{ color: '#64748b' }}
                        >
                          {label.substring(0, 2)}
                        </Typography>
                      }
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        m: 0,
                        '& .MuiTypography-root': {
                          fontSize: isMobile ? '10px' : '12px',
                        },
                      }}
                    />
                  ))}
                </Box>
              </Box>
            )}

            <Box sx={{ mb: 3 }}>
              <Box
                sx={{ display: 'flex', alignItems: 'center', gap: 1, mb: 1 }}
              >
                <Tags size={18} className="text-gray-400" />
                <Typography
                  variant={isMobile ? 'body2' : 'subtitle2'}
                  sx={{ color: '#64748b' }}
                >
                  Restriction Type
                </Typography>
              </Box>
              <FormControl fullWidth>
                <Select
                  name="restriction"
                  value={formik.values.restriction}
                  onChange={formik.handleChange}
                  sx={{
                    '& .MuiSelect-select': {
                      py: { xs: 1, sm: 1.5 },
                      fontSize: isMobile ? '0.875rem' : '1rem',
                    },
                  }}
                >
                  <MenuItem value="manualPrice">Rate</MenuItem>
                  <MenuItem value="stopSell">Stop Sell</MenuItem>
                  <MenuItem value="availability">Availability</MenuItem>
                  <MenuItem value="min_stay_arrival">min_stay_arrival</MenuItem>
                  <MenuItem value="max_stay">max_stay</MenuItem>
                  <MenuItem value="closed_to_arrival">
                    closed_to_arrival
                  </MenuItem>
                  <MenuItem value="closed_to_departure">
                    closed_to_departure
                  </MenuItem>
                </Select>
              </FormControl>
            </Box>

            {formik.values.restriction === 'stopSell' && (
              <Box sx={{ mb: 3 }}>
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    p: { xs: 1.5, sm: 2 },
                    border: '1px solid #e2e8f0',
                    borderRadius: 1,
                    bgcolor: formik.values.stopSell ? '#fee2e2' : 'transparent',
                  }}
                >
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 1.5 }}>
                    <Lock
                      size={18}
                      className={
                        formik.values.stopSell
                          ? 'text-red-500'
                          : 'text-gray-400'
                      }
                    />
                    <Box>
                      <Typography
                        variant={isMobile ? 'body2' : 'subtitle2'}
                        sx={{
                          color: formik.values.stopSell ? '#ef4444' : '#64748b',
                          fontWeight: 600,
                        }}
                      >
                        Stop Sell
                      </Typography>
                      <Typography
                        variant={isMobile ? 'caption' : 'body2'}
                        sx={{
                          color: formik.values.stopSell ? '#dc2626' : '#94a3b8',
                          display: 'block',
                          mt: 0.5,
                        }}
                      >
                        {formik.values.stopSell
                          ? 'Room is currently blocked for sales'
                          : 'Room is available for booking'}
                      </Typography>
                    </Box>
                  </Box>
                  <Switch
                    name="stopSell"
                    checked={formik.values.stopSell}
                    onChange={formik.handleChange}
                    sx={{
                      '& .MuiSwitch-switchBase.Mui-checked': {
                        color: '#ef4444',
                      },
                      '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track':
                        {
                          backgroundColor: '#ef4444',
                        },
                    }}
                  />
                </Box>
              </Box>
            )}

            {formik.values.restriction === 'manualPrice' && (
              <Box sx={{ mb: 3 }}>
                <Box
                  sx={{ display: 'flex', alignItems: 'center', gap: 1, mb: 1 }}
                >
                  <Tags size={18} className="text-gray-400" />
                  <Typography
                    variant={isMobile ? 'body2' : 'subtitle2'}
                    sx={{ color: '#64748b' }}
                  >
                    Price
                  </Typography>
                </Box>
                <TextField
                  fullWidth
                  type="number"
                  name="price"
                  value={formik.values.price}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  InputProps={{
                    startAdornment: (
                      <Typography
                        variant={isMobile ? 'caption' : 'body2'}
                        sx={{ color: '#64748b', mr: 1 }}
                      >
                        DH
                      </Typography>
                    ),
                  }}
                  error={formik.touched.price && Boolean(formik.errors.price)}
                  helperText={formik.touched.price && formik.errors.price}
                  sx={{
                    '& .MuiInputBase-input': {
                      fontSize: isMobile ? '0.875rem' : '1rem',
                    },
                  }}
                />
              </Box>
            )}

            {formik.values.restriction === 'availability' && (
              <Box sx={{ mb: 3 }}>
                <Box
                  sx={{ display: 'flex', alignItems: 'center', gap: 1, mb: 1 }}
                >
                  <Lock size={18} className="text-gray-400" />
                  <Typography
                    variant={isMobile ? 'body2' : 'subtitle2'}
                    sx={{ color: '#64748b' }}
                  >
                    Available Rooms
                  </Typography>
                </Box>
                <TextField
                  fullWidth
                  type="number"
                  name="availability"
                  value={formik.values.availability}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.availability &&
                    Boolean(formik.errors.availability)
                  }
                  helperText={
                    formik.touched.availability && formik.errors.availability
                  }
                  inputProps={{ min: 0 }}
                  sx={{
                    '& .MuiInputBase-input': {
                      fontSize: isMobile ? '0.875rem' : '1rem',
                    },
                  }}
                />
              </Box>
            )}
            {formik.values.restriction === 'min_stay_arrival' && (
              <Box sx={{ mb: 3 }}>
                <Box
                  sx={{ display: 'flex', alignItems: 'center', gap: 1, mb: 1 }}
                >
                  <Lock size={18} className="text-gray-400" />
                  <Typography
                    variant={isMobile ? 'body2' : 'subtitle2'}
                    sx={{ color: '#64748b' }}
                  >
                    min Stay
                  </Typography>
                </Box>
                <TextField
                  fullWidth
                  type="number"
                  name="min_stay_arrival"
                  value={formik.values.min_stay_arrival}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.min_stay_arrival &&
                    Boolean(formik.errors.min_stay_arrival)
                  }
                  helperText={
                    formik.touched.min_stay_arrival &&
                    formik.errors.min_stay_arrival
                  }
                  inputProps={{ min: 0 }}
                />
              </Box>
            )}
            {formik.values.restriction === 'max_stay' && (
              <Box sx={{ mb: 3 }}>
                <Box
                  sx={{ display: 'flex', alignItems: 'center', gap: 1, mb: 1 }}
                >
                  <Lock size={18} className="text-gray-400" />
                  <Typography variant="subtitle2" sx={{ color: '#64748b' }}>
                    min Stay
                  </Typography>
                </Box>
                <TextField
                  fullWidth
                  type="number"
                  name="max_stay"
                  value={formik.values.max_stay}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.max_stay && Boolean(formik.errors.max_stay)
                  }
                  helperText={formik.touched.max_stay && formik.errors.max_stay}
                  inputProps={{ min: 0 }}
                />
              </Box>
            )}
            {formik.values.restriction === 'closed_to_arrival' && (
              <Box sx={{ mb: 3 }}>
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    p: 2,
                    border: '1px solid #e2e8f0',
                    borderRadius: 1,
                    bgcolor: formik.values.closed_to_arrival
                      ? '#fee2e2'
                      : 'transparent',
                  }}
                >
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 1.5 }}>
                    <Lock
                      size={18}
                      className={
                        formik.values.closed_to_arrival
                          ? 'text-red-500'
                          : 'text-gray-400'
                      }
                    />
                    <Box>
                      <Typography
                        variant="subtitle2"
                        sx={{
                          color: formik.values.closed_to_arrival
                            ? '#ef4444'
                            : '#64748b',
                          fontWeight: 600,
                        }}
                      >
                        Closed to arrival
                      </Typography>
                      <Typography
                        variant="caption"
                        sx={{
                          color: formik.values.closed_to_arrival
                            ? '#dc2626'
                            : '#94a3b8',
                          display: 'block',
                          mt: 0.5,
                        }}
                      >
                        {formik.values.closed_to_arrival
                          ? 'Room is closed to arrive'
                          : 'Room is available for arrival'}
                      </Typography>
                    </Box>
                  </Box>
                  <Switch
                    name="closed_to_arrival"
                    checked={formik.values.closed_to_arrival}
                    onChange={formik.handleChange}
                    sx={{
                      '& .MuiSwitch-switchBase.Mui-checked': {
                        color: '#ef4444',
                      },
                      '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track':
                        {
                          backgroundColor: '#ef4444',
                        },
                    }}
                  />
                </Box>
              </Box>
            )}
            {formik.values.restriction === 'closed_to_departure' && (
              <Box sx={{ mb: 3 }}>
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    p: 2,
                    border: '1px solid #e2e8f0',
                    borderRadius: 1,
                    bgcolor: formik.values.closed_to_departure
                      ? '#fee2e2'
                      : 'transparent',
                  }}
                >
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 1.5 }}>
                    <Lock
                      size={18}
                      className={
                        formik.values.closed_to_departure
                          ? 'text-red-500'
                          : 'text-gray-400'
                      }
                    />
                    <Box>
                      <Typography
                        variant="subtitle2"
                        sx={{
                          color: formik.values.closed_to_departure
                            ? '#ef4444'
                            : '#64748b',
                          fontWeight: 600,
                        }}
                      >
                        Closed to departure
                      </Typography>
                      <Typography
                        variant="caption"
                        sx={{
                          color: formik.values.closed_to_departure
                            ? '#dc2626'
                            : '#94a3b8',
                          display: 'block',
                          mt: 0.5,
                        }}
                      >
                        {formik.values.closed_to_departure
                          ? 'Room is closed to departure'
                          : 'Room is available for departure'}
                      </Typography>
                    </Box>
                  </Box>
                  <Switch
                    name="closed_to_departure"
                    checked={formik.values.closed_to_departure}
                    onChange={formik.handleChange}
                    sx={{
                      '& .MuiSwitch-switchBase.Mui-checked': {
                        color: '#ef4444',
                      },
                      '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track':
                        {
                          backgroundColor: '#ef4444',
                        },
                    }}
                  />
                </Box>
              </Box>
            )}
          </Box>

          <Box
            sx={{
              p: 2.5,
              bgcolor: '#f8fafc',
              borderTop: '1px solid #e2e8f0',
              display: 'flex',
              justifyContent: 'flex-end',
              gap: 2,
            }}
          >
            <Button
              variant="outlined"
              onClick={handleClose}
              disabled={formik.isSubmitting || isLoading}
              sx={{
                color: '#64748b',
                borderColor: '#e2e8f0',
                '&:hover': {
                  borderColor: '#94a3b8',
                  bgcolor: '#f1f5f9',
                },
              }}
            >
              Cancel
            </Button>
            <Button
              type="submit"
              variant="contained"
              className="!text-white"
              disabled={formik.isSubmitting || isLoading}
              sx={{
                bgcolor: '#3b82f6',
                '&:hover': {
                  bgcolor: '#2563eb',
                },
                color: 'white',
              }}
            >
              {formik.isSubmitting || isLoading ? (
                <CircularProgress size={24} color="inherit" />
              ) : (
                'Save'
              )}
            </Button>
          </Box>
        </form>
      </Box>
    </Modal>
  );
};

export default UpdateInventory;
