import React from 'react';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Card,
  CardContent,
  Chip,
  IconButton,
  Typography,
  Box
} from '@mui/material';
import { X, Home, User, Calendar, Clock, Users, DollarSign, CreditCard } from 'lucide-react';

const EventPopup = ({ event, open, onClose }) => {
  const formatDate = (date) => {
    const d = new Date(date);
    return d.toLocaleDateString('en-US', { 
      weekday: 'short', 
      month: 'short', 
      day: 'numeric', 
      year: 'numeric' 
    });
  };

  const formatTime = (time) => {
    return new Date(`2024-01-01T${time}:00`).toLocaleTimeString('en-US', {
      hour: '2-digit',
      minute: '2-digit'
    });
  };

  return (
    <Dialog 
      open={open} 
      onClose={onClose}
      maxWidth="md"
      fullWidth
    >
      <DialogContent sx={{ p: 3 }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 3 }}>
          <DialogTitle sx={{ p: 0, fontSize: '1.5rem', fontWeight: 'bold' }}>
            Reservation Details
          </DialogTitle>
          <IconButton onClick={onClose} size="small" sx={{ borderRadius: '50%', '&:hover': { bgcolor: 'grey.100' } }}>
            <X size={20} />
          </IconButton>
        </Box>

        <Card sx={{ bgcolor: 'grey.50', mb: 4 }}>
          <CardContent sx={{ p: 3 }}>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1.5 }}>
              <Home size={24} color="#2196f3" />
              <Typography variant="h6">
                {event?.propertyName || '6251 Cahuilla Avenue'}
              </Typography>
            </Box>

            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1.5, mt: 2 }}>
              <User size={20} color="#666" />
              <Box>
                <Typography variant="body2" color="text.secondary">Guest</Typography>
                <Typography variant="body1" fontWeight={500}>
                  {event?.guestName || 'John Doe'}
                </Typography>
              </Box>
            </Box>

            <Box sx={{ mt: 2 }}>
              <Typography variant="body2" color="text.secondary">Reservation Number</Typography>
              <Typography variant="h6" fontWeight="bold">
                {event?.reservationNumber || 'ABC123'}
              </Typography>
            </Box>
          </CardContent>
        </Card>

        <Box sx={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gap: 4 }}>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
            <Box>
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, color: 'text.secondary' }}>
                <Calendar size={16} />
                <Typography variant="body2">Check In</Typography>
              </Box>
              <Typography variant="body1" sx={{ mt: 0.5, fontWeight: 500 }}>
                {formatDate(event?.startDate || '2024-01-01')}
              </Typography>
            </Box>

            <Box>
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, color: 'text.secondary' }}>
                <Clock size={16} />
                <Typography variant="body2">Check In Time</Typography>
              </Box>
              <Typography variant="body1" sx={{ mt: 0.5, fontWeight: 500 }}>
                {formatTime(event?.checkInTime || '15:00')}
              </Typography>
            </Box>

            <Box>
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, color: 'text.secondary' }}>
                <Users size={16} />
                <Typography variant="body2">Guests</Typography>
              </Box>
              <Typography variant="body1" sx={{ mt: 0.5, fontWeight: 500 }}>
                {event?.numberOfGuests || '2'}
              </Typography>
            </Box>

            <Box>
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, color: 'text.secondary' }}>
                <DollarSign size={16} />
                <Typography variant="body2">Total Price</Typography>
              </Box>
              <Typography variant="h6" sx={{ mt: 0.5, fontWeight: 'bold', color: 'success.main' }}>
                ${event?.price || '150'}
              </Typography>
            </Box>
          </Box>

          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
            <Box>
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, color: 'text.secondary' }}>
                <Calendar size={16} />
                <Typography variant="body2">Check Out</Typography>
              </Box>
              <Typography variant="body1" sx={{ mt: 0.5, fontWeight: 500 }}>
                {formatDate(event?.endDate || '2024-01-03')}
              </Typography>
            </Box>

            <Box>
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, color: 'text.secondary' }}>
                <Clock size={16} />
                <Typography variant="body2">Check Out Time</Typography>
              </Box>
              <Typography variant="body1" sx={{ mt: 0.5, fontWeight: 500 }}>
                {formatTime(event?.checkOutTime || '11:00')}
              </Typography>
            </Box>

            <Box>
              <Typography variant="body2" color="text.secondary">Channel</Typography>
              <Chip 
                label={event?.channel === 'BookingCom' ? 'Booking' : event?.channel || 'Airbnb'}
                size="small"
                sx={{ mt: 0.5 }}
                color="primary"
                variant="outlined"
              />
            </Box>

            <Box>
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, color: 'text.secondary' }}>
                <CreditCard size={16} />
                <Typography variant="body2">Payment Status</Typography>
              </Box>
              <Chip
                label={event?.paid ? 'Paid' : 'Unpaid'}
                size="small"
                sx={{ mt: 0.5 }}
                color={event?.paid ? 'success' : 'error'}
              />
            </Box>
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default EventPopup;
