import React, { useEffect, useState } from 'react';
import { getLanguages } from 'features/reservation/services/serverApi.reservation';
import { Typography } from '@mui/material';




const GuestDetails = ({ editableDetails, isEditMode, handleInputChange }) => {
    const [languages, setLanguages] = useState([]);


    useEffect(() => {
        fetchLanguages();
    }, []);

    useEffect(() => {
        const firstName = editableDetails.guestFirstName || '';
        const lastName = editableDetails.guestLastName || '';
        const fullName = `${firstName} ${lastName}`.trim();
        handleInputChange({ target: { name: 'guestName', value: fullName } });
    }, [editableDetails.guestFirstName, editableDetails.guestLastName]);

    const fetchLanguages = async () => {
        try {
            const languagesData = await getLanguages();
            setLanguages(languagesData);
        } catch (error) {
            console.error('Error fetching languages:', error);
        }
    };



    const renderInputField = (label, name, type = 'text') => (
        <div className="flex flex-col">
            <label className="font-bold text-[#676a6c] text-sm mb-2">{label}</label>
            <input
                type={type}
                name={name}
                className="mb-2 focus:outline-none text-[#676a6c] focus:border-gray-300 px-3 rounded p-2"
                value={editableDetails[name]}
                readOnly={!isEditMode}
                onChange={handleInputChange}
                style={{
                    backgroundColor: isEditMode ? 'white' : '#eee',
                    border: isEditMode ? '1px solid #ccc' : '1px solid #eee',
                    fontSize: 'small',
                }}
            />
        </div>
    );

    const renderSelectField = (label, name, options) => (
        <div className="flex flex-col">
            <label className="font-bold text-[#676a6c] text-sm mb-2">{label}</label>
            <select
                name={name}
                onChange={handleInputChange}
                value={editableDetails[name]}
                disabled={!isEditMode}
                readOnly={!isEditMode}
                className="mb-2 focus:outline-none text-[#676a6c] focus:border-gray-300 px-3 rounded p-2"
                style={{
                    backgroundColor: isEditMode ? 'white' : '#eee',
                    border: isEditMode ? '1px solid #ccc' : '1px solid #eee',
                    fontSize: 'small',
                }}
            >
                {options.map(option => (
                    <option key={option._id} value={option._id}>
                        {option.name}
                    </option>
                ))}
            </select>

        </div>
    );

    return (
        <>
            <Typography variant="body1" className="mb-4 !font-bold !text-lg text-[#676a6c]">
                {editableDetails.reservationNumber ? `Reservation Number: ${editableDetails.reservationNumber}` : 'No reservation number available.'}
            </Typography>
            <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
                {renderInputField('First Name', 'guestFirstName')}
                {renderInputField('Last Name', 'guestLastName')}
                {renderInputField('Email', 'guestEmail', 'email')}
                {renderInputField('Phone', 'phone')}
                {renderInputField('Number Of Guests', 'numberOfGuests', 'number')}
                {renderSelectField('Guest Language', 'guestLanguage', languages)}
            </div>
        </>
    );
};


export default GuestDetails;
