import React, { useEffect, useState } from 'react';
import { getChannexCall } from '../services/serverApi.channex';
import GlobalTable from 'components/GlobalTable/GlobalTable';
import {
  Box,
  Modal,
  Typography,
  IconButton,
  Tooltip,
  CircularProgress,
  Paper,
  Select,
  MenuItem,
  Button,
} from '@mui/material';
import ReactJson from 'react-json-view';
import {
  X,
  Link2,
  Server,
  Code2,
  Copy,
  Maximize2,
  Minimize2,
  PlusCircle,
  Edit,
  Trash2,
  Eye,
  PlusSquare,
  Layout,
  XCircle,
  FileText,
  BookPlus,
  BookmarkPlus,
  List,
  RefreshCcw,
  SearchX,
  MessageSquare,
  MessageCircle,
  Star,
} from 'lucide-react';

const ChannexCall = () => {
  const [data, setData] = useState([]);
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(25);
  const [searchOption, setSearchOption] = useState('');
  const [totalCount, setTotalCount] = useState(0);
  const [selectedPayload, setSelectedPayload] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isFullscreen, setIsFullscreen] = useState(false);
  const [isReloading, setIsReloading] = useState(false);

  const searchOptions = [
    {
      value: 'getChannexProperty',
      label: 'Get Property',
      icon: <Eye size={16} />,
    },
    {
      value: 'createChannexProperty',
      label: 'Create Property',
      icon: <PlusCircle size={16} />,
    },
    {
      value: 'updateChannexProperty',
      label: 'Update Property',
      icon: <Edit size={16} />,
    },
    {
      value: 'deleteChannexProperty',
      label: 'Delete Property',
      icon: <Trash2 size={16} />,
    },
    {
      value: 'getChannexRoomType',
      label: 'Get Room Type',
      icon: <FileText size={16} />,
    },

    {
      value: 'createChannexRoomType',
      label: 'Create Room Type',
      icon: <PlusSquare size={16} />,
    },
    {
      value: 'updateChannexRoomType',
      label: 'Update Room Type',
      icon: <Layout size={16} />,
    },
    {
      value: 'deleteChannexRoomType',
      label: 'Delete Room Type',
      icon: <XCircle size={16} />,
    },
    {
      value: 'getChannexRatePlan',
      label: 'Get Rate Plan',
      icon: <List size={16} />,
    },

    {
      value: 'createChannexRatePlan',
      label: 'Create Rate Plan',
      icon: <BookPlus size={16} />,
    },
    {
      value: 'updateChannexRatePlan',
      label: 'Update Rate Plan',
      icon: <BookmarkPlus size={16} />,
    },
    {
      value: 'getBookingByIdApi',
      label: 'Get Booking By ID',
      icon: <Eye size={16} />,
    },
    {
      value: 'getBookingRevesionFeedApi',
      label: 'Get Booking Revision Feed',
      icon: <FileText size={16} />,
    },
    {
      value: 'getBookingByRevesionIdApi',
      label: 'Get Booking By Revision ID',
      icon: <Eye size={16} />,
    },
    {
      value: 'AcknowledgeBookingRevisionReceiving',
      label: 'Acknowledge Booking Revision',
      icon: <RefreshCcw size={16} />,
    },
    {
      value: 'sendMessageToBooking',
      label: 'Send Message To Booking',
      icon: <MessageSquare size={16} />,
    },
    {
      value: 'replyToReview',
      label: 'Reply To Review',
      icon: <MessageCircle size={16} />,
    },
    {
      value: 'getReviewById',
      label: 'Get Review By ID',
      icon: <Star size={16} />,
    },
    {
      value: 'UpdateAvailability',
      label: 'Update Availability',
      icon: <Star size={16} />,
    },
    {
      value: 'updateRate',
      label: 'Update Rate',
      icon: <Star size={16} />,
    },
  ];

  useEffect(() => {
    fetchData();
  }, [page, limit, searchOption]);

  const handleClear = () => {
    setSearchOption('');
    setPage(0);
  };
  const fetchData = async (forceReload = false) => {
    try {
      setIsLoading(true);
      if (forceReload) setIsReloading(true);

      const result = await getChannexCall({
        page,
        limit,
        search_text: searchOption,
      });

      setData(result.data || []);
      setTotalCount(result.total || 0);
    } catch (error) {
      console.error('Error fetching Channex calls:', error);
      setData([]);
    } finally {
      setIsLoading(false);
      setIsReloading(false);
    }
  };

  const handleReload = () => {
    fetchData(true);
  };

  const handlePageChange = (newPage) => {
    setPage(newPage);
  };

  const handleLimitChange = (newLimit) => {
    setLimit(newLimit);
  };

  const handleOpenPayloadModal = (payload) => {
    setSelectedPayload(payload);
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    setSelectedPayload(null);
    setIsFullscreen(false);
  };

  const formatTimestamp = (timestamp) => {
    return new Date(timestamp).toLocaleString('en-US', {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
    });
  };

  const copyToClipboard = (content) => {
    navigator.clipboard.writeText(JSON.stringify(content, null, 2));
  };

  const columns = [
    {
      field: 'success',
      header: 'Status',
      body: (rowData) => (
        <Tooltip title={`Success: ${rowData.success}`} placement="left">
          <span
            className={`${rowData.success ? 'text-green-700' : 'text-red-700'}`}
          >
            {rowData.success ? 'Success' : 'Failed'}
          </span>
        </Tooltip>
      ),
    },
    {
      field: 'statusCode',
      header: 'Code',
      body: (rowData) => (
        <Tooltip title={`Status Code: ${rowData.statusCode}`} placement="left">
          <span
            style={{
              color: rowData.success ? 'green' : 'red',
              fontWeight: 'bold',
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <Server size={16} style={{ marginRight: 5 }} />
            {rowData.statusCode}
          </span>
        </Tooltip>
      ),
    },
    {
      field: 'callName',
      header: 'Call Name',
      body: (rowData) => (
        <Tooltip title={rowData.callName} placement="left">
          <span>{rowData.callName}</span>
        </Tooltip>
      ),
    },
    {
      field: 'endPoint',
      header: 'End Point',
      body: (rowData) => (
        <Tooltip title={rowData.endPoint} placement="left">
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              maxWidth: '200px',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            }}
          >
            <Link2 size={16} style={{ marginRight: 5 }} />
            <span>{rowData.endPoint}</span>
          </div>
        </Tooltip>
      ),
    },
    {
      field: 'timestamp',
      header: 'Timestamp',
      body: (rowData) => (
        <Tooltip title={rowData.timestamp} placement="left">
          <span>{formatTimestamp(rowData.timestamp)}</span>
        </Tooltip>
      ),
    },
    {
      field: 'requestPayload',
      header: 'Request',
      body: (rowData) => (
        <Tooltip title="View Request Payload" placement="left">
          <div
            onClick={() => handleOpenPayloadModal(rowData.requestPayload)}
            style={{
              cursor: 'pointer',
              display: 'flex',
              alignItems: 'center',
              color: 'blue',
            }}
          >
            <Code2 size={16} style={{ marginRight: 5 }} />
            View Request
          </div>
        </Tooltip>
      ),
    },
    {
      field: 'responsePayload',
      header: 'Response',
      body: (rowData) => (
        <Tooltip title="View Response Payload" placement="left">
          <div
            onClick={() => handleOpenPayloadModal(rowData.responsePayload)}
            style={{
              cursor: 'pointer',
              display: 'flex',
              alignItems: 'center',
              color: 'blue',
            }}
          >
            <Code2 size={16} style={{ marginRight: 5 }} />
            View Response
          </div>
        </Tooltip>
      ),
    },
  ];

  return (
    <Box
      sx={{
        width: '100%',
        maxWidth: '100%',
        overflowX: 'hidden',
        padding: 2,
      }}
    >
      <div className="flex items-center justify-between mb-4">
        <Typography
          variant="h4"
          component="h1"
          className="flex items-center gap-1"
          gutterBottom
        >
          Channex Calls
        </Typography>
        <div className="flex items-center gap-1">
          <Tooltip title="Clear Search">
            <div
              onClick={handleClear}
              className={`
                            cursor-pointer
                            p-2
                            rounded-full
                          hover:bg-gray-100
                            transition-colors
                            duration-200
                        `}
            >
              <SearchX size={20} className="text-gray-700 hover:text-red-600" />
            </div>
          </Tooltip>
          <Tooltip title="Reload Data">
            <div
              onClick={handleReload}
              className={`
                            cursor-pointer 
                            p-2 
                            rounded-full 
                          hover:bg-gray-100 
                            transition-colors 
                            duration-200 
                            ${
                              isReloading ? 'opacity-50 cursor-not-allowed' : ''
                            }
                            `}
            >
              {isReloading ? (
                <div className="animate-spin">
                  <RefreshCcw size={20} className="text-gray-500" />
                </div>
              ) : (
                <RefreshCcw
                  size={20}
                  className="text-gray-700 hover:text-blue-600"
                />
              )}
            </div>
          </Tooltip>
        </div>
      </div>
      <Paper sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
        <Select
          value={searchOption}
          onChange={(e) => setSearchOption(e.target.value)}
          displayEmpty
          fullWidth
          renderValue={(selected) => {
            const selectedOption = searchOptions.find(
              (option) => option.value === selected,
            );
            if (!selected) return 'Select API Call';
            return (
              <div
                style={{ display: 'flex', alignItems: 'center', gap: '8px' }}
              >
                {selectedOption.icon}
                {selectedOption.label}
              </div>
            );
          }}
        >
          {searchOptions.map((option) => (
            <MenuItem
              key={option.value}
              value={option.value}
              sx={{ display: 'flex', alignItems: 'center', gap: '8px' }}
            >
              {option.icon}
              {option.label}
            </MenuItem>
          ))}
        </Select>
      </Paper>

      <GlobalTable
        data={data}
        columns={columns}
        page={page}
        onPageChange={handlePageChange}
        limit={limit}
        onLimitChange={handleLimitChange}
        totalCount={totalCount}
        hasPagination={true}
        rowsPerPageOptions={[10, 25, 50, 100]}
      />

      <Modal
        open={openModal}
        onClose={handleCloseModal}
        aria-labelledby="payload-modal-title"
      >
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: isFullscreen ? '95%' : '80%',
            height: isFullscreen ? '95vh' : '80vh',
            maxWidth: '1200px',
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 3,
            borderRadius: 2,
            overflow: 'auto',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              mb: 2,
            }}
          >
            <Typography id="payload-modal-title" variant="h6" component="h2">
              Payload Details
            </Typography>
            <Box>
              <IconButton
                onClick={() => copyToClipboard(selectedPayload)}
                title="Copy to Clipboard"
              >
                <Copy size={20} />
              </IconButton>
              <IconButton
                onClick={() => setIsFullscreen(!isFullscreen)}
                title={isFullscreen ? 'Minimize' : 'Maximize'}
              >
                {isFullscreen ? (
                  <Minimize2 size={20} />
                ) : (
                  <Maximize2 size={20} />
                )}
              </IconButton>
              <IconButton onClick={handleCloseModal} title="Close">
                <X size={20} />
              </IconButton>
            </Box>
          </Box>
          <ReactJson
            src={selectedPayload || {}}
            collapsed={false}
            theme="monokai"
            enableClipboard={true}
            displayDataTypes={false}
          />
        </Box>
      </Modal>
    </Box>
  );
};

export default ChannexCall;
