import React, { useRef } from 'react';
import { 
  List, 
  ListItem, 
  Typography, 
  Avatar, 
  Box,
  Chip,
  Divider,
  CircularProgress,
  TextField
} from '@mui/material';
import { MessageCircle } from 'lucide-react';

function MessagesList({ 
  threads, 
  onSelectThread, 
  selectedThread, 
  isLoading,
  hasMore,
  onScroll,
  searchQuery,
  onSearchChange 
}) {
  const listRef = useRef(null);

  const handleScroll = () => {
    const bottom = 
      listRef.current.scrollHeight - listRef.current.scrollTop === 
      listRef.current.clientHeight;
    if (bottom && hasMore && !isLoading) {
      onScroll();
    }
  };

  const getLastMessage = (thread) => {
    if (!thread?.messages?.length) return null;
    return thread.messages[thread.messages.length - 1];
  };

  const getMessagePreview = (message) => {
    if (!message) return 'No messages';
    return message.message.length > 50 
      ? `${message.message.substring(0, 50)}...` 
      : message.message;
  };

  return (
    <Box className="flex flex-col h-full w-full">
      <Typography variant="subtitle1" className="px-4 py-3 border-b font-medium">
        Message Threads
      </Typography>

      <Box className="px-4 py-2">
        <TextField
          fullWidth
          variant="outlined"
          placeholder="Search messages..."
          value={searchQuery || ''}
          onChange={onSearchChange}
          size="small"
        />
      </Box>

      {isLoading && threads.length === 0 ? (
        <Box display="flex" justifyContent="center" alignItems="center" minHeight="200px">
          <CircularProgress />
        </Box>
      ) : threads.length === 0 ? (
        <Box display="flex" justifyContent="center" alignItems="center" minHeight="200px">
          <Typography>No message threads found</Typography>
        </Box>
      ) : (
        <List 
          ref={listRef}
          onScroll={handleScroll}
          className="overflow-y-scroll scrollbar-thin scrollbar-thumb-medium-aquamarine scrollbar-track-gray-100"
          style={{ 
            maxHeight: 'calc(100vh - 200px)', 
            overflowY: 'scroll',
            scrollbarWidth: 'thin',
            scrollbarGutter: 'stable'
          }}
        >
          {threads.map((thread) => {
            const lastMessage = getLastMessage(thread);
            const hasUnreadMessages = thread.unreadCount > 0;
            
            return (
              <React.Fragment key={thread._id}>
                <ListItem 
                  button
                  selected={selectedThread?._id === thread._id}
                  onClick={() => onSelectThread(thread)}
                  className={`hover:bg-gray-50 transition-colors px-4 py-2 ${
                    hasUnreadMessages ? 'bg-blue-50' : ''
                  }`}
                  sx={{ 
                    '&.Mui-selected': {
                      backgroundColor: '#f3f4f6',
                      '&:hover': {
                        backgroundColor: '#e5e7eb',
                      }
                    }
                  }}
                >
                  <Box className="flex flex-col w-full gap-1.5">
                    <Box className="flex justify-between items-start">
                      <Box className="flex gap-2 items-center min-w-0">
                        <Avatar sx={{ width: 32, height: 32 }}>
                          {thread.guestName?.[0] || 'G'}
                        </Avatar>
                        <Box className="min-w-0">
                          <Typography variant="body2" className="font-medium truncate">
                            {thread.guestName || 'Guest'}
                          </Typography>
                          <Typography variant="caption" color="textSecondary" className="truncate block">
                            {thread.reservationNumber}
                          </Typography>
                        </Box>
                      </Box>
                      <Box className="flex items-center gap-2">
                        {hasUnreadMessages && (
                          <Chip
                            label={thread.unreadCount}
                            size="small"
                            color="primary"
                            className="!h-5 min-w-5"
                          />
                        )}
                        <Box className="flex items-center gap-1">
                          <MessageCircle className="w-4 h-4 text-gray-400" />
                          <Typography variant="caption">
                            {thread.messages?.length || 0}
                          </Typography>
                        </Box>
                      </Box>
                    </Box>

                    <Typography 
                      variant="caption" 
                      color="textSecondary" 
                      className={`line-clamp-2 ${hasUnreadMessages ? 'font-medium' : ''}`}
                    >
                    </Typography>

                    <Box className="flex justify-between items-center mt-0.5">
                      <Typography variant="caption" color="textSecondary">
                        {lastMessage ? new Date(lastMessage.date).toLocaleString() : 'No messages'}
                      </Typography>
                      <Chip 
                        size="small"
                        label={thread.channelName}
                        className="bg-blue-50 text-blue-600"
                        sx={{ height: 20, '& .MuiChip-label': { px: 1, fontSize: '0.675rem' } }}
                      />
                    </Box>
                  </Box>
                </ListItem>
                <Divider sx={{ borderColor: '#e5e7eb', margin: '0' }} />
              </React.Fragment>
            );
          })}
          {isLoading && hasMore && (
            <Box display="flex" justifyContent="center" p={2}>
              <CircularProgress size={24} />
            </Box>
          )}
        </List>
      )}
    </Box>
  );
}

export default MessagesList;