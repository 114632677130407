import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
// import { Card, CardContent, Typography, Grid, Chip, Box, ImageList, ImageListItem, Button } from '@mui/material';
// import { Pool, Elevator, Security, LocalParking, Balcony } from '@mui/icons-material';
import { getProjectsAndUnits } from '../services/serverApi.ProjectUnits';
import defaultImage from 'assets/images/image_placeholder.jpg';
import DashboardLayout from 'components/LayoutContainers/DashboardLayout';
import DashboardNavbar from 'components/Navbars/DashboardNavbar';
import VisibilityIcon from '@mui/icons-material/Visibility';
import Pagination from 'components/GlobalTable/Pagination';

import { Card, CardContent, Typography, Grid, Chip, Box, ImageList, ImageListItem, LinearProgress, Divider, Paper, IconButton, Button, CircularProgress } from '@mui/material';
import { Pool, Elevator, Security, LocalParking, Balcony, House, ExpandMore, ExpandLess } from '@mui/icons-material';



const ProjectUnits = () => {
  const navigate = useNavigate();
  const [projectsData, setProjectsData] = useState([]);
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(5);
  const [loading, setLoading] = useState(false);
  const rowsPerPageOptions = [5, 10, 20, 30, 50].map(num => ({ label: num.toString(), value: num }));


  useEffect(() => {
    fetchProjects();
  }, [page, limit]);

  const fetchProjects = async () => {
    try {
      setLoading(true);
      const response = await getProjectsAndUnits({ page, limit });
      setProjectsData(response.data.data);
    } catch (error) {
      console.error('Error fetching projects:', error);
    } finally {
      setLoading(false);
    }
  };

  const handlePageChange = (newPage) => {
    setPage(newPage);
  };

  const handleLimitChange = (newLimit) => {
    setLimit(newLimit);
  };



  const handleUnitDetails = (unitId) => {
    navigate(`/admin/units/${unitId}`);
  };

  const handleProjectDetails = (projectId) => {
    navigate(`/admin/projectDetails/${projectId}`);
  };


  const formatDate = (date) => {
    return new Date(date).toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
    });
  }



  const IndividualUnitCard = ({ unit }) => (
    <Card className="!transform !transition-all !duration-500 !hover:scale-[1.01] !bg-white !shadow-lg !hover:shadow-2xl !rounded-2xl !overflow-hidden !border-0 md:h-[280px] h-[420px] !mb-8">
      <CardContent className="!p-0 !h-full">
        <Grid container className="!h-full">
          <Grid item xs={12} md={5} className="md:h-[100%] h-[140px]">
            <Box className="!relative !h-full !w-full">
              <img
                src={unit.images[0]?.url || defaultImage}
                alt="Property"
                className="!w-full !h-full !object-cover"
              />
              <Box className="!absolute !inset-0 !bg-gradient-to-t !from-black/50 !to-transparent" />
              <Chip
                label="For Sale"
                className="!absolute !top-3 !left-3 !bg-white/90 !text-emerald-600 !font-semibold !shadow-xl !px-2 !py-0.5"
              />
              <Box className="!absolute !bottom-3 !left-3 !flex !gap-2">
                {unit.swimmingPool && <Pool className="!text-white !w-5 !h-5" />}
                {unit.elevator && <Elevator className="!text-white !w-5 !h-5" />}
                {unit.security && <Security className="!text-white !w-5 !h-5" />}
                {unit.garage && <LocalParking className="!text-white !w-5 !h-5" />}
                {unit.balcony && <Balcony className="!text-white !w-5 !h-5" />}
              </Box>
            </Box>
          </Grid>

          <Grid item xs={12} md={7} className="md:h-[100%] h-[360px]">
            <Box className="!p-4 !h-full">
              <div className="!flex !flex-col !h-full !justify-between !gap-3">
                <div>
                  <div className="!flex !items-center !justify-between !mb-2">
                    <div className="!flex !flex-row !gap-2 !items-center">
                      <Typography className="!font-medium !text-gray-700">
                        {unit.unitName}
                      </Typography>
                      <Button
                        onClick={() => handleUnitDetails(unit._id)}
                        className="!bg-gray-100 !text-gray-700 !hover:!bg-gray-200 !p-2 !min-w-0 !rounded-lg"
                      >
                        <VisibilityIcon className="!w-5 !h-5" />
                      </Button>
                    </div>
                    <Typography variant="h6" className="!font-bold !text-emerald-600">
                      ${unit.salePrice.toLocaleString()}
                    </Typography>
                  </div>

                  <Typography className="!text-gray-600 !text-sm">
                    {unit.address}, {unit.district}
                  </Typography>

                  <Typography className="!text-gray-600 !text-sm !line-clamp-2 !mt-2">
                    {unit.description}
                  </Typography>

                  <Box className="!flex !flex-wrap !gap-2 !mt-2">
                    <Chip
                      icon={<House className="!text-emerald-600 !w-4 !h-4" />}
                      label={`${unit.totalArea} m²`}
                      className="!bg-emerald-50 !text-emerald-700 !font-medium !text-xs !h-6"
                    />
                    <Chip 
                      label={`${unit.livingArea} m² habitable`} 
                      size="small" 
                      className="!bg-emerald-50 !text-emerald-700 !font-medium !text-xs !h-6" 
                    />
                    <Chip 
                      label={`${unit.balconyArea} m² balcon`} 
                      size="small" 
                      className="!bg-emerald-50 !text-emerald-700 !font-medium !text-xs !h-6" 
                    />
                    <Chip 
                      label={`${(unit.salePrice / unit.totalArea).toFixed(2)} $/m²`} 
                      size="small" 
                      className="!bg-emerald-50 !text-emerald-700 !font-medium !text-xs !h-6" 
                    />
                    <Chip 
                      label={`Étage ${unit.floor}`} 
                      size="small" 
                      className="!bg-emerald-50 !text-emerald-700 !font-medium !text-xs !h-6" 
                    />
                    <Chip label={`${unit.numberOfBedrooms} Lits`} size="small" className="!bg-emerald-50 !text-emerald-700 !font-medium !text-xs !h-6" />
                    <Chip label={`${unit.numberOfBathrooms} SDB`} size="small" className="!bg-emerald-50 !text-emerald-700 !font-medium !text-xs !h-6" />
                  </Box>
                </div>
              </div>
            </Box>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );


  const ProjectCard = ({ project }) => (
    <Card className="!transform !transition-all !duration-500 !hover:scale-[1.01] !bg-white !shadow-lg !hover:shadow-2xl !rounded-2xl !overflow-hidden !border-0 md:h-[280px] h-[420px] !mb-8">
      <CardContent className="!p-0 !h-full">
        <Grid container className="!h-full">
          <Grid item xs={12} md={5} className="md:h-[100%] h-[140px]">
            <Box className="!relative !h-full !w-full">
              <img
                src={project.dataproject.images[0]?.url || defaultImage}
                alt="Project"
                className="!w-full !h-full !object-cover"
              />
              <Box className="!absolute !inset-0 !bg-gradient-to-t !from-black/50 !to-transparent" />
              <Chip
                label={project.dataproject.status}
                className="!absolute !top-3 !left-3 !font-semibold !shadow-xl !px-2 !py-0.5"
                color={project.dataproject.status === 'Under-Construction' ? 'warning' : 'success'}
              />
            </Box>
          </Grid>

          <Grid item xs={12} md={7} className="md:h-[100%] h-[360px]">
            <Box className="!p-4 !h-full">
              <div className="!flex !flex-col !h-full !justify-between !gap-3">
                <div>
                  <div className="!flex !items-center !justify-between !mb-2">
                    <div className="!flex !flex-row !gap-2 !items-center">
                      <Typography variant="h6" className="!font-bold !text-gray-900">
                        {project.dataproject.projectName}
                      </Typography>
                      <Button
                        onClick={() => handleProjectDetails(project.dataproject.id)}
                        className="!bg-gray-100 !text-gray-700 !hover:!bg-gray-200 !p-2 !min-w-0 !rounded-lg"
                      >
                        <VisibilityIcon className="!w-5 !h-5" />
                      </Button>

                    </div>

                    <Typography className="!font-bold !text-emerald-600">
                      ${project.dataproject.startingPrice.toLocaleString()}
                    </Typography>
                  </div>

                  <Typography className="!text-gray-600 !text-sm !line-clamp-2">
                    {project.dataproject?.description}
                  </Typography>

                  <Grid container spacing={2} className="!mt-2">
                    <Grid item xs={6}>
                      <Typography className="!text-gray-500 !text-xs">
                        Starting Price
                      </Typography>
                      <Typography className="!text-emerald-600 !font-bold">
                        ${project.dataproject.startingPrice.toLocaleString()}
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography className="!text-gray-500 !text-xs">
                        Type
                      </Typography>
                      <Typography className="!text-emerald-600 !font-bold">
                        {project.dataproject.projectType}
                      </Typography>
                    </Grid>
                  </Grid>

                  <Box className="!grid grid-cols-2 md:grid-cols-2 !gap-2 !mt-2">
                    {project.units.slice(0, 3).map(unit => (
                      <Box key={unit._id} className="!bg-gray-50 !rounded-lg !p-2 md:w-[13rem] w-auto">
                        <div className="!flex !flex-row !items-center !gap-2 !justify-between">
                          <Typography className="!text-xs !font-medium !text-gray-900">
                            {unit.unitName}
                          </Typography>
                          <Typography className="!text-emerald-600 !text-sm !font-bold">
                            ${unit.salePrice.toLocaleString()}
                          </Typography>
                          <Typography className="!text-gray-500 !text-xs">
                            {unit.totalArea} m²
                          </Typography>
                        </div>
                      </Box>
                    ))}
                  </Box>
                </div>
              </div>
            </Box>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Box className="!px-4 sm:!px-8 !py-6 sm:!py-10 !bg-gray-50 !min-h-screen">
        <Typography variant="h4" className="!font-bold !text-gray-800 !mb-8">
          Immobilier - Projets et Unites
        </Typography>

        {loading ? (
          <Box className="!flex !justify-center !items-center !min-h-[400px]">
            <CircularProgress
              size={50}
              thickness={4}
              className="!text-emerald-600"
            />
          </Box>
        ) : (
          <>
            {projectsData.length === 0 ? (
              <Box className="text-center py-8">
                <Typography variant="h6" color="textSecondary">
                  No projects or units found
                </Typography>
              </Box>
            ) : (
              <>
                {projectsData.map((item, index) => (
                  item.dataproject ? (
                    <ProjectCard key={`project-${index}`} project={item} />
                  ) : (
                    <IndividualUnitCard key={`unit-${index}`} unit={item.unit} />
                  )
                ))}
              </>
            )}
            <Box className="!mt-8 !bg-white !rounded-xl !shadow-md !p-4">
              <Pagination
                page={page}
                onPageChange={handlePageChange}
                isNextDisabled={projectsData.length < limit}
                limit={limit}
                onLimitChange={handleLimitChange}
                rowsPerPageOptions={rowsPerPageOptions}
                className="!text-emerald-600"
              />
            </Box>
          </>
        )}
      </Box>
    </DashboardLayout>
  );
};

export default ProjectUnits;