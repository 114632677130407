import React, { useState, useEffect } from 'react';
import "primereact/resources/themes/lara-light-cyan/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import GlobalTable from '../../../components/GlobalTable/GlobalTable'; 
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { getOpenAiInit,removeOpenAiInit } from '../services/serverApi.adminConfig';
import AddOpenAiInitDialog from './AddOpenAI.component ';
import ModifyOpenAiInitDialog from './ModifyOpenAI.component';
import AddIcon from '@mui/icons-material/Add'; 
import { Paper, Grid, Button, IconButton,  CircularProgress, Box} from '@mui/material';
import EditOffIcon from '@mui/icons-material/EditOff';
import DeleteSweepIcon from '@mui/icons-material/DeleteSweep';
import OpenAILogo from '../../../helpers/OpenAILogo'; 


function OpenAiInit() {
  const [openAiItem, setOpenAiItem] = useState([]);
  const [loading, setLoading] = useState(true);
  const [openDialog, setOpenDialog] = useState(false);
  const [openDialogM, setOpenDialogM] = useState(false);
  const [dataOpenAi, setDataOpenAi] = useState(null);
  const [openAiIndex, setOpenAiIndex] = useState(null);

  const openAiItems = async () => {
    try {
      const data = await getOpenAiInit();
      setOpenAiItem(data.data?.openAiInits);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    openAiItems();
  }, [])

  const handleOpenDialog = () => setOpenDialog(true);
  const handleCloseDialog = () => setOpenDialog(false);

  const handleCloseDialogM = () => {
    setOpenDialogM(false);
  };

  const handleOpenDialogM = (data, index) => {
    setOpenDialogM(true);
    setDataOpenAi(data);
    setOpenAiIndex(index);
  };

  const addOpenAiInit = (newItem) => {
    setOpenAiItem([...openAiItem, newItem.data?.openAiInit]);
    handleCloseDialog(); 
  };
  const handleDelete = async (openAiId, index) => {
    const confirmed = window.confirm("Are you sure you want to delete this item?");
    if (confirmed) {
        removeOpenAiInit(openAiId).then((response) => {
  
            if (response.status === 200) {
                const updatedData = openAiItem.filter((item, i) => i !== index);
                setOpenAiItem(updatedData);
                toast.success("Deleted Successfully");
            } else {
                console.error('Error deleting Event');
            }
        });
    }
  };
  const columns = [
    {
      field: "type",
      header:'Type',
      headerClassName: "header-test",
      body: (rowData) => (
        <span className="block mx-auto text-center  text-medium-aquamarine font-bold cursor-pointer">
          {rowData?.type}
        </span>
      )
    },
    {
      field: "api_key",
      header:'Api key',
      headerClassName: "header-test",
      body: (rowData) => (
        <span className="block mx-auto text-center  text-medium-aquamarine font-bold cursor-pointer">
          {rowData?.api_key}
        </span>
      )
    },
    {
      field: "embedding_model",
      header:'Embedding model',
      headerClassName: "header-test",
      body: (rowData) => (
        <span className="block mx-auto text-center text-medium-aquamarine font-bold cursor-pointer">
          {rowData?.embedding_model}
        </span>
      )
    },
    {
      field: "null",
      header:'Action',
      headerClassName: "header-test",
      body: (rowData, { rowIndex }) => (
          <div className="flex  gap-2 justify-center items-center">
            <button className="px-2 py-1 bg-medium-aquamarine !rounded-md" onClick={() => handleOpenDialogM(rowData,rowIndex)}>
              <EditOffIcon className="text-white" />
            </button>
            <button className="px-2 py-1 bg-[#df5454] !rounded-md" onClick={() => handleDelete(rowData._id,rowIndex)}>
              <DeleteSweepIcon className="text-white" />
            </button>
          </div>
      )
    },
  ];

  return (
    <div className="card p-4">
      <Grid>
        <Grid item xs={12}>
          <Box className="flex justify-center items-center gap-1 p-4">
            <OpenAILogo size={40} />
            <span
              style={{
                fontSize: '38px',
                fontWeight: 'bold',
              }}
            >
              OpenAI
            </span>
            <span className='text-gray-700 font-medium'>Init</span>
          </Box>

          <ToastContainer position="top-right" autoClose={3000} />
          <div
            style={{
              display: 'flex',
              justifyContent: 'end',
              alignItems: 'center',
            }}
            className="mb-3"
          >
            <Button
              startIcon={<AddIcon />}
              sx={{
                float: 'right',
                color: 'white !important',
                margin: '10px',
                '&:hover': {
                  background: '#06bf9d',
                },
              }}
              className="!bg-medium-aquamarine"
              variant="contained"
              onClick={handleOpenDialog}
            >
              Add OpenAI Init
            </Button>
          </div>
        </Grid>
        {loading ? (
          <Grid item cl={12} style={{ margin: 'auto' }}>
            <CircularProgress size={30} />
          </Grid>
        ) : (
          <GlobalTable
            data={openAiItem}
            columns={columns}
            hasPagination={false}
          />
        )}
      </Grid>

      <AddOpenAiInitDialog 
        open={openDialog} 
        onClose={handleCloseDialog} 
        addOpenAiInit={addOpenAiInit} 
      />
      <ModifyOpenAiInitDialog 
        open={openDialogM} 
        onClose={handleCloseDialogM} 
        setOpenAiItem={setOpenAiItem} 
        openAiItem={openAiItem} 
        index={openAiIndex} 
        dataOpenAi={dataOpenAi} 
      />
    </div>
  );
}

export default OpenAiInit;
