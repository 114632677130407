import React from 'react';
import { FormControl, Select, MenuItem, Chip, Box } from '@mui/material';
import { styled } from '@mui/material/styles';

const channelOptions = [
  { label: 'Sojori', value: 'sojori' },
  { label: 'Booking', value: 'BookingCom' },
  { label: 'AirBNB', value: 'AirBNB' }
];

const StyledSelect = styled(Select)({
  '& .MuiSelect-select': {
    padding: '9px 32px 8px 9px !important',
    display: 'flex',
    alignItems: 'center',
    gap: 0.5,
  },
});

const ChannelNameFilter = ({ channelName, setChannelName }) => {
  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setChannelName(typeof value === 'string' ? value.split(',') : value);
  };

  const getChannelLabel = (value) => {
    const channel = channelOptions.find(opt => opt.value === value);
    return channel ? channel.label : value;
  };

  return (
    <FormControl sx={{ 
      m: { xs: 0, sm: 1 }, 
      width: '100%' 
    }}>
      <StyledSelect
        labelId="channel-filter-label"
        id="channel-filter"
        multiple
        value={channelName}
        onChange={handleChange}
        displayEmpty
        renderValue={(selected) => {
          if (selected.length === 0) {
            return <em className="!p-[1.5px]">Channel</em>;
          }
          return (
            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
              {selected.map((value) => (
                <Chip key={value} label={getChannelLabel(value)} size="small" />
              ))}
            </Box>
          );
        }}
      >
        {channelOptions.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </StyledSelect>
    </FormControl>
  );
};

export default ChannelNameFilter; 