import React, { useState, useEffect } from 'react';
import { Box, TextField, Typography, Stack } from '@mui/material';
import { toast } from 'react-toastify';
import { getMailTemplateById } from '../services/serverApi.adminConfig';
import TimeInputGroup from './TimeInputGroup';

const ConfigureForm = ({ initialData, onSubmit, onBack, updateFormData }) => {
  const [config, setConfig] = useState({
    description: initialData?.description || '',
    reservation: {
      immediately: {
        enabled: initialData?.reservation?.immediately?.enabled || false,
        day: initialData?.reservation?.immediately?.day || '',
        hours: initialData?.reservation?.immediately?.hours || '',
        minutes: initialData?.reservation?.immediately?.minutes || ''
      },
      after: {
        enabled: initialData?.reservation?.after?.enabled || false,
        day: initialData?.reservation?.after?.day || '',
        hours: initialData?.reservation?.after?.hours || '',
        minutes: initialData?.reservation?.after?.minutes || ''
      }
    },
    arrivalDate: {
      before: {
        enabled: initialData?.arrivalDate?.before?.enabled || false,
        day: initialData?.arrivalDate?.before?.day || '',
        hours: initialData?.arrivalDate?.before?.hours || '',
        minutes: initialData?.arrivalDate?.before?.minutes || ''
      },
      after: {
        enabled: initialData?.arrivalDate?.after?.enabled || false,
        day: initialData?.arrivalDate?.after?.day || '',
        hours: initialData?.arrivalDate?.after?.hours || '',
        minutes: initialData?.arrivalDate?.after?.minutes || ''
      }
    },
    departureDate: {
      before: {
        enabled: initialData?.departureDate?.before?.enabled || false,
        day: initialData?.departureDate?.before?.day || '',
        hours: initialData?.departureDate?.before?.hours || '',
        minutes: initialData?.departureDate?.before?.minutes || ''
      },
      after: {
        enabled: initialData?.departureDate?.after?.enabled || false,
        day: initialData?.departureDate?.after?.day || '',
        hours: initialData?.departureDate?.after?.hours || '',
        minutes: initialData?.departureDate?.after?.minutes || ''
      }
    }
  });

  useEffect(() => {
    updateFormData({
      description: config.description,
      reservation: config.reservation,
      arrivalDate: config.arrivalDate,
      departureDate: config.departureDate
    });
  }, [config]);


  const validateTimeValues = (values) => {
    if (!values) return false;

    const day = parseInt(values.day);
    const hours = parseInt(values.hours);
    const minutes = parseInt(values.minutes);

    if (day < 0 || day > 365) {
      toast.error('Day must be between 0 and 365');
      return false;
    }
    if (hours < 0 || hours > 23) {
      toast.error('Hours must be between 0 and 23');
      return false;
    }
    if (minutes < 0 || minutes > 59) {
      toast.error('Minutes must be between 0 and 59');
      return false;
    }

    return true;
  };

  const handleToggle = (section, timing, enabled) => {
    setConfig(prev => ({
      ...prev,
      [section]: {
        ...prev[section],
        [timing]: {
          ...prev[section][timing],
          enabled
        }
      }
    }));
  };

  const handleChange = (section, timing, field, value) => {
    value = value.replace(/[^\d]/g, '');

    const newConfig = {
      ...config,
      [section]: {
        ...config[section],
        [timing]: {
          ...config[section]?.[timing],
          [field]: value
        }
      }
    };



    const timeGroup = newConfig[section][timing];
    if (value !== '') {
      const fieldValue = parseInt(value);
      const maxValues = { day: 365, hours: 23, minutes: 59 };

      if (fieldValue < 0 || fieldValue > maxValues[field]) {
        toast.error(`${field.charAt(0).toUpperCase() + field.slice(1)} must be between 0 and ${maxValues[field]}`);
        return;
      }
    }

    setConfig(newConfig);
  };

  const handleSubmit = () => {
    const sections = ['reservation', 'arrivalDate', 'departureDate'];
    let isValid = true;

    // if (!config.messageName.trim()) {
    //   toast.error('Please enter a message name');
    //   return;
    // }

    for (const section of sections) {
      const timings = Object.keys(config[section]);
      for (const timing of timings) {
        if (!validateTimeValues(config[section][timing])) {
          isValid = false;
          break;
        }
      }
      if (!isValid) break;
    }

    if (isValid) {
      onSubmit({ ...config });
    }
  };

  return (
    <Stack spacing={3} className="!px-6 !py-2">
      <Box>
        <Typography variant="subtitle1" className="!text-base !font-bold" gutterBottom>
          Description
        </Typography>
        <TextField
          fullWidth
          placeholder="Enter description"
          value={config.description}
          onChange={(e) => setConfig({ ...config, description: e.target.value })}
        />
      </Box>

      <Box>
        <Typography variant="h6" gutterBottom>
          Reservation
        </Typography>
        <Stack spacing={2}>
          <TimeInputGroup
            section="reservation"
            timing="immediately"
            values={config.reservation?.immediately}
            onChange={handleChange}
            enabled={config.reservation?.immediately?.enabled}
            onToggle={handleToggle}
          />
          <TimeInputGroup
            section="reservation"
            timing="after"
            values={config.reservation?.after}
            onChange={handleChange}
            enabled={config.reservation?.after?.enabled}
            onToggle={handleToggle}
          />
        </Stack>
      </Box>

      <Box>
        <Typography variant="h6" gutterBottom>
          Arrival Date
        </Typography>
        <Stack spacing={2}>
          <TimeInputGroup
            section="arrivalDate"
            timing="before"
            values={config.arrivalDate?.before}
            onChange={handleChange}
            enabled={config.arrivalDate?.before?.enabled}
            onToggle={handleToggle}
          />
          <TimeInputGroup
            section="arrivalDate"
            timing="after"
            values={config.arrivalDate?.after}
            onChange={handleChange}
            enabled={config.arrivalDate?.after?.enabled}
            onToggle={handleToggle}
          />
        </Stack>
      </Box>

      <Box>
        <Typography variant="h6" gutterBottom>
          Departure Date
        </Typography>
        <Stack spacing={2}>
          <TimeInputGroup
            section="departureDate"
            timing="before"
            values={config.departureDate?.before}
            onChange={handleChange}
            enabled={config.departureDate?.before?.enabled}
            onToggle={handleToggle}
          />
          <TimeInputGroup
            section="departureDate"
            timing="after"
            values={config.departureDate?.after}
            onChange={handleChange}
            enabled={config.departureDate?.after?.enabled}
            onToggle={handleToggle}
          />
        </Stack>
      </Box>

      <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: 2, mt: 2 }}>
        <button
          onClick={onBack}
          className="px-4 py-2 !text-sm border rounded hover:bg-gray-100"
        >
          Back
        </button>
        <button
          onClick={handleSubmit}
          className="px-4 py-2 !text-sm bg-medium-aquamarine text-white rounded hover:bg-opacity-90"
        >
          Next
        </button>
      </Box>
    </Stack>
  );
};

export default ConfigureForm;