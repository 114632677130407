import React, { useState, useEffect, useCallback } from 'react';
import { Panel, PanelGroup, PanelResizeHandle } from 'react-resizable-panels';
import { Box, CircularProgress, Paper } from '@mui/material';
import MessagesList from '../components/MessagesList';
import MessageDetail from '../components/MessageDetail';
import { getMessagesThreads, getReservationsById } from '../services/serverApi.messageConfig';
import DashboardLayout from 'components/LayoutContainers/DashboardLayout';
import DashboardNavbar from 'components/Navbars/DashboardNavbar';
import useMessagesSocket from '../components/useMessagesSocket';

function MessageContainer() {
  const [threads, setThreads] = useState([]);
  const [selectedThread, setSelectedThread] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [limit] = useState(20);
  const [page, setPage] = useState(0);
  const [hasMore, setHasMore] = useState(true);
  const [searchQuery, setSearchQuery] = useState('');
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [activePanel, setActivePanel] = useState('messages');
  const { socket } = useMessagesSocket();

  const fetchThreads = async (currentPage) => {
    if (!hasMore) return;

    try {
      setIsLoading(true);
      const response = await getMessagesThreads(limit, currentPage);
      
      const threadsWithUnread = response.data.data.map(thread => ({
        ...thread,
        unreadCount: 0
      }));

      if (currentPage === 0) {
        setThreads(threadsWithUnread);
      } else {
        setThreads(prev => [...prev, ...threadsWithUnread]);
      }

      setHasMore(response.data.data.length === limit);
    } catch (error) {
      console.error('Error fetching message threads:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleSelectThread = (thread) => {
    setSelectedThread(thread);
    setThreads(prevThreads =>
      prevThreads.map(t =>
        t._id === thread._id
          ? { ...t, unreadCount: 0 }
          : t
      )
    );
    if (isMobile) {
      setActivePanel('detail');
    }
  };

  const handleNewMessage = async (data) => {
    const message = data.payload?.msgPayload || data.payload || data;
    
    const formattedMessage = {
      id: message.id,
      message: message.message,
      sender: message.sender,
      date: message.date,
      reservation_id: message.reservationId
    };

    setThreads(prevThreads => {
      const threadIndex = prevThreads.findIndex(t => t._id === formattedMessage.reservation_id);

      if (threadIndex === -1) {
        getReservationsById(formattedMessage.reservation_id)
          .then(response => {
            const reservation = response.reservation;
            const newThread = {
              _id: reservation._id,
              guestName: reservation.guestName,
              reservationNumber: reservation.reservationNumber,
              channelName: reservation.channelName,
              messages: reservation.messages || [],
              unreadCount: 1
            };
            
            setThreads(current => [newThread, ...current]);
            
            if (selectedThread?._id === newThread._id) {
              setSelectedThread(newThread);
            }
          })
          .catch(error => {
            console.error('Error fetching new reservation:', error);
          });

        return prevThreads;
      }

      const updatedThreads = [...prevThreads];
      const thread = { ...updatedThreads[threadIndex] };
      
      thread.messages = thread.messages || [];
      thread.messages = [...thread.messages, formattedMessage];

      if (selectedThread?._id !== thread._id && message.sender === 'guest') {
        thread.unreadCount = (thread.unreadCount || 0) + 1;
      }

      updatedThreads[threadIndex] = thread;

      if (selectedThread?._id === formattedMessage.reservation_id) {
        setSelectedThread(thread);
      }

      const [updatedThread] = updatedThreads.splice(threadIndex, 1);
      return [updatedThread, ...updatedThreads];
    });
  };

  useEffect(() => {
    fetchThreads(page);
  }, [page]);

  useEffect(() => {
    if (!socket) return;

    socket.on('NEW_RECIVED_MSG', handleNewMessage);
    socket.on('NEW_SENDED_MSG', handleNewMessage);

    return () => {
      socket.off('NEW_RECIVED_MSG');
      socket.off('NEW_SENDED_MSG');
    };
  }, [socket, selectedThread]);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const handleScroll = useCallback(() => {
    setPage(prevPage => prevPage + 1);
  }, []);

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
    setPage(0);
    setHasMore(true);
    fetchThreads(0);
  };

  const handlePanelChange = (panel) => {
    setActivePanel(panel);
  };

  const filteredThreads = threads.filter(thread =>
    thread.guestName?.toLowerCase().includes(searchQuery.toLowerCase()) ||
    thread.reservationNumber?.toLowerCase().includes(searchQuery.toLowerCase())
  );

  return (
    <DashboardLayout>
      <DashboardNavbar />
      {isMobile ? (
        <Box className="h-[calc(100vh-64px)]">
          {activePanel === 'messages' && (
            <Paper className="h-full">
              <MessagesList
                threads={filteredThreads}
                onSelectThread={handleSelectThread}
                selectedThread={selectedThread}
                isLoading={isLoading}
                hasMore={hasMore}
                onScroll={handleScroll}
                searchQuery={searchQuery}
                onSearchChange={handleSearchChange}
              />
            </Paper>
          )}
          {activePanel === 'detail' && selectedThread && (
            <Paper className="h-full relative">
              <div className="absolute top-3 right-0 z-10 p-2">
                <button
                  onClick={() => handlePanelChange('messages')}
                  className="p-2 bg-gray-100 hover:bg-gray-200 rounded-md text-sm font-medium text-gray-600 transition-colors"
                >
                  ← Back
                </button>
              </div>
              <MessageDetail
                thread={selectedThread}
                socket={socket}
                onMessageSent={(message) => {
                  const updatedThread = {
                    ...selectedThread,
                    messages: [...selectedThread.messages, message]
                  };
                  setSelectedThread(updatedThread);
                }}
              />
            </Paper>
          )}
        </Box>
      ) : (
        <PanelGroup direction="horizontal" style={{ height: 'calc(100vh - 64px)' }}>
          <Panel defaultSize={30} minSize={20}>
            <Paper className="h-full">
              <MessagesList
                threads={filteredThreads}
                onSelectThread={handleSelectThread}
                selectedThread={selectedThread}
                isLoading={isLoading}
                hasMore={hasMore}
                onScroll={handleScroll}
                searchQuery={searchQuery}
                onSearchChange={handleSearchChange}
              />
            </Paper>
          </Panel>
          <PanelResizeHandle className="w-2 bg-gray-200 hover:bg-gray-300 transition-colors" />
          <Panel defaultSize={70}>
            <Paper className="h-full">
              <MessageDetail 
                thread={selectedThread}
                socket={socket}
                onMessageSent={(message) => {
                  const updatedThread = {
                    ...selectedThread,
                    messages: [...selectedThread.messages, message]
                  };
                  setSelectedThread(updatedThread);
                }}
              />
            </Paper>
          </Panel>
        </PanelGroup>
      )}
    </DashboardLayout>
  );
}

export default MessageContainer;