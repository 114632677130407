import React, { useState, useEffect } from "react";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import TextField from '@mui/material/TextField';

const FilterDialog = ({ visible, onHide, onApply, initialFilters, dateTypeOptions, listings }) => {
  const [modalFilter, setModalFilter] = useState(initialFilters.globalFilter || "");
  const [modalDateType, setModalDateType] = useState(initialFilters.dateType || "");
  const [modalStartDate, setModalStartDate] = useState(initialFilters.startDate || "");
  const [modalEndDate, setModalEndDate] = useState(initialFilters.endDate || "");
  const [modalListingId, setModalListingId] = useState(initialFilters.listingId?.id || "");
  const [modalPhone, setModalPhone] = useState(initialFilters.phone || "");

  const handleApply = () => {
    console.log("Applying filter with listingId:", modalListingId);
    console.log("Date filters being applied:", {
      dateType: modalDateType,
      startDate: modalStartDate,
      endDate: modalEndDate
    });
    const filters = {
      globalFilter: modalFilter,
      dateType: modalDateType,
      startDate: modalStartDate,
      endDate: modalEndDate,
      listingId: modalListingId,
      phone: modalPhone,
    };
    onApply(filters);
  };

  const clearGuestName = () => setModalFilter("");
  const clearDateType = () => {
    setModalDateType("");
    setModalStartDate("");
    setModalEndDate("");
  };
  const clearStartDate = () => setModalStartDate("");
  const clearEndDate = () => setModalEndDate("");
  const clearListingId = () => setModalListingId("");
  const clearPhone = () => setModalPhone("");

  useEffect(() => {
    if (visible) {
      setModalFilter(initialFilters.globalFilter || "");
      setModalDateType(initialFilters.dateType || "");
      setModalStartDate(initialFilters.startDate || "");
      setModalEndDate(initialFilters.endDate || "");
      setModalListingId(initialFilters.listingId || "");
      setModalPhone(initialFilters.phone || "");
    }
  }, [visible]);

  const footer = (
    <div className="flex justify-between">
      <Button label="Cancel" className="p-button-text" onClick={onHide} />
      <Button label="Apply" className="p-button-text" onClick={handleApply} />
    </div>
  );

  const inputStyle = {
    borderRadius: "0.25rem",
    border: "1px solid #ced4da",
    height: "38px",
    padding: "0.5rem 0.75rem",
    outline: "none !important"
  };
  const fieldClass = "mb-4";
  const labelClass = "block text-black mb-2 font-medium";
  const inputContainerClass = "flex items-center";
  const clearButtonClass = "p-button-text p-button-rounded p-button-plain ml-2";

  return (
    <Dialog
      visible={visible}
      onHide={onHide}
      header="Filter"
      footer={footer}
      className="w-[90%] md:w-[40rem] bg-white"
      contentClassName="bg-white"
      footerClassName="p-3 bg-medium-aquamarine text-white border-t border-[#e9ecef]"
      headerClassName="p-3 bg-medium-aquamarine text-white border-b border-[#e9ecef]"
    >
      <div className="p-4">
        <div className={fieldClass}>
          <label className={labelClass} htmlFor="filterInput">Guest Name</label>
          <div className={inputContainerClass}>
            <InputText
              id="filterInput"
              value={modalFilter}
              onChange={(e) => setModalFilter(e.target.value)}
              className="w-full"
              style={inputStyle}
            />
            {modalFilter && (
              <Button icon="pi pi-times" className={clearButtonClass} onClick={clearGuestName} />
            )}
          </div>
        </div>

        <div className={fieldClass}>
          <label className={labelClass} htmlFor="phoneInput">Phone</label>
          <div className={inputContainerClass}>
            <InputText
              id="phoneInput"
              value={modalPhone}
              onChange={(e) => setModalPhone(e.target.value)}
              className="w-full"
              style={inputStyle}
            />
            {modalPhone && (
              <Button icon="pi pi-times" className={clearButtonClass} onClick={clearPhone} />
            )}
          </div>
        </div>

        <div className={fieldClass}>
          <label className={labelClass}>Date Type</label>
          <div className={inputContainerClass}>
            <Dropdown
              optionLabel="name"
              optionValue="value"
              value={modalDateType}
              options={dateTypeOptions}
              onChange={(e) => setModalDateType(e.value)}
              placeholder="Select a Date Type"
              className="w-full flex items-center"
              panelClassName="p-3"
              style={inputStyle}
            />
            {modalDateType && (
              <Button icon="pi pi-times" className={clearButtonClass} onClick={clearDateType} />
            )}
          </div>
        </div>

        {modalDateType && (
          <>
            <div className={fieldClass}>
              <label className={labelClass}>Start Date</label>
              <div className={inputContainerClass}>
                <TextField
                  type="date"
                  value={modalStartDate}
                  onChange={(e) => setModalStartDate(e.target.value)}
                  className="w-full"
                  sx={{ 
                    '& .MuiInputBase-input': { 
                      height: '20px',
                      padding: '8px 12px'
                    }
                  }}
                />
                {modalStartDate && (
                  <Button icon="pi pi-times" className={clearButtonClass} onClick={clearStartDate} />
                )}
              </div>
            </div>
            <div className={fieldClass}>
              <label className={labelClass}>End Date</label>
              <div className={inputContainerClass}>
                <TextField
                  type="date"
                  value={modalEndDate}
                  onChange={(e) => setModalEndDate(e.target.value)}
                  className="w-full"
                  sx={{ 
                    '& .MuiInputBase-input': { 
                      height: '20px',
                      padding: '8px 12px'
                    }
                  }}
                  inputProps={{
                    min: modalStartDate // This prevents selecting end date before start date
                  }}
                />
                {modalEndDate && (
                  <Button icon="pi pi-times" className={clearButtonClass} onClick={clearEndDate} />
                )}
              </div>
            </div>
          </>
        )}

        <div className={fieldClass}>
          <label className={labelClass}>Listing ID</label>
          <div className={inputContainerClass}>
            <Dropdown
              value={modalListingId}
              options={listings}
              onChange={(e) => {
                console.log("Dropdown value changed to:", e.value);
                setModalListingId(e.value);
              }}
              optionLabel="name"
              optionValue="id"
              placeholder="Select a Listing"
              className="w-full flex items-center"
              panelClassName="p-3"
              style={inputStyle}
            />
            {modalListingId && (
              <Button icon="pi pi-times" className={clearButtonClass} onClick={() => {
                console.log("Clearing listingId");
                setModalListingId("");
              }} />
            )}
          </div>
        </div>
      </div>
    </Dialog>
  );
};

export default FilterDialog;