import React, { useState, useRef, useEffect } from 'react';
import {
  Box,
  Typography,
  List,
  ListItem,
  TextField,
  Button,
  Avatar,
  Alert,
  Snackbar,
  IconButton
} from '@mui/material';
import { Send, Image } from 'lucide-react';
import { sendMessage } from '../services/serverApi.messageConfig';

function MessageDetail({ thread }) {
  const [messageText, setMessageText] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [alert, setAlert] = useState({ open: false, message: '', severity: 'success' });
  const [isModalOpen, setIsModalOpen] = useState(false);
  const messagesEndRef = useRef(null);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    scrollToBottom();
  }, [thread?.messages]);

  if (!thread) {
    return (
      <Box className="h-full flex items-center justify-center">
        <Typography color="textSecondary">
          Select a conversation to view messages
        </Typography>
      </Box>
    );
  }

  const isValidDate = (dateStr) => {
    if (!dateStr) return false;
    const date = new Date(dateStr);
    return date instanceof Date && !isNaN(date);
  };

  const formatDate = (dateStr) => {
    if (!isValidDate(dateStr)) {
      return 'No date available';
    }
  
    try {
      const date = new Date(dateStr);
      return new Intl.DateTimeFormat('en-US', {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
        hour: '2-digit',
        minute: '2-digit'
      }).format(date);
    } catch (error) {
      console.warn(`Error formatting date: ${dateStr}`, error);
      return 'Invalid date';
    }
  };

  const handleMessageSubmit = async () => {
    if (!messageText.trim()) return;

    setIsSubmitting(true);
    try {
      await sendMessage(messageText, thread._id);
      setAlert({
        open: true,
        message: 'Message sent successfully',
        severity: 'success'
      });
      setMessageText('');
    } catch (error) {
      setAlert({
        open: true,
        message: 'Failed to send message',
        severity: 'error'
      });
      console.error('Error sending message:', error);
    } finally {
      setIsSubmitting(false);
    }
  };

  const isPropertyMessage = (message) => message.sender === 'property';

  return (
    <Box className="flex flex-col h-full w-full">
      <Box className="p-3 md:p-4 border-b">
        <Box className="flex items-center gap-3">
          <Avatar sx={{ width: 40, height: 40 }}>
            {thread.guestName?.[0] || 'G'}
          </Avatar>
          <Box>
            <Typography variant="subtitle1" className="font-medium">
              {thread.guestName || 'Guest'}
            </Typography>
            <Typography variant="caption" color="textSecondary">
              {thread.channelName} · {thread.reservationNumber}
            </Typography>
          </Box>
        </Box>
      </Box>

      <List 
        className="flex-grow overflow-y-scroll scrollbar-thin scrollbar-thumb-medium-aquamarine scrollbar-track-gray-100 p-3 md:p-4 space-y-4"
        style={{ 
          maxHeight: 'calc(100vh - 200px)',
          scrollbarWidth: 'thin',
          scrollbarGutter: 'stable'
        }}
      >
        {thread.messages?.map((message) => (
          <ListItem 
            key={message.id} 
            className={`flex flex-col mb-3 ${
              isPropertyMessage(message) 
                ? '!items-end' 
                : '!items-start'
            }`}
          >
            <Box 
              className={`max-w-[80%] rounded-lg p-3 ${
                isPropertyMessage(message)
                  ? 'mr-auto bg-blue-50'
                  : 'ml-auto bg-gray-50'
              }`}
            >
              <Typography variant="body2" className="whitespace-pre-line mb-2">
                {message.message}
              </Typography>
              <Typography variant="caption" color="textSecondary">
                {formatDate(message.date)}
              </Typography>
            </Box>
          </ListItem>
        ))}
        <div ref={messagesEndRef} />
      </List>

      <Box className="p-4 border-t flex gap-2">
        <TextField
          fullWidth
          variant="outlined"
          value={messageText}
          onChange={(e) => setMessageText(e.target.value)}
          placeholder="Write your message..."
          multiline
          maxRows={4}
          disabled={isSubmitting}
          onKeyPress={(e) => {
            if (e.key === 'Enter' && !e.shiftKey) {
              e.preventDefault();
              handleMessageSubmit();
            }
          }}
        />
        {/* <IconButton 
          onClick={() => setIsModalOpen(true)}
          className="!bg-gray-100 hover:!bg-gray-200"
        >
          <Image className="w-5 h-5" />
        </IconButton> */}
        <Button
          variant="contained"
          className="!bg-blue-600 !text-white"
          onClick={handleMessageSubmit}
          disabled={!messageText.trim() || isSubmitting}
        >
          <Send className="w-4 h-4" />
        </Button>
      </Box>

      <Snackbar
        open={alert.open}
        autoHideDuration={6000}
        onClose={() => setAlert({ ...alert, open: false })}
      >
        <Alert
          onClose={() => setAlert({ ...alert, open: false })}
          severity={alert.severity}
        >
          {alert.message}
        </Alert>
      </Snackbar>
    </Box>
  );
}

export default MessageDetail;