import React from 'react';

export const CalendarHeader = ({
    goToNextPeriod,
    goToPreviousPeriod,
    setViewType,
    viewType,
    goToToday,
    formattedDateRange,
    scrollBackward,
    scrollForward,
    setCurrentDate,
    changeMonthView
}) => {



    const handleNavigateMonth = (direction) => {
        changeMonthView(direction);
    };



    const handleNavigateDay = (direction) => {
        if (direction === 'next') {
            setCurrentDate(prevDate => {
                const newDate = new Date(prevDate);
                newDate.setDate(newDate.getDate() + 1);
                return newDate;
            });
        } else {
            setCurrentDate(prevDate => {
                const newDate = new Date(prevDate);
                newDate.setDate(newDate.getDate() - 1);
                return newDate;
            });
        }
    };


    return (
        <div>
            <div className="flex flex-col gap-3 p-4 bg-gray-100 border-b border-gray-300 sm:grid sm:grid-cols-3">
                <div className="flex overflow-x-auto pb-2 sm:pb-0 order-2 sm:order-1">
                    <div className="flex">
                        <button onClick={() => handleNavigateDay('previous')} className="whitespace-nowrap px-2 !py-[2px] border border-gray-300 bg-white hover:bg-gray-200 !text-xs flex items-center"><i className="pi pi-angle-left"></i><span>Dy</span></button>
                        <button onClick={scrollBackward} className="whitespace-nowrap px-2 !py-[2px] border border-gray-300 bg-white hover:bg-gray-200 !text-xs flex items-center"><i className="pi pi-angle-left"></i><span>Wk</span></button>
                        <button onClick={() => handleNavigateMonth('previous')} className="whitespace-nowrap px-2 !py-[2px] border border-gray-300 bg-white hover:bg-gray-200 !text-xs flex items-center"><i className="pi pi-angle-left"></i><span>Mo</span></button>
                        <button onClick={goToToday} className="whitespace-nowrap px-3 !py-[2px] border border-gray-300 bg-white hover:bg-gray-200 !text-xs">Now</button>
                        <button onClick={() => handleNavigateMonth('next')} className="whitespace-nowrap px-2 !py-[2px] border border-gray-300 bg-white hover:bg-gray-200 !text-xs flex items-center"><i className="pi pi-angle-right"></i><span>Mo</span></button>
                        <button onClick={scrollForward} className="whitespace-nowrap px-2 !py-[2px] border border-gray-300 bg-white hover:bg-gray-200 !text-xs flex items-center"><i className="pi pi-angle-right"></i><span>Wk</span></button>
                        <button onClick={() => handleNavigateDay('next')} className="whitespace-nowrap px-2 !py-[2px] border border-gray-300 bg-white hover:bg-gray-200 !text-xs flex items-center !rounded-r-md"><i className="pi pi-angle-right"></i><span>Dy</span></button>
                    </div>
                </div>

                <div className='text-center order-1 sm:order-2'>
                    <span className="!text-sm font-semibold">{formattedDateRange}</span>
                </div>

                <div className="flex overflow-x-auto pb-2 sm:pb-0 order-3">
                    <div className="flex">
                        <button onClick={goToPreviousPeriod} className="whitespace-nowrap px-3 !py-[2px] border border-gray-300 bg-white hover:bg-gray-200 !text-xs"><i className="pi pi-angle-left"></i></button>
                        <button onClick={goToNextPeriod} className="whitespace-nowrap px-3 !py-[2px] border border-gray-300 bg-white hover:bg-gray-200 !text-xs"><i className="pi pi-angle-right"></i></button>
                        <button onClick={() => setViewType('10 Day')} className={`whitespace-nowrap px-3 !py-[2px] border border-gray-300 hover:bg-gray-200 !text-xs ${viewType === '10 Day' ? 'bg-gray-600 text-white' : 'bg-white'}`}>10 Day</button>
                        <button onClick={() => setViewType('14 Day')} className={`whitespace-nowrap px-3 !py-[2px] border border-gray-300 hover:bg-gray-200 !text-xs ${viewType === '14 Day' ? 'bg-gray-600 text-white' : 'bg-white'}`}>14 Day</button>
                        <button onClick={() => setViewType('20 Day')} className={`whitespace-nowrap px-3 !py-[2px] border border-gray-600 hover:bg-gray-200 !text-xs ${viewType === '20 Day' ? 'bg-gray-600 text-white' : 'bg-white'}`}>20 Day</button>
                        <button onClick={() => setViewType('30 Day')} className={`whitespace-nowrap px-3 !py-[2px] border border-gray-600 hover:bg-gray-200 !text-xs ${viewType === '30 Day' ? 'bg-gray-600 text-white' : 'bg-white'}`}>30 Day</button>
                        <button onClick={() => setViewType('Month')} disabled={viewType} className={`whitespace-nowrap px-3 !py-[2px] border border-gray-600 hover:bg-gray-200 !text-xs !rounded-r-md cursor-no-drop ${viewType === 'Month' ? 'bg-gray-600 text-white' : 'bg-gray-50 text-gray-600'}`}>Month</button>
                    </div>
                </div>
            </div>
        </div>
    );
};