import React, { useState, useEffect } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import DashboardLayout from 'components/LayoutContainers/DashboardLayout';
import GuestInfo from './components/createReservation/GuestInfo';
// import PaymentInfo from './components/createReservation/guestInfoComponent/PaymentInfo';
// import CostomeFieldsInfo from './components/componentsInfo/CostomeFieldsInfo';
// import DoorInfo from './components/componentsInfo/DoorInfo';
// import AttachmentInfo from './components/componentsInfo/AttachmentInfo';
// import TasksInfo from './components/componentsInfo/TasksInfo';
import { createReservation, getListings, getCalendarDetails } from '../services/serverApi.reservation';
import Box from '@mui/material/Box';
import { useNavigate } from "react-router-dom";
import { toast, ToastContainer } from 'react-toastify';
import CircularProgress from '@mui/material/CircularProgress';

const ReservationCreate = () => {
    const [visibleSection, setVisibleSection] = useState('guestInfo');
    const [listingOptions, setListingOptions] = useState([]);
    const navigate = useNavigate();
    const today = new Date().toISOString().split('T')[0];
    const [isSubmitting, setIsSubmitting] = useState(false);
    const staging = JSON.parse(localStorage.getItem('isStaging')) || false;

 
 


    const fetchListingOptions = async () => {
        try {
            const listings = await getListings(staging);
            setListingOptions(listings);
        } catch (error) {
            console.error('Error fetching listing options:', error);
        }
    };

    const formik = useFormik({
        initialValues: {
            atSojori: true,
            chekinListingId: '',
            sojoriId: '',
            listingName: '',
            channelId: 2000,
            source: null,
            arrivalDate: '',
            departureDate: '',
            checkInTime: '',
            checkOutTime: '',
            currency: 'MAD',
            status: 'Confirmed',
            guestCountry: '',
            guestCity: '',
            guestName: '',
            guestFirstName: '',
            guestLastName: '',
            guestEmail: '',
            phone: '',
            numberOfGuests: '',
            guestLanguage: '',
            checkInTime: '',
            checkOutTime: '',
            timeLine: 'Normal',
            paymentStatus: 'UnPaid',
            roomTypeId: '',
            confirmedCheckInTime: false,
            confirmedCheckOutTime: false,


        },
        validationSchema: Yup.object({
            arrivalDate: Yup.date()
                .required('Arrival Year is required')
                .min(1900, 'Year must be YYYY format')
                .max(9999, 'Year must be YYYY format')
                .test('arrivalDate', 'Arrival Year must be before or equal to Departure Year', function (value) {
                    const departureDate = this.resolve(Yup.ref('departureDate'));
                    return !departureDate || value <= departureDate;
                }),
            departureDate: Yup.date()
                .required('Departure Year is required')
                .min(1900, 'Year must be YYYY format')
                .max(9999, 'Year must be YYYY format'),
            checkInTime: Yup.string().required('Check-In Time is required'),
            checkOutTime: Yup.string().required('Check-Out Time is required'),
            currency: Yup.string().required('Currency is required'),
            status: Yup.string(),
            guestCountry: Yup.string(),
            guestCity: Yup.string(),
            guestNote: Yup.string(),
            hostNote: Yup.string(),
            guestName: Yup.string().required('Guest Name is required'),
            guestFirstName: Yup.string(),
            guestLastName: Yup.string(),
            guestEmail: Yup.string(),
            phone: Yup.string().required('Phone is required'),
            numberOfGuests: Yup.number().required('Number of Guests is required'),
            guestLanguage: Yup.string(),
            timeLine: Yup.string(),
            paymentStatus: Yup.string(),
            roomTypeId: Yup.string().required('Room Type is required'),
            confirmedCheckInTime: Yup.boolean(),
            confirmedCheckOutTime: Yup.boolean(),

        }),
        onSubmit: async (values, { setSubmitting, resetForm }) => {
            setIsSubmitting(true);

            try {
                const reservationData = {
                    ...values,
                };
                const createdReservation = await createReservation(reservationData);
                console.log('Reservation created successfully:', createdReservation);
                toast.success('Reservation created successfully');


                resetForm();
            }   catch (error) {
                console.error('Error creating reservation:', error);
                const errorMessage = 
                error.response?.data?.message || 
                error.response?.data?.error ||
                error.response?.data?.errors?.[0]?.message || 
                error.message || 
                'Error creating reservation';
                toast.error(errorMessage);
            }
            setSubmitting(false);
            setIsSubmitting(false);
        },
    });

    useEffect(() => {
        fetchListingOptions();

    }, [staging]);

    const fetchCalendarAndSetPrice = async (sojoriId) => {
        if (!sojoriId) return;
        try {
            const calendarData = await getCalendarDetails(sojoriId, today, today);
            const totalPrice = calendarData[0]?.price || 0;
            formik.setFieldValue('totalPrice', totalPrice);
        } catch (error) {
            console.error('Error fetching calendar details:', error);
        }
    };

    const handleListingChange = (e) => {
        const listingId = e.target.value;
        const selectedListing = listingOptions.find(listing => listing.id === listingId);

        formik.setFieldValue('sojoriId', listingId);
        // formik.setFieldValue('checkInTime', `${selectedListing.checkInTime}`);
        // formik.setFieldValue('checkOutTime', `${selectedListing.checkOutTime}`);

        fetchCalendarAndSetPrice(listingId);
    };

    const toggleSection = (section) => {
        setVisibleSection((prevSection) => (prevSection === section ? null : section));
    };


    return (
        <DashboardLayout>
            <ToastContainer position="top-right" autoClose={3000} />
            <div className="p-4 card">
                <form onSubmit={formik.handleSubmit}>
                    <Box sx={{ width: '100%', typography: 'body1' }}>
                        <div id="fixed_control_bar" className="w-full">
                            <div className="flex justify-between items-center p-4 sm:p-4 p-2">
                                <h5>
                                    <div className="flex items-center text-[#00b4b4] cursor-pointer" onClick={() => navigate(-1)} >
                                        <span className="sm:ml-2 ml-0 sm:text-[16px] text-[14px]">
                                            <i className="pi pi-angle-left sm:!text-[16px] !text-[14px] font-bold"></i>
                                            Reservations
                                        </span>
                                    </div>
                                </h5>
                                
                                <div className="flex items-center sm:gap-2 gap-1">
                                    {isSubmitting ? (
                                        <CircularProgress size={20} color="primary" />
                                    ) : (
                                        <>
                                            <button type="submit" className="bg-[#00b4b4] !rounded sm:w-[70px] w-[60px]" disabled={formik.isSubmitting}>
                                                <span className="ladda-label sm:text-[15px] text-[13px] text-white sm:p-[2px] p-[1px] font-semibold">Save</span>
                                            </button>
                                            <button type="button" className="bg-[#dcf6f6] !rounded sm:w-[70px] w-[60px]" onClick={() => navigate(-1)}>
                                                <span className="ladda-label sm:text-[15px] text-[13px] text-[#00b4b4] sm:p-[2px] p-[1px] font-semibold">Cancel</span>
                                            </button>
                                        </>
                                    )}
                                </div>
                            </div>
                        </div>
                    </Box>

                    <SectionHeader
                        icon="pi pi-calendar"
                        title="Guest Info"
                        isVisible={visibleSection === 'guestInfo'}
                        onClick={() => toggleSection('guestInfo')}
                    />
                    {visibleSection === 'guestInfo' && <GuestInfo formik={formik} listingOptions={listingOptions} handleListingChange={handleListingChange} />}

                    {/* <SectionHeader
                        icon="pi pi-money-bill"
                        title="Payment Info"
                        isVisible={visibleSection === 'paymentInfo'}
                        onClick={() => toggleSection('paymentInfo')}
                    />
                    {visibleSection === 'paymentInfo' && <PaymentInfo />}

                    <SectionHeader
                        icon="pi pi-star"
                        title="Custom Fields"
                        isVisible={visibleSection === 'customFields'}
                        onClick={() => toggleSection('customFields')}
                    />
                    {visibleSection === 'customFields' && <CostomeFieldsInfo />}

                    <SectionHeader
                        icon="pi pi-key"
                        title="Door Info"
                        isVisible={visibleSection === 'doorInfo'}
                        onClick={() => toggleSection('doorInfo')}
                    />
                    {visibleSection === 'doorInfo' && <DoorInfo />}

                    <SectionHeader
                        icon="pi pi-file"
                        title="Attachment"
                        isVisible={visibleSection === 'attachement'}
                        onClick={() => toggleSection('attachement')}
                    />
                    {visibleSection === 'attachement' && <AttachmentInfo />}

                    <SectionHeader
                        icon="pi pi-briefcase"
                        title="Tasks"
                        isVisible={visibleSection === 'Tasks'}
                        onClick={() => toggleSection('Tasks')}
                    />
                    {visibleSection === 'Tasks' && <TasksInfo />} */}
                </form>
            </div>
        </DashboardLayout>
    );
};

const SectionHeader = ({ icon, title, isVisible, onClick }) => (
    <div className="text-[#676a6c] font-bold rounded mb-4 cursor-pointer flex gap-2 items-center" onClick={onClick}>
        <i className={`${icon} font-bold size-4 !mr-2`} style={{ fontWeight: 'bold' }}></i>
        <span className="text-[18px] font-bold">{title}</span>
        <i className={`pi ${isVisible ? 'pi-angle-up' : 'pi-angle-down'} !ml-2`}></i>
    </div>
);

export default ReservationCreate;
