import React, { useState, useEffect, useCallback } from 'react';
import { Panel, PanelGroup, PanelResizeHandle } from 'react-resizable-panels';
import { Box, CircularProgress, Paper, Tabs, Tab } from '@mui/material';
import ReviewsList from '../ReviewsList';
import ReviewDetail from '../ReviewDetail';
import { getReviews } from '../services/serverApi.reviewConfig';
import DashboardLayout from 'components/LayoutContainers/DashboardLayout';
import DashboardNavbar from 'components/Navbars/DashboardNavbar';

function ReviewsContainer() {
  const [reviews, setReviews] = useState([]);
  const [selectedReview, setSelectedReview] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [limit, setLimit] = useState(10);
  const [hasMore, setHasMore] = useState(true);
  const [searchQuery, setSearchQuery] = useState('');
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [activePanel, setActivePanel] = useState('reviews');
  const [replyFilter, setReplyFilter] = useState(false);

  const fetchReviews = async () => {
    if (!hasMore) return;

    try {
      setIsLoading(true);
      const response = await getReviews(limit, 0, replyFilter);
      setReviews(response.data.data);
      setHasMore(response.data.data.length === limit);
    } catch (error) {
      console.error('Error fetching reviews:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleReplySubmitted = () => {
    if (selectedReview) {
      if (!replyFilter) {
        setReviews(prevReviews =>
          prevReviews.filter(review => review._id !== selectedReview._id)
        );
      } else {
        setReviews(prevReviews =>
          prevReviews.map(review =>
            review._id === selectedReview._id
              ? { ...review, is_replied: true }
              : review
          )
        );
      }
    }

    setSelectedReview(null);

    fetchReviews();
  };


  useEffect(() => {
    fetchReviews();
  }, [limit, replyFilter]);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const handleScroll = useCallback(() => {
    setLimit(prevLimit => prevLimit + 5);
  }, []);

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
    setLimit(5);
    setHasMore(true);
    setReviews([]);
    fetchReviews();
  };

  const handlePanelChange = (panel) => {
    setActivePanel(panel);
  };

  const handleTabChange = (event, newValue) => {
    setReplyFilter(newValue);
    setLimit(10);
    setHasMore(true);
    setReviews([]);
    setSelectedReview(null);
  };

  const filteredReviews = reviews.filter(review => {
    const searchQueryLower = searchQuery.toLowerCase();
    const listingName = review?.listing?.name?.toLowerCase() || '';
    const guestName = review?.reservation?.guestName?.toLowerCase() || '';

    return listingName.includes(searchQueryLower) ||
        guestName.includes(searchQueryLower);
  });

  // const ReviewsHeader = () => (
  //   <Box className="!border-b !border-b-black">
  //     <Tabs 
  //       value={replyFilter} 
  //       onChange={handleTabChange}
  //       indicatorColor="primary"
  //       textColor="primary"
  //     >
  //       <Tab label="Not Replied" value={false} />
  //       <Tab label="Replied" value={true} />
  //     </Tabs>
  //   </Box>
  // );

  return (
    <DashboardLayout>
      <DashboardNavbar />
      {isMobile ? (
        <Box className="h-[calc(100vh-64px)]">
          {activePanel === 'reviews' && (
            <Paper className="h-full">
              {/* <ReviewsHeader /> */}
              <ReviewsList
                reviews={filteredReviews}
                onSelectReview={setSelectedReview}
                selectedReview={selectedReview}
                isLoading={isLoading}
                hasMore={hasMore}
                onScroll={handleScroll}
                searchQuery={searchQuery}
                onSearchChange={handleSearchChange}
                replyFilter={replyFilter}
                onTabChange={handleTabChange}
              />
            </Paper>
          )}
          {activePanel === 'detail' && selectedReview && (
            <Paper className="h-full relative">
              <div className="absolute top-3 right-0 z-10 p-2">
                <button
                  onClick={() => handlePanelChange('reviews')}
                  className="!p-2 !bg-gray-100 !hover:bg-gray-200 !rounded-md !text-sm !font-medium !text-gray-600 !transition-colors"
                >
                  ← Back
                </button>
              </div>
              <ReviewDetail
                review={selectedReview}
                onReplySubmitted={handleReplySubmitted}
              />            </Paper>
          )}
        </Box>
      ) : (
        <PanelGroup direction="horizontal" style={{ height: 'calc(100vh - 64px)' }}>
          <Panel defaultSize={30} minSize={20}>
            <Paper className="h-full">
              <ReviewsList
                reviews={filteredReviews}
                onSelectReview={setSelectedReview}
                selectedReview={selectedReview}
                isLoading={isLoading}
                hasMore={hasMore}
                onScroll={handleScroll}
                searchQuery={searchQuery}
                onSearchChange={handleSearchChange}
                replyFilter={replyFilter}
                onTabChange={handleTabChange}
              />
            </Paper>
          </Panel>
          <PanelResizeHandle className="w-2 bg-gray-200 hover:bg-gray-300 transition-colors" />
          <Panel defaultSize={70}>
            <Paper className="h-full">
              <ReviewDetail 
                review={selectedReview} 
                onReplySubmitted={handleReplySubmitted}
              />
            </Paper>
          </Panel>
        </PanelGroup>
      )}
    </DashboardLayout>
  );
}

export default ReviewsContainer;