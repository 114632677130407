import React, { useState, useEffect } from 'react';
import { getListingsWithRatePlans, createRatePlan, updateRatePlan, getCities, getCountries, updateUseDynamicPrice } from '../services/serverApi.dynamicPricing';
import DashboardLayout from 'components/LayoutContainers/DashboardLayout';
import DashboardNavbar from 'components/Navbars/DashboardNavbar';
import FilterSection from '../components/listingRatePlan/FilterSection';
import ListingItem from '../components/listingRatePlan/ListingItem';
import PaginationControls from '../components/listingRatePlan/PaginationControls';
import CreateRatePlanDialog from '../components/listingRatePlan/CreateRatePlanDialog';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Switch, FormControlLabel } from '@mui/material';

const ListingsWithRatePlans = () => {
    const [listings, setListings] = useState([]);
    const [pagination, setPagination] = useState({ page: 0, limit: 8 });
    const [filters, setFilters] = useState({ name: '', city: '', country: '' });
    const [loading, setLoading] = useState(false);
    const [openCreateDialog, setOpenCreateDialog] = useState(false);
    const [selectedListingId, setSelectedListingId] = useState(null);
    const [cities, setCities] = useState([]);
    const [countries, setCountries] = useState([]);
    const [expandedListing, setExpandedListing] = useState(null);
    const [staging, setStaging] = useState(JSON.parse(localStorage.getItem('isStaging')) || false);
    const [newRatePlan, setNewRatePlan] = useState({
        name: 'Default Rate Plan',
        applies_to: {
            Countries: false, country_ids: [], Cities: true,
            Properties: false, property_ids: [], Room_type: true,
            room_type_ids: [], city_id: null
        },
        'adjustments-year': {
            jan: 100, feb: 100, mar: 100, apr: 100, may: 100, jun: 100,
            jul: 100, aug: 100, sep: 100, oct: 100, nov: 100, dec: 100
        },
        'adjustments-week_days': {
            mon: 100, tue: 100, wed: 100, thu: 100, fri: 100, sat: 100, sun: 100
        },
        'adjustments-event': { active: true, events: [] }
    });

    useEffect(() => {
        const handleStagingToggle = (event) => {
            setStaging(event.detail);
        };
        window.addEventListener('stagingToggle', handleStagingToggle);
        fetchListings();
        fetchCities();
        fetchCountries();
        return () => {
            window.removeEventListener('stagingToggle', handleStagingToggle);
        };
    }, [pagination.page, pagination.limit, staging]);




    const fetchCities = async () => {
        try {
            const response = await getCities();
            setCities(response.data);
        } catch (error) {
            console.error('Error fetching cities:', error);
            toast.error('Failed to fetch cities. Please try again later.');
        }
    };

    const fetchCountries = async () => {
        try {
            const response = await getCountries();
            setCountries(response.data);
        } catch (error) {
            console.error('Error fetching countries:', error);
            toast.error('Failed to fetch countries. Please try again later.');
        }
    };

    const fetchListings = async () => {
        setLoading(true);
        try {
            const response = await getListingsWithRatePlans({
                ...pagination, ...filters, sortingBy: '', staging,
            });
            setListings(response.data.data.listings);
        } catch (error) {
            console.error('Error fetching listings:', error);
            toast.error('Failed to fetch listings. Please try again later.');
        }
        setLoading(false);
    };

    const handleFilterChange = (e) => {
        setFilters(prevFilters => ({ ...prevFilters, [e.target.name]: e.target.value }));
    };

    const handlePaginationChange = (newPage) => {
        setPagination((prevPagination) => ({ ...prevPagination, page: newPage }));
    };

    const handleCreateRatePlan = async () => {
        try {
            const updatedRatePlan = {
                ...newRatePlan,
                applies_to: {
                    ...newRatePlan.applies_to,
                    Room_type: true,
                    room_type_ids: [selectedListingId]
                }
            };
            await createRatePlan(updatedRatePlan);
            setOpenCreateDialog(false);
            fetchListings();
            toast.success('Rate plan created successfully!');
        } catch (error) {
            console.error('Error creating rate plan:', error);
            if (error.response?.data?.errors && error.response.data.errors.length > 0) {
                const errorMessage = error.response.data.errors[0].message;
                const friendlyMessage = errorMessage.replace(
                    '"adjustments-event.events[0].event_name"',
                    'Event name'
                );
                toast.error(friendlyMessage);
            } else {
                toast.error('Failed to create rate plan. Please try again.');
            }
        }
    };

    const handleUpdateRatePlan = async (listingId, ratePlanId, updatedEvent) => {
        try {
            await updateRatePlan(ratePlanId, {
                'adjustments-event': updatedEvent,
                Active: updatedEvent.active
            });
            setListings(prevListings =>
                prevListings.map(listing =>
                    listing._id === listingId
                        ? {
                            ...listing,
                            roomTypes: listing.roomTypes.map(roomType => ({
                                ...roomType,
                                ratePlans: roomType.ratePlans.map(ratePlan =>
                                    ratePlan._id === ratePlanId
                                        ? { ...ratePlan, 'adjustments-event': updatedEvent, Active: updatedEvent.active }
                                        : ratePlan
                                )
                            }))
                        }
                        : listing
                )
            );
            toast.success('Rate plan updated successfully!');
        } catch (error) {
            console.error('Error updating rate plan:', error);
            if (error.response?.data?.errors && error.response.data.errors.length > 0) {
                const errorMessage = error.response.data.errors[0].message;
                const friendlyMessage = errorMessage.replace(
                    '"adjustments-event.events[0].event_name"', 
                    'Event name'
                );
                toast.error(friendlyMessage);
            } else {
                toast.error('Failed to update rate plan. Please try again.');
            }
        }
    };

    const toggleListingExpansion = (listingId) => {
        setExpandedListing(expandedListing === listingId ? null : listingId);
    };

    const handleDynamicPriceToggle = async (listingId, currentValue) => {
        try {
            await updateUseDynamicPrice(listingId, !currentValue);
            // Refresh the listings to get updated data
            fetchListings();
            toast.success('Dynamic pricing setting updated successfully!');
        } catch (error) {
            console.error('Error updating dynamic price setting:', error);
            toast.error('Failed to update dynamic pricing setting');
        }
    };

    return (
        <DashboardLayout>
            <DashboardNavbar />
            <div className="card p-4">
                <FilterSection
                    filters={filters}
                    onFilterChange={handleFilterChange}
                    pagination={pagination}
                    onPaginationChange={setPagination}
                    onSearch={fetchListings}
                    cities={cities}
                    countries={countries}
                />
                {listings.map((listing) => (
                    <div key={listing._id} className="mb-3">
                        <div
                            className="d-flex justify-content-between align-items-center p-3 bg-light"
                        >
                            <div className="d-flex align-items-center gap-4 flex-1">
                                <div 
                                    className="cursor-pointer"
                                    onClick={() => toggleListingExpansion(listing._id)}
                                >
                                    <h5 className="mb-0">{listing.name}</h5>
                                </div>
                                <Switch
                                    checked={listing.useDynamicPrice || false}
                                    onChange={() => handleDynamicPriceToggle(listing._id, listing.useDynamicPrice)}
                                    name="useDynamicPrice"
                                />
                            </div>
                            <span 
                                className="cursor-pointer"
                                onClick={() => toggleListingExpansion(listing._id)}
                            >
                                {expandedListing === listing._id ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                            </span>
                        </div>
                        {expandedListing === listing._id && (
                            <ListingItem
                                listing={listing}
                                onCreateRatePlan={() => {
                                    setSelectedListingId(listing._id);
                                    setOpenCreateDialog(true);
                                }}
                                onUpdateRatePlan={(ratePlanId, updatedEvent) => handleUpdateRatePlan(listing._id, ratePlanId, updatedEvent)}
                            />
                        )}
                    </div>
                ))}
                <PaginationControls
                    page={pagination.page}
                    onPageChange={handlePaginationChange}
                />
                <CreateRatePlanDialog
                    open={openCreateDialog}
                    onClose={() => setOpenCreateDialog(false)}
                    ratePlan={newRatePlan}
                    onRatePlanChange={setNewRatePlan}
                    onCreateRatePlan={handleCreateRatePlan}
                />
                <ToastContainer
                    position="top-right"
                    autoClose={3000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                    theme="light"
                />
            </div>
        </DashboardLayout>
    );
};

export default ListingsWithRatePlans;