import React, { useState, useEffect, useCallback } from 'react';
import { Panel, PanelGroup, PanelResizeHandle } from 'react-resizable-panels';
import { Paper } from '@mui/material';
import ConversationList from './ConversationList';
import ChatComponent from './ChatComponent';
import ChatReservationDetails from './ChatReservationDetails';
import DashboardLayout from 'components/LayoutContainers/DashboardLayout';
import DashboardNavbar from 'components/Navbars/DashboardNavbar';
import io from 'socket.io-client';
import { SOCKET_URL, SOCKET_PATH } from './services/serverApi.chatConfig';

function ChatContainer() {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [activePanel, setActivePanel] = useState('conversations');
  const [activeConversation, setActiveConversation] = useState(null);
  const [socket, setSocket] = useState(null);
  const [conversationMessages, setConversationMessages] = useState({});

  useEffect(() => {
    const newSocket = io(SOCKET_URL, {
      path: SOCKET_PATH,
      transports: ['websocket'],
    });
    setSocket(newSocket);

    newSocket.on('connect', () => {
      console.log('WebSocket connected.');
      newSocket.emit('JOIN_ROOM', 'CHAT');
      console.log('Joined CHAT room');
    });

    newSocket.on('disconnect', () => {
      console.log('WebSocket disconnected.');
    });

    return () => {
      if (newSocket) {
        newSocket.emit('LEAVE_ROOM', 'CHAT');
        newSocket.close();
      }
    };
  }, []);

  const handleSelectConversation = useCallback((conversation) => {
    setActiveConversation(conversation);
    setConversationMessages(prev => ({
      ...prev,
      [conversation._id]: conversation.conversations || []
    }));
    console.log('Selected conversation:', conversation._id);
  }, []);

  const updateMessages = useCallback((conversationId, updatedMessage) => {
    setConversationMessages(prev => ({
      ...prev,
      [conversationId]: prev[conversationId].map(msg => 
        msg._id === updatedMessage._id ? { ...msg, ...updatedMessage } : msg
      )
    }));
  }, []);

  const addNewMessage = useCallback((conversationId, newMessage) => {
    setConversationMessages(prev => ({
      ...prev,
      [conversationId]: [...(prev[conversationId] || []), newMessage]
    }));
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const handlePanelChange = (panel) => {
    setActivePanel(panel);
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      {isMobile ? (
        <div style={{ height: 'calc(100vh - 64px)' }}>
          {activePanel === 'conversations' && (
            <Paper className="h-full">
              <ConversationList
                onSelectConversation={(conversation) => {
                  handleSelectConversation(conversation);
                  handlePanelChange('chat');
                }}
                socket={socket}
                activeConversation={activeConversation}
              />
            </Paper>
          )}
          {activePanel === 'chat' && (
            <Paper className="h-full relative">
              <div className="absolute top-3 right-0 z-10 p-2 flex gap-2">
                <button 
                  onClick={() => handlePanelChange('conversations')}
                  className="!p-2 !bg-gray-100 !hover:bg-gray-200 !rounded-md !text-sm !font-medium !text-gray-600 !transition-colors"
                >
                  ← Back
                </button>
                <button 
                  onClick={() => handlePanelChange('details')}
                  className="!p-2 !bg-medium-aquamarine !hover:bg-dark-aquamarine !rounded-md !text-sm !font-medium !text-white !transition-colors"
                >
                  Details →
                </button>
              </div>
              <ChatComponent
                socket={socket}
                conversationId={activeConversation?._id}
                messages={conversationMessages[activeConversation?._id] || []}
                updateMessages={updateMessages}
                addNewMessage={addNewMessage}
                activeConversation={activeConversation}
              />
            </Paper>
          )}
          {activePanel === 'details' && (
            <Paper className="h-full relative">
              <div className="absolute top-3 right-0 z-10 p-2">
                <button 
                  onClick={() => handlePanelChange('chat')}
                  className="!p-2 !bg-gray-100 !hover:bg-gray-200 !rounded-md !text-sm !font-medium !text-gray-600 !transition-colors"
                >
                  ← Back
                </button>
              </div>
              <ChatReservationDetails activeConversation={activeConversation} />
            </Paper>
          )}
        </div>
      ) : (
        <PanelGroup direction="horizontal" style={{ height: 'calc(100vh - 64px)' }}>
          <Panel defaultSize={25} minSize={20}>
            <Paper className="h-full">
              <ConversationList
                onSelectConversation={handleSelectConversation}
                socket={socket}
                activeConversation={activeConversation}
              />
            </Paper>
          </Panel>
          <PanelResizeHandle className="w-2 bg-gray-200 hover:bg-gray-300 transition-colors" />
          <Panel defaultSize={50} minSize={30}>
            <Paper className="h-full ">
              <ChatComponent
                socket={socket}
                conversationId={activeConversation?._id}
                messages={conversationMessages[activeConversation?._id] || []}
                updateMessages={updateMessages}
                addNewMessage={addNewMessage}
                activeConversation={activeConversation} 
              />
            </Paper>
          </Panel>
          <PanelResizeHandle className="w-2 bg-gray-200 hover:bg-gray-300 transition-colors" />
          <Panel defaultSize={25} minSize={20}>
            <Paper className="h-full">
              <ChatReservationDetails activeConversation={activeConversation} />
            </Paper>
          </Panel>
        </PanelGroup>
      )}
    </DashboardLayout>
  );
}

export default ChatContainer;