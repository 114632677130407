import React, { useState, useEffect } from 'react';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import { Modal, Box, Typography, Tooltip, TextField, Button, MenuItem, Select, InputLabel, FormControl, Checkbox, FormControlLabel, IconButton, Stepper, Step, StepLabel, Grid, CircularProgress, InputAdornment } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import ImageUpload from 'components/CustomUpload/UploadV2';
import { getReservationByNumber, createTask, updateTask, getTaskConfigs } from '../../services/serverApi.task';
import SearchIcon from '@mui/icons-material/Search';
import moment from 'moment';
import { X } from 'lucide-react';
import { toast } from 'react-toastify';
import PersonIcon from '@mui/icons-material/Person';
import DateRangeIcon from '@mui/icons-material/DateRange';
import { CalendarOff } from 'lucide-react';
import { CalendarDays } from 'lucide-react';



const validationSchema = Yup.object({
    type: Yup.string().required('Required'),
    subType: Yup.string().required('Required'),
    startDate: Yup.date().required('Required'),
    endDate: Yup.date().required('Required'),
    listingId: Yup.string(),
    price: Yup.number().min(0, 'Must be positive').required('Required'),
    reservationNumber: Yup.string(),
    duration: Yup.number().min(0, 'Must be positive'),
    emergency: Yup.string().required('Required'),
    presence: Yup.string().required('Required'),
    // descriptions: Yup.array().of(Yup.object().shape({
    //     description: Yup.string(),
    // })),
    // images: Yup.array().of(Yup.object().shape({
    //     imageUrl: Yup.string(),
    // })).required('Required'),
    // startHour: Yup.string().required('Required'),
    // endHour: Yup.string().required('Required'),
});

const AddTask = (props) => {
    const { open, onClose, staff = [], onSave, listing = null, existingTask = null, isAddTask = null, listingId = null, selectedReservation = null } = props;
    const [activeStep, setActiveStep] = useState(0);
    const [isSearching, setIsSearching] = useState(false);
    const [searchError, setSearchError] = useState('');
    const [showEndDate, setShowEndDate] = useState(false);
    const [endDateClicked, setEndDateClicked] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [Tasktypes, setTaskTypes] = useState([]);

    const staging = JSON.parse(localStorage.getItem('isStaging')) || false;

   const initialValues = {
        type: '',
        name: '',
        subType: '',
        mode: 'Auto',
        TS_SEL: [],
        TS_VAL: existingTask ? Boolean(existingTask.TS_VAL) : false,
        startDate: '',
        endDate: '',
        staffId: '',
        price: 0,
        paid: existingTask ? Boolean(existingTask.paid) : false,
        paymentMode: '',
        taskStatus: '',
        reservationNumber: selectedReservation ? selectedReservation.reservationNumber : '',
        guestName: selectedReservation ? selectedReservation.guestName : '',
        arrivalDate: selectedReservation ? selectedReservation.startDate : '',
        departureDate: selectedReservation ? selectedReservation.endDate : '',
        listingId: '',
        reservationId: selectedReservation ? selectedReservation.id : '',
        duration: 2,
        emergency: 'Normal',
        presence: 'N',
        ...(existingTask || {}),
        descriptions: (existingTask && existingTask.descriptions && existingTask.descriptions.length > 0)
            ? existingTask.descriptions
            : [],
        images: [],
        TS: [],
        startHour: existingTask?.TS_SEL?.[0]?.start?.toString().padStart(2, '0') || '',
        endHour: existingTask?.TS_SEL?.[0]?.end?.toString().padStart(2, '0') || '',
    };
    const steps = existingTask
        ? ['Basic Info', 'Note', 'Additional Details', 'Images']
        : ['Basic Info', 'Note', 'Additional Details', 'Images'];

    const handleClose = () => {
        setActiveStep(0);
        onClose();
    };


    const currentListing = Array.isArray(listing) && listing.length > 0 ? listing.find(l => l.id === listingId) : listing;


    const handleSave = async (values, { setSubmitting }) => {
        console.log("values", values);
    
        try {
            const taskData = {
                name: values.name,
                type: values.type,
                subType: values.subType,
                mode: values.mode,
                TS: values.TS.map(ts => ({ start: ts.start, end: ts.end })),
                TS_SEL: [{
                    start: parseInt(values.startHour),
                    end: parseInt(values.endHour)
                }],
                TS_VAL: values.TS_VAL,
                startDate: moment(values.startDate).format('YYYY-MM-DD'),
                endDate: moment(values.endDate).format('YYYY-MM-DD'),
                staffId: values.staffId,
                price: Number(values.price),
                paid: values.paid,
                paymentMode: values.paymentMode,
                reservationNumber: values.reservationNumber,
                listingId: values.listingId,
                reservationId: values.reservationId,
                duration: Number(values.duration),
                taskStatus: values.taskStatus,
                emergency: values.emergency,
                presence: values.presence,
                descriptions: values.descriptions.map(desc => ({ description: desc.description })),
                images: values.images.map(img => ({ imageUrl: img.imageUrl }))
            };
    
            let savedTask;
            if (existingTask) {
                taskData.status = values.status;
                taskData.assignmentStatus = values.assignmentStatus;
                const taskId = existingTask.id || existingTask._id;
                if (!taskId) {
                    throw new Error('Task ID is missing');
                }
                savedTask = await updateTask(taskId, taskData);
            } else {
                savedTask = await createTask(taskData);
            }
            onSave(savedTask);
            setActiveStep(0);
            handleClose();
            // toast.success('Task saved successfully');
        } catch (error) {
            console.error('Error:', error);
            if (error.response?.data?.errors) {
                const errorMessages = error.response.data.errors
                    .map(err => `${err.message}`)
                    .join(', ');
                toast.error(errorMessages);
            } else {
                toast.error(error.message || 'Failed to save task. Please try again.');
            }
        } finally {
            setSubmitting(false);
        }
    };

    const fetchTaskTypes = async () => {
        setIsLoading(true);
        try {
            const response = await getTaskConfigs();
            if (response && Array.isArray(response)) {
                setTaskTypes(response);
            } else {
                setTaskTypes([]);
            }
        } catch (error) {
            console.error('Error fetching task config:', error);
            setTaskTypes([]);
        } finally {
            setIsLoading(false);
        }
    };



    useEffect(() => {
        fetchTaskTypes();
    }, []);


    const handleReservationSearch = async (values, setFieldValue) => {
        setIsSearching(true);
        setSearchError('');
        try {
            const reservation = await getReservationByNumber(values.reservationNumber, staging);
            if (reservation && reservation.listing.TS_CLEAN) {
                setFieldValue('reservationId', reservation._id);
                setFieldValue('guestName', reservation.guestName);
                setFieldValue('departureDate', reservation.departureDate);
                setFieldValue('arrivalDate', reservation.arrivalDate);
                toast.success('Reservation found and details updated.');
            } else {
                setSearchError('No reservation found.');
                toast.warning('No reservation found.');
            }
        } catch (error) {
            setSearchError('An error occurred while searching for the reservation.');
            toast.error('Error searching for reservation. Please try again.');
        } finally {
            setIsSearching(false);
        }
    };

    const renderStepContent = (step, values, setFieldValue, errors, touched) => {
        switch (step) {
            case 0:
                return (
                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={4}>
                            <FormControl fullWidth variant="outlined" error={touched.type && errors.type}>
                                <InputLabel>Type</InputLabel>
                                <Field
                                    as={Select}
                                    name="type"
                                    label="Type"
                                    onChange={(e) => {
                                        setFieldValue('type', e.target.value);
                                    }}
                                >
                                    {Tasktypes.map(taskType => (
                                        <MenuItem key={taskType._id} value={taskType.task}>
                                            {taskType.task}
                                        </MenuItem>
                                    ))}
                                </Field>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <FormControl fullWidth variant="outlined" error={touched.subType && errors.subType}>
                                <InputLabel>Sub Type</InputLabel>
                                <Field
                                    as={Select}
                                    name="subType"
                                    label="Sub Type"
                                    onChange={(e) => {
                                        const selectedSubType = Tasktypes.flatMap(taskType => taskType.subs).find(subType => subType.type === e.target.value);
                                        if (selectedSubType) {
                                            setFieldValue('subType', selectedSubType.type);
                                            if (values.type === 'CLEAN') {
                                                setFieldValue('name', selectedSubType.name.Darija);
                                            } else {
                                                setFieldValue('name', selectedSubType.name.Francais);
                                            }
                                        }
                                    }}
                                >
                                    {Tasktypes.flatMap(taskType =>
                                        taskType.subs.map(subType => (
                                            <MenuItem key={subType._id} value={subType.type}>
                                                {subType.type}
                                            </MenuItem>
                                        ))
                                    )}
                                </Field>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <FormControl fullWidth variant="outlined" error={touched.mode && errors.mode}>
                                <InputLabel>Mode</InputLabel>
                                <Field as={Select} name="mode" label="Mode">
                                    <MenuItem value="Auto">Auto</MenuItem>
                                    <MenuItem value="Manu">Manual</MenuItem>
                                </Field>
                            </FormControl>
                        </Grid>

                        <Grid item xs={12} sm={4}>
                            <Box className="flex gap-1">
                                <Field
                                    as={TextField}
                                    fullWidth
                                    variant="outlined"
                                    name="startDate"
                                    label="Start Date"
                                    type="date"
                                    InputLabelProps={{ shrink: true }}
                                    error={touched.startDate && errors.startDate}
                                    helperText={touched.startDate && errors.startDate}
                                    value={values.startDate ? moment(values.startDate).format('YYYY-MM-DD') : ''}
                                    inputProps={{
                                        min: moment().format('YYYY-MM-DD')
                                    }}
                                    onChange={(e) => {
                                        const selectedDate = moment(e.target.value);
                                        const currentDate = moment().startOf('day');

                                        if (selectedDate.isSameOrAfter(currentDate)) {
                                            setFieldValue('startDate', e.target.value);
                                            if (!showEndDate) {
                                                setFieldValue('endDate', e.target.value);
                                            }
                                        } else {
                                            setFieldValue('startDate', currentDate.format('YYYY-MM-DD'));
                                            if (!showEndDate) {
                                                setFieldValue('endDate', currentDate.format('YYYY-MM-DD'));
                                            }
                                        }
                                    }}
                                />
                                <div className={`border border-gray-400 rounded-md ${endDateClicked ? 'bg-blue-200' : ''}`}>
                                    <Tooltip title="end date">
                                        <IconButton
                                            size="small"
                                            className='text-gray-600'
                                            aria-label="end date"
                                            onClick={() => {
                                                setShowEndDate(!showEndDate);
                                                setEndDateClicked(!endDateClicked);
                                                if (!showEndDate) {
                                                    setFieldValue('endDate', values.startDate);
                                                }
                                            }}
                                        >
                                            {!showEndDate ? (
                                                <CalendarOff size={20} />
                                            ) : (
                                                <CalendarDays size={20} />
                                            )}
                                        </IconButton>
                                    </Tooltip>
                                </div>
                            </Box>
                        </Grid>

                        <Grid item xs={12} sm={4}>
                            <FormControl fullWidth variant="outlined">
                                <InputLabel id="start-hour-label">Start Hour</InputLabel>
                                <Field
                                    as={Select}
                                    name="startHour"
                                    labelId="start-hour-label"
                                    label="Start Hour"
                                >
                                    {[...Array(24)].map((_, i) => (
                                        <MenuItem key={i} value={i.toString().padStart(2, '0')}>
                                            {i.toString().padStart(2, '0')}:00
                                        </MenuItem>
                                    ))}
                                </Field>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <FormControl fullWidth variant="outlined">
                                <InputLabel id="end-hour-label">End Hour</InputLabel>
                                <Field
                                    as={Select}
                                    name="endHour"
                                    labelId="end-hour-label"
                                    label="End Hour"
                                >
                                    {[...Array(24)].map((_, i) => (
                                        <MenuItem key={i} value={i.toString().padStart(2, '0')}>
                                            {i.toString().padStart(2, '0')}:00
                                        </MenuItem>
                                    ))}
                                </Field>
                            </FormControl>
                        </Grid>
                        {showEndDate ? (
                            <Grid item xs={12} >
                                <Field
                                    as={TextField}
                                    fullWidth
                                    variant="outlined"
                                    name="endDate"
                                    label="End Date"
                                    type="date"
                                    InputLabelProps={{ shrink: true }}
                                    inputProps={{
                                        min: moment().format('YYYY-MM-DD')
                                    }}
                                    error={touched.endDate && errors.endDate}
                                    helperText={touched.endDate && errors.endDate}
                                    value={values.endDate ? moment(values.endDate).format('YYYY-MM-DD') : ''}
                                />
                            </Grid>
                        ) : null}

                        <Grid item xs={12} sm={6}>
                            <Field
                                as={TextField}
                                fullWidth
                                variant="outlined"
                                name="price"
                                label="Price"
                                type="number"
                                error={touched.price && errors.price}
                                helperText={touched.price && errors.price}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <FormControl fullWidth variant="outlined" error={touched.paymentMode && errors.paymentMode}>
                                <InputLabel>Payment Mode</InputLabel>
                                <Field as={Select} name="paymentMode" label="Payment Mode">
                                    <MenuItem value="cash">Cash</MenuItem>
                                    <MenuItem value="card">Card</MenuItem>
                                </Field>
                            </FormControl>
                        </Grid>
                        {isAddTask && (
                            <Grid item xs={12} sm={4}>
                                <FormControl fullWidth variant="outlined" error={touched.listingId && errors.listingId}>
                                    <InputLabel>Listings</InputLabel>
                                    <Field
                                        as={Select}
                                        name="listingId"
                                        label="Listings"
                                        value={values.listingId}
                                        onChange={(event) => {
                                            const selectedListingId = event.target.value;
                                            setFieldValue('listingId', selectedListingId);
                                            const selectedListing = listing.find(item => item.id === selectedListingId);
                                            if (selectedListing && selectedListing.TS_CLEAN) {
                                                setFieldValue('TS', selectedListing.TS_CLEAN);
                                            } else {
                                                setFieldValue('TS', []);
                                            }
                                        }}
                                    >
                                        {listing?.map((listing) => (
                                            <MenuItem
                                                key={listing.id}
                                                value={listing.id}
                                                disabled={listingId && listingId !== listing.id}
                                            >
                                                {listing.name}
                                            </MenuItem>
                                        ))}
                                    </Field>
                                </FormControl>
                            </Grid>

                        )}

                        {existingTask && (
                            <>
                                <Grid item xs={12} sm={3}>
                                    <FormControl fullWidth error={touched.status && errors.status}>
                                        <InputLabel>Status</InputLabel>
                                        <Field as={Select} name="status">
                                            <MenuItem value="PENDING">PENDING</MenuItem>
                                            <MenuItem value="IN_PROGRESS">IN_PROGRESS</MenuItem>
                                            <MenuItem value="COMPLETED">COMPLETED</MenuItem>
                                            <MenuItem value="CANCELED">CANCELED</MenuItem>
                                            <MenuItem value="CANCELED-BY-CUST">CANCELED-BY-CUST</MenuItem>
                                            <MenuItem value="ASSIGNED">ASSIGNED</MenuItem>
                                            <MenuItem value="ACCEPTED">ACCEPTED</MenuItem>

                                        </Field>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={6} sm={3}>
                                    <FormControl fullWidth variant="outlined">
                                        <InputLabel>Staff</InputLabel>
                                        <Field as={Select} name="staffId" label="Staff">
                                            {staff.map((member) => (
                                                <MenuItem key={member.id} value={member._id}>{member.username}</MenuItem>
                                            ))}
                                        </Field>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} sm={3}>
                                    <FormControl fullWidth error={touched.assignmentStatus && errors.assignmentStatus}>
                                        <InputLabel>Assignment Status</InputLabel>
                                        <Field as={Select} name="assignmentStatus">
                                            <MenuItem value="ASSIGNED">ASSIGNED</MenuItem>
                                            <MenuItem value="UNASSIGNED">UNASSIGNED</MenuItem>
                                            <MenuItem value="ACCEPTED">ACCEPTED</MenuItem>
                                            <MenuItem value="REFUSED">REFUSED</MenuItem>
                                        </Field>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} sm={3}>
                                    <FormControl fullWidth variant="outlined" error={touched.taskStatus && errors.taskStatus}>
                                        <InputLabel>Task Status</InputLabel>
                                        <Field as={Select} name="taskStatus" label="Task Status">
                                            <MenuItem className='p-2' value="">None</MenuItem>
                                            <MenuItem value="START">START</MenuItem>
                                            <MenuItem value="END">END</MenuItem>
                                        </Field>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} sm={3}>
                                    <FormControlLabel
                                        control={
                                            <Field name="TS_VAL">
                                                {({ field }) => (
                                                    <Checkbox
                                                        {...field}
                                                        checked={field.value === true}
                                                        onChange={(e) => {
                                                            setFieldValue("TS_VAL", e.target.checked);
                                                        }}
                                                        color="primary"
                                                    />
                                                )}
                                            </Field>
                                        }
                                        label="Confirmed"
                                    />
                                </Grid>
                            </>
                        )}
                        <Grid item xs={12} sm={4}>
                            <FormControlLabel
                                control={
                                    <Field name="paid">
                                        {({ field }) => (
                                            <Checkbox
                                                {...field}
                                                checked={field.value === true}
                                                onChange={(e) => {
                                                    setFieldValue("paid", e.target.checked);
                                                }}
                                                color="primary"
                                            />
                                        )}
                                    </Field>
                                }
                                label="Paid"
                            />
                        </Grid>
                    </Grid>
                );
            case 1:
                return (
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <Typography variant="subtitle1">Descriptions</Typography>
                            {(values.descriptions || []).length === 0 ? (
                                <Button
                                    startIcon={<AddIcon />}
                                    onClick={() => setFieldValue('descriptions', [{ description: '' }])}
                                >
                                    Add Description
                                </Button>
                            ) : (
                                (values.descriptions || []).map((descObj, index) => (
                                    <Box key={index} display="flex" alignItems="center" mb={2}>
                                        <Field
                                            as={TextField}
                                            fullWidth
                                            name={`descriptions[${index}].description`}
                                            value={descObj.description || ''}
                                            onChange={(e) => {
                                                const newDescriptions = [...(values.descriptions || [])];
                                                newDescriptions[index] = { description: e.target.value };
                                                setFieldValue('descriptions', newDescriptions);
                                            }}
                                            style={{ marginRight: '10px' }}
                                        />
                                        <IconButton onClick={() => {
                                            const newDescriptions = [...(values.descriptions || []), { description: '' }];
                                            setFieldValue('descriptions', newDescriptions);
                                        }}>
                                            <AddIcon />
                                        </IconButton>
                                        {(values.descriptions || []).length > 1 && (
                                            <IconButton onClick={() => {
                                                const newDescriptions = (values.descriptions || []).filter((_, i) => i !== index);
                                                setFieldValue('descriptions', newDescriptions);
                                            }}>
                                                <DeleteIcon />
                                            </IconButton>
                                        )}
                                    </Box>
                                ))
                            )}
                        </Grid>
                    </Grid>
                );
            case 2:
                return (
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Box display="flex" alignItems="center">
                                <Field
                                    as={TextField}
                                    fullWidth
                                    name="reservationNumber"
                                    label="Reservation Number"
                                    sx={{ mr: 2 }}
                                />
                                <Button
                                    variant="contained"
                                    onClick={() => handleReservationSearch(values, setFieldValue)}
                                    disabled={isSearching}
                                    startIcon={isSearching ? <CircularProgress size={20} /> : <SearchIcon className='text-white' />}
                                >
                                    <span className='text-white'>Search</span>
                                </Button>
                            </Box>

                            {values.guestName && values.departureDate && values.arrivalDate && (
                                <Box
                                    sx={{
                                        mt: 2,
                                        p: 2,
                                        borderRadius: '8px',
                                        backgroundColor: '#f5f5f5',
                                        border: '1px solid #e0e0e0',
                                        display: 'flex',
                                        flexDirection: 'column',
                                        gap: 1
                                    }}
                                >
                                    <Typography variant="subtitle2" color="primary">
                                        Reservation Details
                                    </Typography>
                                    <Box display="flex" alignItems="center" gap={1}>
                                        <PersonIcon fontSize="small" color="action" />
                                        <Typography variant="body2">{values.guestName}</Typography>
                                    </Box>
                                    <Box display="flex" alignItems="center" gap={1}>
                                        <DateRangeIcon fontSize="small" color="action" />
                                        <Typography variant="body2">
                                            {moment(values.arrivalDate).format('MMM DD, YYYY')} - {moment(values.departureDate).format('MMM DD, YYYY')}
                                        </Typography>
                                    </Box>
                                </Box>
                            )}
                            {searchError && (
                                <Typography color="error" sx={{ mt: 1 }}>
                                    {searchError}
                                </Typography>
                            )}
                        </Grid>

                        <Grid item xs={12} md={4}>
                            <Field
                                as={TextField}
                                fullWidth
                                name="duration"
                                label="Duration (hours)"
                                type="number"
                            />
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <FormControl fullWidth>
                                <InputLabel>Emergency</InputLabel>
                                <Field as={Select} name="emergency">
                                    <MenuItem value="Normal">Normal</MenuItem>
                                    <MenuItem value="Urgent">Urgent</MenuItem>
                                    <MenuItem value="Critical">Critical</MenuItem>
                                </Field>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <FormControl fullWidth>
                                <InputLabel>Presence</InputLabel>
                                <Field as={Select} name="presence">
                                    <MenuItem value="P">P</MenuItem>
                                    <MenuItem value="N">N</MenuItem>
                                </Field>
                            </FormControl>
                        </Grid>

                    </Grid>
                );
            case 3:
                return (
                    <Box>
                        <Typography variant="subtitle1" gutterBottom>Images</Typography>
                        <Box mb={2}>
                            <ImageUpload
                                fieldName="image"
                                setFieldValue={(_, value) => {
                                    setFieldValue('images', [...values.images, { imageUrl: value }]);
                                }}
                                folder="other"
                            />
                        </Box>
                        <Box display="flex" flexWrap="wrap" gap={2}>
                            {(values.images || []).map((image, index) => (
                                <Box key={index} position="relative">
                                    <img
                                        src={image.imageUrl}
                                        alt={`Uploaded image ${index + 1}`}
                                        style={{ width: 100, height: 100, objectFit: 'cover' }}
                                    />
                                    <IconButton
                                        size="small"
                                        style={{
                                            position: 'absolute',
                                            top: 0,
                                            right: 0,
                                            backgroundColor: 'rgba(255, 255, 255, 0.7)',
                                        }}
                                        onClick={() => {
                                            const newImages = values.images.filter((_, i) => i !== index);
                                            setFieldValue('images', newImages);
                                        }}
                                    >
                                        <DeleteIcon fontSize="small" />
                                    </IconButton>
                                </Box>
                            ))}
                        </Box>
                    </Box>
                );
            default:
                return null;
        }
    };

    return (
        <Modal open={open} onClose={handleClose}>
            <Box sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: '90%',
                maxWidth: 800,
                bgcolor: 'background.paper',
                border: '1px solid gray',
                boxShadow: 24,
                p: 4,
                maxHeight: '90vh',
                overflow: 'auto',
            }} className="!rounded-md">
                <Box sx={{ p: 2, borderBottom: '1px solid rgba(0, 0, 0, 0.12)' }}>
                    <Box display="flex" justifyContent="space-between" alignItems="center">
                        <Typography variant="h6" component="h2">
                            <span className='text-gray-800'>{existingTask ? existingTask.name || '_____' : 'Create Task'} </span>
                        </Typography>
                        <IconButton onClick={onClose} size="small">
                            <X size={20} />
                        </IconButton>
                    </Box>
                </Box>

                <Formik
                    initialValues={existingTask ? { ...initialValues, ...existingTask } : { ...initialValues, listingId: listing?.id, TS: listing?.TS_CLEAN || [] }}
                    validationSchema={validationSchema}
                    onSubmit={handleSave}
                >
                    {({ values, setFieldValue, errors, touched, isSubmitting }) => (
                        <Form>
                            <Stepper activeStep={activeStep} alternativeLabel sx={{ mb: 4 }}>
                                {steps.map((label, index) => (
                                    <Step
                                        key={label}
                                        onClick={() => setActiveStep(index)}
                                        style={{ cursor: existingTask ? 'pointer' : 'default' }}
                                    >
                                        <StepLabel>{label}</StepLabel>
                                    </Step>
                                ))}
                            </Stepper>

                            {renderStepContent(activeStep, values, setFieldValue, errors, touched)}

                            <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 4 }}>
                                <Button
                                    onClick={() => setActiveStep((prevStep) => prevStep - 1)}
                                    disabled={activeStep === 0}
                                >
                                    Back
                                </Button>
                                <Box>
                                    <Button
                                        className='!bg-[#257eea] mx-1'
                                        onClick={() => {
                                            if (activeStep === steps.length - 1) {
                                            } else {
                                                setActiveStep((prevStep) => prevStep + 1);
                                            }
                                        }}
                                        type='button'
                                        disabled={isSubmitting || activeStep === 3}
                                    >
                                        <span className='text-white'>
                                            Next
                                        </span>
                                    </Button>
                                    <Button
                                        className='!bg-[#257eea]'
                                        type="submit"
                                        disabled={isSubmitting}
                                    >
                                        <span className='text-white'>Save</span>
                                    </Button>
                                </Box>
                            </Box>
                        </Form>
                    )}
                </Formik>
            </Box>
        </Modal>
    );
};

export default AddTask;