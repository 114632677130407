import React, { useEffect, useState } from 'react';
import {
  Grid,
  Button,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Menu,
  MenuItem,
  Box,
  CircularProgress
} from '@mui/material';
import { Link } from 'react-router-dom';
import { List, Edit, Task, CalendarMonth, Delete } from '@mui/icons-material';
import { FlagIcon } from 'react-flag-kit';
import { CheckSquare, LayoutGrid, Pencil, Home, Building2 } from 'lucide-react';
import {
  syncAvailabilityChannex
} from '../services/serverApi.listing';
import { toast } from 'react-toastify';

function ListingItem({
  listing,
  handleOpen,
  handleEditClick,
  handleTaskClick,
  handleCalendarClick,
  handleDeleteClick,
  anchorEl,
  handleMenuClose,
  handleTaskOption,
  handleMasterOption,
  deleteDialogOpen,
  handleDeleteClose,
  selectedListingName,
  deleteConfirmationText,
  setDeleteConfirmationText,
  handleDeleteConfirm,
  isDeleteButtonEnabled,
  setIsDeleteButtonEnabled,
  selectedListingId
}) {

  const [loading, setLoading] = useState(false);


  useEffect(() => {
    setIsDeleteButtonEnabled(deleteConfirmationText === selectedListingName);
  }, [deleteConfirmationText, selectedListingName, setIsDeleteButtonEnabled]);

  const handleConfirmationChange = (e) => {
    const newText = e.target.value;
    setDeleteConfirmationText(newText);
  };

  const handleChannexSync = async () => {
    setLoading(true);
    try {
      const response = await syncAvailabilityChannex({
        listingIds: [listing._id]
      });

      const message = response?.data?.message || 'Sync completed successfully';
      toast.success(message);
    } catch (error) {
      const errorMessage = error.response?.data?.message || 'Sync failed';
      toast.error(errorMessage);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Grid item xs={12} md={12} lg={12}>
      <Grid container style={{ cursor: 'default', background: 'white' }}>
        <Grid item lg={4} md={12} xs={12} style={{ padding: '15px' }}>
          <div className="photo-block">
            <div className="text-center">
              <img
                alt="Listing"
                className="img-responsive"
                src={listing.listingImages[0]?.url || ''}
                style={{
                  width: '100%',
                  height: '250px',
                  boxShadow: '5px 13px 6px #8f8f8f',
                }}
              />
            </div>
          </div>
        </Grid>

        <Grid item lg={8} md={12} xs={12} sx={{ 
          marginTop: { xs: 0, lg: 2 },
          padding: { xs: '0 15px', lg: 0 }
        }}>
          <Grid container>
            <Grid item xs={12} md={12} sx={{ marginBottom: { xs: 2, lg: 5 } }}>
              <Grid container gap={listing.propertyUnit ? 4 : 12}>
                <Grid item lg={10} md={12} xs={12}>
                  <div className='flex flex-col xs:gap-2 md:gap-1'>
                    <div className='flex flex-wrap items-center gap-2'>
                      <div
                        className="w-8 h-8 flex justify-center items-center bg-[#d4ffff] rounded-sm cursor-pointer"
                        onClick={() => handleOpen(listing)}
                      >
                        <Pencil style={{ color: 'rgb(29 141 141)' }} size={20} />
                      </div>
                      <Link
                        className='truncate'
                        style={{
                          color: '#00b4b4',
                          fontWeight: 'lighter',
                          fontSize: '30px',
                        }}
                        state={{ data: listing }}
                        to="/admin/Listing/Listing_Details"
                      >
                        {listing.name}{' '}
                        <span className="xs:block md:inline">({listing._id.slice(0, 6)}...)</span>
                      </Link>
                    </div>
                    <Typography className="text-muted">
                      {listing.city},{' '}
                      <span className="CountryLabel">
                        {listing.country}{' '}
                        <FlagIcon code={listing.countryCode} size={17} />
                      </span>
                    </Typography>
                  </div>
                </Grid>
                {listing.propertyUnit && (
                  <Box className="w-full flex flex-wrap gap-2">
                    <div className='xs:w-full md:w-[12%] p-1 border-1 border-[#bbf9f9] rounded-full text-[#4fc3c3] text-[16px] flex items-center gap-2 justify-center md:justify-start'>
                      {listing.propertyUnit === 'Single' ? <Home size={20} /> : <Building2 size={20} />}{listing.propertyUnit}
                    </div>
                    {listing.channexListingId && (
                      <Box className="xs:w-full md:w-auto">
                        <div
                          className='xs:w-full md:w-[120px] p-1 border-1 flex justify-center items-center border-[#bbf9f9] rounded-md bg-[#4fc3c3] hover:bg-[#45adad] text-white text-[16px] cursor-pointer shadow-sm'
                          onClick={handleChannexSync}
                        >
                          {loading ? (
                            <CircularProgress
                              size={20}
                              color="inherit"
                              style={{ color: 'white' }}
                            />
                          ) : (
                            <span>Channex</span>
                          )}
                        </div>
                      </Box>
                    )}
                  </Box>
                )}

                <Grid item xs={12} style={{ marginTop: 12 }}>
                  <div className="grid xs:grid-cols-2 md:grid-cols-6 gap-2">
                    <Button
                      variant="outlined"
                      style={{
                        background: '#00b4b4',
                        borderColor: '#00b4b4',
                        marginRight: '3px',
                        fontSize: 'medium',
                      }}
                      startIcon={<List />}
                      fullWidth
                    >
                      <Link
                        style={{
                          textDecoration: 'none',
                          color: 'white',
                          width: '90%',
                        }}
                        state={{ data: listing }}
                        to="/admin/Listing/Listing_Details"
                      >
                        Details
                      </Link>
                    </Button>

                    <Button
                      onClick={() => handleEditClick(listing._id)}
                      variant="outlined"
                      style={{
                        background: 'rgb(187 249 249)',
                        borderColor: '#00b4b4',
                        color: '#00b4b4',
                        border: 'none',
                        fontSize: '14px',
                      }}
                      startIcon={<Edit />}
                      fullWidth
                    >
                      Edit
                    </Button>

                    <Button
                      variant="outlined"
                      style={{
                        background: 'rgb(187 249 249)',
                        borderColor: '#00b4b4',
                        color: '#00b4b4',
                        border: 'none',
                        fontSize: '14px',
                      }}
                      startIcon={<Task />}
                      onClick={(event) => handleTaskClick(event, listing._id)}
                      fullWidth
                    >
                      Task
                    </Button>

                    <Button
                      variant="outlined"
                      style={{
                        background: 'rgb(187 249 249)',
                        borderColor: '#00b4b4',
                        color: '#00b4b4',
                        border: 'none',
                        fontSize: '14px',
                      }}
                      startIcon={<CalendarMonth />}
                      onClick={() => handleCalendarClick(listing._id)}
                      fullWidth
                    >
                      Calendar
                    </Button>

                    <Button
                      variant="outlined"
                      style={{
                        background: 'rgb(229 78 78)',
                        borderColor: '#00b4b4',
                        border: 'none',
                        fontSize: '14px',
                        color: 'white',
                      }}
                      startIcon={<Delete />}
                      onClick={() => handleDeleteClick(listing._id)}
                      fullWidth
                    >
                      Delete
                    </Button>
                  </div>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
        PaperProps={{
          elevation: 3,
          style: {
            borderRadius: '8px',
            minWidth: '150px',
          },
        }}
      >
        <MenuItem
          onClick={handleTaskOption}
          style={{
            color: '#00b4b4',
            fontSize: '14px',
            padding: '10px 20px',
            display: 'flex',
            alignItems: 'center',
            gap: '10px',
          }}
        >
          <CheckSquare size={18} />
          Tasks
        </MenuItem>
        <MenuItem
          onClick={handleMasterOption}
          style={{
            color: '#00b4b4',
            fontSize: '14px',
            padding: '10px 20px',
            display: 'flex',
            alignItems: 'center',
            gap: '10px',
          }}
        >


          <LayoutGrid size={18} />
          Master
        </MenuItem>
      </Menu>

      <Dialog
        open={deleteDialogOpen}
        onClose={handleDeleteClose}
        maxWidth="sm"
        fullWidth
        BackdropProps={{
          style: {
            backgroundColor: 'rgba(0, 0, 0, 0.1)',
          },
        }}
      >
        <DialogTitle className="!font-extrabold">
          Confirm Deletion
        </DialogTitle>
        <DialogContent>
          <Typography gutterBottom>
            Are you sure you want to delete this listing?
          </Typography>
          <Typography gutterBottom className="!font-extrabold">
            To confirm, please type the listing name: &ldquo;{selectedListingName}&rdquo;
          </Typography>
          <TextField
            autoFocus
            margin="dense"
            fullWidth
            value={deleteConfirmationText}
            onChange={handleConfirmationChange}
            error={deleteConfirmationText !== '' && !isDeleteButtonEnabled}
            helperText={
              deleteConfirmationText !== '' && !isDeleteButtonEnabled
                ? 'Text does not match listing name'
                : ''
            }
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDeleteClose}>
            Cancel
          </Button>
          <Button
            onClick={handleDeleteConfirm}
            className={`!text-white ${isDeleteButtonEnabled ? '!bg-red-500 hover:!bg-red-600' : '!bg-red-300'}`}
            disabled={!isDeleteButtonEnabled}
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </Grid>
  );
}

export default ListingItem;