import React, { useState, useEffect } from 'react';
import {
  Stepper,
  Step,
  StepLabel,
  Box,
} from '@mui/material';
import { toast } from 'react-toastify';
import { getMailTemplateById } from '../services/serverApi.adminConfig';
import MailTemplateForm from './MailTemplateForm';
import ConfigureForm from './ConfigureForm';
import FinalStep from './FinalStep';

const MailTemplateContainer = ({ templateId = null, onSubmit, onCancel }) => {
  const [activeStep, setActiveStep] = useState(0);
  const [isLoading, setIsLoading] = useState(templateId !== null);
  const [formData, setFormData] = useState({
    type: 'Email',
    content: '',
    contentEng: '',
    messageName: 'CONFIRMATION_RESERVATION_STANDARD',
    description: '',
    reservation: {
      immediately: { enabled: false, day: '', hours: '', minutes: '' },
      after: { enabled: false, day: '', hours: '', minutes: '' }
    },
    arrivalDate: {
      before: { enabled: false, day: '', hours: '', minutes: '' },
      after: { enabled: false, day: '', hours: '', minutes: '' }
    },
    departureDate: {
      before: { enabled: false, day: '', hours: '', minutes: '' },
      after: { enabled: false, day: '', hours: '', minutes: '' }
    }
  });

  const steps = ['Create', 'Configure', 'Save'];

  useEffect(() => {
    if (templateId) {
      fetchTemplateData();
    }
  }, [templateId]);

  const fetchTemplateData = async () => {
    try {
      setIsLoading(true);
      const response = await getMailTemplateById(templateId);
      if (response.data.success) {
        const template = response.data.data;
        const initialData = {
          type: template.type || 'Email',
          content: convertTemplateToDisplay(template.content || ''),
          contentEng: convertTemplateToDisplay(template.contentEng || ''),
          messageName: template.messageName || 'CONFIRMATION_RESERVATION_STANDARD',
          description: template.description || '',
          reservation: template.reservation || {
            immediately: { enabled: false, day: '', hours: '', minutes: '' },
            after: { enabled: false, day: '', hours: '', minutes: '' }
          },
          arrivalDate: template.arrivalDate || {
            before: { enabled: false, day: '', hours: '', minutes: '' },
            after: { enabled: false, day: '', hours: '', minutes: '' }
          },
          departureDate: template.departureDate || {
            before: { enabled: false, day: '', hours: '', minutes: '' },
            after: { enabled: false, day: '', hours: '', minutes: '' }
          }
        };
        setFormData(initialData);
      }
    } catch (error) {
      console.error('Error fetching template:', error);
      toast.error('Failed to fetch template data');
    } finally {
      setIsLoading(false);
    }
  };

  const convertTemplateToDisplay = (content) => {
    const availableFields = [
      { value: '${guestName}', display: '[GUEST_NAME]' },
      { value: '${guestFirstName}', display: '[GUEST_FIRST_NAME]' },
      { value: '${guestLastName}', display: '[GUEST_LAST_NAME]' },
      { value: '${guestEmail}', display: '[GUEST_EMAIL]' },
      { value: '${phone}', display: '[GUEST_PHONE]' },
      { value: '${guestAddress}', display: '[GUEST_ADDRESS]' },
      { value: '${guestCity}', display: '[GUEST_CITY]' },
      { value: '${guestCountry}', display: '[GUEST_COUNTRY]' },
      { value: '${nationality}', display: '[NATIONALITY]' },
      { value: '${reservationNumber}', display: '[RESERVATION_NUMBER]' },
      { value: '${numberOfGuests}', display: '[NUMBER_OF_GUESTS]' },
      { value: '${nights}', display: '[NIGHTS]' },
      { value: '${arrivalDate}', display: '[ARRIVAL_DATE]' },
      { value: '${departureDate}', display: '[DEPARTURE_DATE]' },
      { value: '${checkInTime}', display: '[CHECK_IN_TIME]' },
      { value: '${checkOutTime}', display: '[CHECK_OUT_TIME]' },
      { value: '${totalPrice}', display: '[TOTAL_PRICE]' },
      { value: '${currency}', display: '[CURRENCY]' },
      { value: '${paymentStatus}', display: '[PAYMENT_STATUS]' },
      { value: '${paymentMethod}', display: '[PAYMENT_METHOD]' },
      { value: '${doorCode}', display: '[DOOR_CODE]' },
      { value: '${listing.name}', display: '[LISTING_NAME]' },
    ];

    let displayContent = content || '';
    availableFields.forEach(field => {
      displayContent = displayContent.replace(
        new RegExp(escapeRegExp(field.value), 'g'),
        field.display
      );
    });
    return displayContent;
  };

  const escapeRegExp = (string) => {
    return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
  };

  const handleStepClick = (step) => {
    if (step < activeStep) {
      setActiveStep(step);
    }
  };

  const updateFormData = (data) => {
    setFormData(prevData => ({
      ...prevData,
      ...data
    }));
  };

  const handleFormSubmit = (data) => {
    updateFormData(data);
    setActiveStep(1);
  };

  const handleConfigureSubmit = (configData) => {
    updateFormData(configData);
    setActiveStep(2);
  };

  const handleBackToCreate = () => {
    setActiveStep(0);
  };

  const handleFinalSubmit = () => {
    onSubmit(formData);
  };

  const renderStepContent = (step) => {
    if (isLoading) {
      return (
        <div className="flex justify-center items-center h-64">
          <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-medium-aquamarine"></div>
        </div>
      );
    }

    switch (step) {
      case 0:
        return (
          <MailTemplateForm
            initialData={formData}
            onSubmit={handleFormSubmit}
            onCancel={onCancel}
            updateFormData={updateFormData}
          />
        );
      case 1:
        return (
          <ConfigureForm 
            initialData={formData}
            onSubmit={handleConfigureSubmit}
            onBack={() => setActiveStep(0)}
            data={formData}
            updateFormData={updateFormData}
          />
        );
      case 2:
        return (
          <FinalStep
            onSubmit={() => handleFinalSubmit()}
            onBack={() => setActiveStep(1)}
            data={formData}
          />
        );
      default:
        return null;
    }
  };

  return (
    <Box className="px-4">
      <Stepper activeStep={activeStep} className="!mb-8 !py-3">
        {steps.map((label, index) => (
          <Step
            key={label}
            onClick={() => handleStepClick(index)}
            className={`cursor-pointer ${index <= activeStep ? 'hover:opacity-80' : ''}`}
          >
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
      <div className="mt-6">
        {renderStepContent(activeStep)}
      </div>
    </Box>
  );
};

export default MailTemplateContainer;