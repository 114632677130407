import React, { useState, useEffect } from 'react';
import { getProjects, deleteProject, getProjectById } from '../services/serverApi.ProjectUnits';
import GlobalTable from 'components/GlobalTable/GlobalTable';
import ProjectModal from '../components/ProjectModal';
import { ToastContainer, toast } from 'react-toastify';
import EditOffIcon from '@mui/icons-material/EditOff';
import DeleteSweepIcon from '@mui/icons-material/DeleteSweep';
import { CircularProgress, Typography, Button, Paper, Box, Tooltip } from '@mui/material';
import DashboardLayout from 'components/LayoutContainers/DashboardLayout';
import DashboardNavbar from 'components/Navbars/DashboardNavbar';
import ImageGalleryTrigger from 'components/ImageGalleryModal/ImageGalleryModal';
import { useParams } from 'react-router-dom';
import DeleteConfirmationModal from '../components/DeleteConfirmationModal';

function Projects() {
    const { projectId } = useParams();
    const [isLoading, setIsLoading] = useState(true);
    const [projects, setProjects] = useState([]);
    const [modalOpen, setModalOpen] = useState(false);
    const [selectedProject, setSelectedProject] = useState(null);
    const [error, setError] = useState(null);
    const [page, setPage] = useState(0);
    const [limit, setLimit] = useState(10);
    const [total, setTotal] = useState(0);
    const [deleteModalOpen, setDeleteModalOpen] = useState(false);
    const [projectToDelete, setProjectToDelete] = useState(null);


    useEffect(() => {
        fetchProjects();
    }, [page, limit, projectId]);

    const fetchProjects = async () => {
        try {
            let response;
            if (projectId) {
                response = await getProjectById(projectId);
                setProjects([response.data.project]);
            } else {
                response = await getProjects({ page, limit });
                setProjects(response.data.projects);
            }
        } catch (error) {
            console.error('Error fetching projects:', error);
        } finally {
            setIsLoading(false);
        }
    };
    const handleOpenModal = (project = null) => {
        setSelectedProject(project);
        setModalOpen(true);
    };

    const handleCloseModal = () => {
        setSelectedProject(null);
        setModalOpen(false);
    };

    const handleDeleteProject = (project) => {
        setProjectToDelete(project);
        setDeleteModalOpen(true);
    };

    const handleConfirmDelete = async (deleteUnits) => {
        try {
            await deleteProject(projectToDelete.id, deleteUnits);
            setProjects(prevProjects => prevProjects.filter(project => project.id !== projectToDelete.id));
            toast.success('Project deleted successfully');
        } catch (error) {
            console.error('Error deleting project:', error);
            toast.error('Failed to delete project');
        } finally {
            setDeleteModalOpen(false);
            setProjectToDelete(null);
        }
    };


    const handlePageChange = (newPage) => {
        setPage(newPage);
    };

    const handleLimitChange = (newLimit) => {
        setLimit(newLimit);
        setPage(0);
    };

    const formatDate = (date) => {
        const options = { year: 'numeric', month: 'long', day: 'numeric' };
        return new Date(date).toLocaleDateString('en-US', options);
    };

    const columns = [
        {
            header: "Images",
            body: (rowData) => (
                <Box className="flex justify-center">
                    <ImageGalleryTrigger images={rowData.images} />
                </Box>
            )
        },
        {
            header: "Project Name",
            body: (rowData) => (
                <Tooltip title={rowData.projectName} placement="top">
                    <Typography className="!text-sm !font-medium !text-gray-800">
                        {rowData.projectName}
                    </Typography>
                </Tooltip>
            )
        },
        {
            header: "Project Type",
            body: (rowData) => (
                <span className="px-3 py-1 rounded-full text-sm bg-blue-100 text-blue-800">
                    {rowData.projectType}
                </span>
            )
        },
        {
            header: "Delivery Date",
            body: (rowData) => (
                <span className="px-3 py-1 rounded-full text-sm bg-purple-100 text-purple-800">
                    {formatDate(rowData.deliveryDate)}
                </span>
            )
        },
        {
            header: "Status",
            body: (rowData) => (
                <span className={`px-3 py-1 rounded-full text-sm ${rowData.status === 'Active'
                        ? 'bg-green-100 text-green-800'
                        : 'bg-yellow-100 text-yellow-800'
                    }`}>
                    {rowData.status}
                </span>
            )
        },
        {
            header: "Location",
            body: (rowData) => (
                <div className="space-y-1">
                    <div className="text-gray-700 font-medium">{rowData.city}</div>
                    <div className="text-sm text-gray-500">{rowData.district}</div>
                    <div className="text-xs text-gray-400 truncate max-w-[200px]">{rowData.address}</div>
                </div>
            )
        },
        {
            header: "Units",
            body: (rowData) => (
                <div className="flex flex-col gap-2">
                    <div className="flex bg-blue-100 rounded-md w-fit items-center">
                        <span className="text-sm font-medium text-gray-600 !pl-2 !pr-1">Available:</span>
                        <span className="!pr-2 py-1 rounded-md text-gray-800 text-sm">
                            {rowData.remainingUnits}
                        </span>
                    </div>
                    <div className="flex bg-green-100 rounded-md w-fit items-center">
                        <span className="text-sm font-medium text-gray-600 !pl-2 !pr-1">Sold:</span>
                        <span className="!pr-2 py-1 rounded-md text-gray-800 text-sm">
                            {rowData.soldUnits}
                        </span>
                    </div>
                    <div className="flex bg-gray-100 rounded-md w-fit items-center">
                        <span className="text-sm font-medium text-gray-600 !pl-2 !pr-1">Total:</span>
                        <span className="!pr-2 py-1 rounded-md text-gray-800 text-sm">
                            {rowData.totalUnits}
                        </span>
                    </div>
                </div>
            )
        },
        {
            header: "Starting Price",
            body: (rowData) => (
                <div className="text-lg font-semibold text-teal-600">
                    ${rowData.startingPrice.toLocaleString()}
                </div>
            )
        },
        {
            header: "Action",
            body: (rowData) => (
                <div className="flex gap-2">
                    <Tooltip title="Edit Project">
                        <button
                            className="p-2 rounded-full hover:text-teal-600 text-medium-aquamarine transition-colors"
                            onClick={() => handleOpenModal(rowData)}
                        >
                            <EditOffIcon className="w-5 h-5" />
                        </button>
                    </Tooltip>
                    <Tooltip title="Delete Project">
                        <button
                            className="p-2 rounded-full hover:text-red-600 text-red-600 transition-colors"
                            onClick={() => handleDeleteProject(rowData)}
                        >
                            <DeleteSweepIcon className="w-5 h-5" />
                        </button>
                    </Tooltip>
                </div>
            ),
        },
    ];

    if (error) {
        return <div className="w-full h-64 flex justify-center items-center text-red-500">{error}</div>;
    }

    return (
        <DashboardLayout>
            <DashboardNavbar />
            <ToastContainer />
            <Paper className="!p-6 !shadow-lg !rounded-xl">
                <div className="!flex !justify-between items-center !mb-6">
                    <Typography variant="h4" className="text-gray-800 font-semibold !text-lg">
                        Projects Management
                    </Typography>
                    <Button
                        variant="contained"
                        onClick={() => handleOpenModal()}
                        className="!bg-medium-aquamarine hover:!bg-medium-aquamarine-dark !text-white transition-colors !text-sm"
                        startIcon={<span className="!text-lg">+</span>}
                    >
                        Create New Project
                    </Button>
                </div>

                {isLoading ? (
                    <Box className="flex justify-center items-center h-64">
                        <CircularProgress className="text-teal-600" />
                    </Box>
                ) : (
                    <div className="overflow-hidden rounded-xl">
                        <GlobalTable
                            data={projects}
                            columns={columns}
                            hasPagination={true}
                            page={page}
                            onPageChange={handlePageChange}
                            limit={limit}
                            onLimitChange={handleLimitChange}
                            isNextDisabled={page >= Math.ceil(total / limit) - 1}
                            rowsPerPageOptions={[10, 20, 50]}
                        />
                    </div>
                )}

                <ProjectModal
                    open={modalOpen}
                    onClose={handleCloseModal}
                    setProjects={setProjects}
                    project={selectedProject}
                />
                <DeleteConfirmationModal
                    open={deleteModalOpen}
                    onClose={() => {
                        setDeleteModalOpen(false);
                        setProjectToDelete(null);
                    }}
                    onConfirm={handleConfirmDelete}
                    projectName={projectToDelete?.projectName}
                />
            </Paper>
        </DashboardLayout>
    );
}

export default Projects;