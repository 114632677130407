import React, { useState, useRef, useEffect } from 'react';
import { TextField, Button, Typography, List, ListItem, IconButton, InputAdornment } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import EditIcon from '@mui/icons-material/Edit';
import SendIcon from '@mui/icons-material/Send';
import ChatBubbleOutlineIcon from '@mui/icons-material/ChatBubbleOutline';
import { pushMessage, updateOpenAIMessage } from './services/serverApi.chatConfig';
import moment from 'moment';
import MessageComposeModal from './MessageComposeModal';
import MessageEditModal from './MessageEditModal';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

function ChatComponent({ socket, conversationId, messages, updateMessages, addNewMessage, activeConversation }) {
  const [newMessage, setNewMessage] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [displayedMessageCount, setDisplayedMessageCount] = useState(20);
  const [messageOffset, setMessageOffset] = useState(0);

  const messagesEndRef = useRef(null);
  const listRef = useRef(null);
  const chatContainerRef = useRef(null);
  const [showIcons, setShowIcons] = useState(false);
  const [flexDirection, setFlexDirection] = useState('row');


  const [isComposeModalOpen, setIsComposeModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [editingMessage, setEditingMessage] = useState(null);


  useEffect(() => {
    if (socket) {
      const handleNewMessage = (data) => {
        addNewMessage(data._id, data.msg);
        scrollToBottom();
      };

      socket.on('NEW_RECIVED_MSG', handleNewMessage);
      socket.on('NEW_SENDED_MSG', handleNewMessage);

      return () => {
        socket.off('NEW_RECIVED_MSG', handleNewMessage);
        socket.off('NEW_SENDED_MSG', handleNewMessage);
      };
    }
  }, [socket, addNewMessage]);

  const scrollToBottom = () => {
    setTimeout(() => {
      messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
    }, 0);
  };

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  useEffect(() => {
    const resizeObserver = new ResizeObserver(entries => {
      for (let entry of entries) {
        const width = entry.contentRect.width;
        setShowIcons(width < 700);
        setFlexDirection(width < 330 ? 'column' : 'row');
      }
    });

    if (chatContainerRef.current) {
      resizeObserver.observe(chatContainerRef.current);
    }

    return () => {
      resizeObserver.disconnect();
    };
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (newMessage.trim() && conversationId) {
      const gmtPlus1Date = moment().add(1, 'hours').toISOString();
      try {
        const lastDateReceived = activeConversation?.last_date_received || null;

        await pushMessage(conversationId, {
          origin: "Sent",
          source: "admin",
          type: "Text",
          content: newMessage,
          last_date_sent: gmtPlus1Date,
          last_date_received: lastDateReceived,
        });

        setNewMessage('');
      } catch (error) {
        console.error('Error sending message:', error);
      }
    }
  };

  const handleModalSubmit = async (modalMessage) => {
    if (modalMessage.trim() && conversationId) {
      const gmtPlus1Date = moment().add(1, 'hours').toISOString();
      try {
        const lastDateReceived = activeConversation?.last_date_received || null;

        await pushMessage(conversationId, {
          origin: "Sent",
          source: "admin",
          type: "Text",
          content: modalMessage,
          last_date_sent: gmtPlus1Date,
          last_date_received: lastDateReceived,
        });

        setIsModalOpen(false);
      } catch (error) {
        console.error('Error sending message from modal:', error);
      }
    }
  };

  const handleModalOpen = () => {
    setIsModalOpen(true);
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
  };

  const handleApprove = (messageId) => {
    console.log('Approving message:', messageId);
  };

  const handleDismiss = (messageId) => {
    console.log('Dismissing message:', messageId);
  };

  const handleEdit = (message) => {
    setEditingMessage(message);
    setIsEditModalOpen(true);
  };

  const handleEditSubmit = async (editedContent) => {
    if (editingMessage && editingMessage._id) {
      try {
        const response = await updateOpenAIMessage(editingMessage._id, editedContent);
        const updatedMessage = response.data.updatedMessage;
        updateMessages(conversationId, updatedMessage);
        setIsEditModalOpen(false);
        setEditingMessage(null);
      } catch (error) {
        console.error('Error updating message:', error);
      }
    }
  };

  const handleEditModalClose = () => {
    setIsEditModalOpen(false);
    setEditingMessage(null);
  };

  const renderMessageContent = (message) => {
    switch (message.type) {
      case 'Text':
        return <Typography variant="body2">{message.content}</Typography>;
      case 'Image':
        return <img src={message.url} alt={message.content} style={{ maxWidth: '100%', borderRadius: '8px' }} />;
      case 'GPS':
        return (
          <>
            <Typography variant="body2">{message.content}</Typography>
            <Typography variant="body2">
              Latitude: {message.location.latitude}, Longitude: {message.location.longitude}
            </Typography>
          </>
        );
      default:
        return <Typography variant="body2">Unknown message type</Typography>;
    }
  };
  useEffect(() => {
    setMessageOffset(0);
    setDisplayedMessageCount(20);
    scrollToBottom();
  }, [conversationId]);


  const sortedMessages = [...messages].sort((a, b) => new Date(b.date) - new Date(a.date));


  const displayedMessages = sortedMessages.slice(
    messageOffset,
    messageOffset + displayedMessageCount
  );

  const handleShowMore = () => {
    setMessageOffset((prev) => prev + displayedMessageCount);
  };

  // const handleShowMore = () => {
  //   setMessageOffset((prevOffset) => prevOffset + 20);
  // };

  const handleShowLess = () => {
    setMessageOffset(0);
    scrollToBottom();
  };



  return (
    <div ref={chatContainerRef} className="flex flex-col h-full max-h-full">
      <Typography variant="h6" className="p-4" style={{ borderBottom: '1px solid #ccc' }}>Chat</Typography>

      <List ref={listRef} className="flex-grow overflow-y-auto overflow-x-hidden p-4 space-y-2 flex flex-col-reverse scrollbar-thin scrollbar-thumb-medium-aquamarine scrollbar-track-gray-100">
        <div ref={messagesEndRef} />


        {messageOffset > 0 && (
          <div className="flex justify-center mt-2">
            <Button
              onClick={handleShowLess}
            >
              <KeyboardArrowDownIcon className="text-medium-aquamarine !h-14 !w-14" />
            </Button>
          </div>
        )}
        {displayedMessages.length > 0 ? (
          displayedMessages.map((message) => (
            <React.Fragment key={message._id}>
              <ListItem className={`flex flex-col ${message.origin === 'Received' ? '!items-start' : '!items-end'} mt-4`}>
                <div className={`w-1/2 max-w-md ${message.origin === 'Received' ? 'bg-gray-200 !text-left' : 'bg-blue-50'} rounded-lg p-2`}>
                  {renderMessageContent(message)}
                  <Typography variant="caption">
                    <span>{message.source}</span>
                  </Typography>
                </div>
                <Typography variant="caption" component="div" className="mt-1">
                  {new Date(message.date).toLocaleString()}
                </Typography>
                {/* {message.source === 'OpenAI' && ( */}
                <div className={`mt-2 flex gap-2 flex-${flexDirection}`}>
                  {showIcons ? (
                    <>
                      <IconButton onClick={() => handleApprove(message._id)} className="!bg-white border !border-[#1AC255] !text-black"><CheckIcon /></IconButton>
                      <IconButton onClick={() => handleDismiss(message._id)} className="!bg-white border !border-[#F2534C] !text-black"><CloseIcon /></IconButton>
                      <IconButton onClick={() => handleEdit(message)} className="!bg-white border !border-gray-500 !text-black"><EditIcon /></IconButton>
                    </>
                  ) : (
                    <>
                      <Button variant="contained" className="!bg-white border !border-[#1AC255] !text-black" onClick={() => handleApprove(message._id)}>Approve</Button>
                      <Button variant="contained" className="!bg-white border !border-[#F2534C] !text-black" onClick={() => handleDismiss(message._id)}>Dismiss</Button>
                      <Button variant="contained" className="!bg-white border !border-gray-500 !text-black" onClick={() => handleEdit(message)}>Edit</Button>
                    </>
                  )}
                </div>
                {/* )} */}
              </ListItem>
            </React.Fragment>
          ))
        ) : (
          <Typography>No messages to display</Typography>
        )}
        {messageOffset + displayedMessageCount < sortedMessages.length && (
          <div className="flex justify-center">
            <Button
              onClick={handleShowMore}
            >
              <KeyboardArrowUpIcon className="text-medium-aquamarine !h-14 !w-14" />
            </Button>
          </div>
        )}
      </List>
      <form onSubmit={handleSubmit} className="p-4 flex gap-2 border-t border-gray-300 bg-white">
        <TextField
          fullWidth
          variant="outlined"
          value={newMessage}
          onChange={(e) => setNewMessage(e.target.value)}
          placeholder={conversationId ? "Type a message..." : "Select a conversation to send a message"}
          className="mr-2"
          disabled={!conversationId}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={handleModalOpen} edge="end" disabled={!conversationId}>
                  <ChatBubbleOutlineIcon />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        <Button
          type="submit"
          variant="contained"
          className="!bg-blue-600 !text-white"
          disabled={!newMessage.trim() || !conversationId}
        >
          {showIcons ? <SendIcon /> : 'Send'}
        </Button>
      </form>

      <MessageComposeModal
        open={isModalOpen}
        onClose={handleModalClose}
        onSubmit={handleModalSubmit}
      />
      <MessageEditModal
        open={isEditModalOpen}
        onClose={handleEditModalClose}
        onSubmit={handleEditSubmit}
        message={editingMessage}
      />
    </div>
  );
}

export default ChatComponent;