import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  InputAdornment,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Button,
} from '@mui/material';
import { Search, FilterList } from '@mui/icons-material';
import { makeStyles } from '@mui/styles';
import { getcities, getcountries } from '../../../services/serverApi.calendar';
import { Eraser, CloudUpload, RotateCw } from 'lucide-react';
import FilterItems from './FilterItems';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: '#ffffff',
    borderRadius: '8px',
    boxShadow: '0 1px 3px rgba(0,0,0,0.12)',
    padding: theme.spacing(2),
    marginBottom: theme.spacing(1),
  },
  formControl: {
    minWidth: '150px',
    flex: 1,
    '& .MuiOutlinedInput-root': {
      height: '40px',
    },
    '& .MuiSelect-select': {
      padding: '8px 14px',
    },
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  filterContent: {
    display: 'grid',
    gridTemplateColumns: 'repeat(4, minmax(0, 1fr))',
    gap: theme.spacing(2),
    width: '60%',
    [theme.breakpoints.down('sm')]: {
      gridTemplateColumns: 'repeat(2, 1fr)',
      width: '100%',
    },
  },
  searchField: {
    '& .MuiOutlinedInput-root': {
      height: '40px',
    },
    '& .MuiOutlinedInput-input': {
      padding: '8px 14px',
    },
  },
}));

const FilterCalendar = ({
  onFilterChange,
  onSearchQueryChange,
  onSelectedItemsChange,
  listingsCount,
  selectedItems,
  setSelectedItems,
  onSaveChanges,
  hasPendingChanges,
  setPendingChanges,
}) => {
  const classes = useStyles();
  const [showFilter, setShowFilter] = useState(false);
  const [countries, setCountries] = useState([]);
  const [cities, setCities] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState('');
  const [selectedCity, setSelectedCity] = useState('');
  const [searchQuery, setSearchQuery] = useState('');
  const options = [
    'Available Room',
    'Rate',
    'Base Price',
    'Manual Price',
    'Calculated Price',
    'Use Dynamic Price',
    'Channex Available',
    'Reservations',
    'stopSell',
    'min_stay_arrival',
    'max_stay',
    'closed_to_arrival',
    'closed_to_departure',
  ];

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const [countriesData, citiesData] = await Promise.all([
        getcountries(),
        getcities(),
      ]);
      setCountries(countriesData.data);
      setCities(citiesData.data);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const handleFilterChange = () => {
    onFilterChange({
      country: selectedCountry,
      city: selectedCity,
    });
  };

  const handleResetChanges = () => {
    setPendingChanges([]);
  };

  const handleSelectedItemsChange = (newItems) => {
    setSelectedItems(newItems);
    onSelectedItemsChange(newItems);
  };

  const handleSearchChange = (e) => {
    const query = e.target.value;
    setSearchQuery(query);
    onSearchQueryChange(query);
  };

  const handleCountryChange = (e) => {
    const countryId = e.target.value;
    setSelectedCountry(countryId);
    setSelectedCity('');
    handleFilterChange();
  };

  const handleCityChange = (e) => {
    const cityId = e.target.value;
    setSelectedCity(cityId);
    handleFilterChange();
  };

  const handleClearFilter = () => {
    setSelectedCountry('');
    setSelectedCity('');
    setSearchQuery('');
    setSelectedItems(['Rate', 'Available Room']);
    onFilterChange({
      country: '',
      city: '',
    });
    onSearchQueryChange('');
  };

  useEffect(() => {
    handleFilterChange();
  }, [selectedCountry, selectedCity]);

  const handleSaveChanges = async () => {
    if (!hasPendingChanges) {
      toast.info('No changes to save');
      return;
    }
    await onSaveChanges();
  };

  return (
    <Box className={classes.root}>

      <Box className="flex flex-col md:flex-row justify-between gap-2 md:gap-4">
        <div className="flex flex-col md:flex-row gap-1 w-full md:!w-[40%]">
          <div className="flex items-center p-2 rounded-md shadow-sm bg-white text-gray-700 border-1 border-gray-200">
            <FilterList fontSize="small" />
            <span className="ml-2 text-sm font-medium">Filters</span>
          </div>
          <Box className="grid grid-cols-2 md:flex md:flex-row gap-2 w-full">
            <FormControl className={`w-full md:w-auto ${classes.formControl}`}>
              <InputLabel id="country-select-label">Country</InputLabel>
              <Select
                labelId="country-select-label"
                id="country-select"
                value={selectedCountry}
                label="Country"
                onChange={handleCountryChange}
              >
                <MenuItem value="">All countries</MenuItem>
                {countries.map((country) => (
                  <MenuItem key={country.id} value={country.name}>
                    {country.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl className={`w-full md:w-auto ${classes.formControl}`}>
              <InputLabel id="city-select-label">City</InputLabel>
              <Select
                labelId="city-select-label"
                id="city-select"
                value={selectedCity}
                label="City"
                onChange={handleCityChange}
              >
                <MenuItem value="">All cities</MenuItem>
                {cities.map((city) => (
                  <MenuItem key={city.id} value={city.name}>
                    {city.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FilterItems
              selectedItems={selectedItems}
              options={options}
              onItemsChange={handleSelectedItemsChange}
              className="w-full"
            />
            <div
              onClick={handleClearFilter}
              style={{ cursor: 'pointer' }}
              className="flex items-center gap-1 p-2 rounded-md shadow-sm bg-gray-800 text-white"
            >
              <Eraser size={14} />
              <span className="ml-2 text-sm">Clear</span>
            </div>
          </Box>
        </div>



        <Box className="flex flex-col md:flex-row items-center gap-2 w-full md:w-auto">

          <TextField
            placeholder="Search by listing"
            variant="outlined"
            size="small"
            value={searchQuery}
            onChange={handleSearchChange}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Search />
                </InputAdornment>
              ),
            }}
            fullWidth
            className={`md:!w-auto ${classes.searchField}`}
          />
        </Box>


      </Box>



      <div className="hidden md:flex items-center gap-4 mt-3 justify-between">

        {hasPendingChanges && (
          <div className="flex flex-col md:flex-row gap-2 w-full md:w-auto">
            <div
              onClick={handleSaveChanges}
              style={{
                cursor: hasPendingChanges ? 'pointer' : 'not-allowed',
                opacity: hasPendingChanges ? 1 : 0.6,
              }}
              className="flex items-center justify-center gap-1 p-2 rounded-md shadow-sm bg-green-500 text-white w-full md:w-auto"
            >
              <CloudUpload size={14} />
              <span className="ml-2 text-sm">Save Changes</span>
            </div>
            <div
              onClick={handleResetChanges}
              style={{ cursor: 'pointer' }}
              className="flex items-center justify-center gap-1 p-2 rounded-md shadow-sm bg-red-500 text-white w-full md:w-auto"
            >
              <RotateCw size={14} />
              <span className="ml-2 text-sm">Reset Changes</span>
            </div>
          </div>
        )}

        <div className="flex items-center gap-2">

          <div className="flex items-center gap-2">
            <div className="w-3 h-3 rounded bg-orange-100 border border-orange-200"></div>
            <span className="text-xs text-gray-600">Calculated Price</span>
          </div>
          <div className="flex items-center gap-2">
            <div className="w-3 h-3 rounded bg-blue-100 border border-blue-200"></div>
            <span className="text-xs text-gray-600">Manual Price</span>
          </div>
          <div className="flex items-center gap-2">
            <div className="w-3 h-3 rounded bg-gray-100 border border-gray-200"></div>
            <span className="text-xs text-gray-600">Base Price</span>
          </div>
        </div>
      </div>


      <div className="flex flex-wrap justify-center items-center gap-4 w-full py-2 mt-2 md:hidden">

        {hasPendingChanges && (
          <div className="flex flex-col md:flex-row gap-2 w-full md:w-auto">
            <div
              onClick={handleSaveChanges}
              style={{
                cursor: hasPendingChanges ? 'pointer' : 'not-allowed',
                opacity: hasPendingChanges ? 1 : 0.6,
              }}
              className="flex items-center justify-center gap-1 p-2 rounded-md shadow-sm bg-green-500 text-white w-full md:w-auto"
            >
              <CloudUpload size={14} />
              <span className="ml-2 text-sm">Save Changes</span>
            </div>
            <div
              onClick={handleResetChanges}
              style={{ cursor: 'pointer' }}
              className="flex items-center justify-center gap-1 p-2 rounded-md shadow-sm bg-red-500 text-white w-full md:w-auto"
            >
              <RotateCw size={14} />
              <span className="ml-2 text-sm">Reset Changes</span>
            </div>
          </div>
        )}

        <div className="flex items-center gap-2">

          <div className="flex items-center gap-2">
            <div className="w-3 h-3 rounded bg-orange-100 border border-orange-200"></div>
            <span className="text-xs text-gray-600">Calculated Price</span>
          </div>
          <div className="flex items-center gap-2">
            <div className="w-3 h-3 rounded bg-blue-100 border border-blue-200"></div>
            <span className="text-xs text-gray-600">Manual Price</span>
          </div>
          <div className="flex items-center gap-2">
            <div className="w-3 h-3 rounded bg-gray-100 border border-gray-200"></div>
            <span className="text-xs text-gray-600">Base Price</span>
          </div>
        </div>
      </div>

    </Box>
  );
};

export default FilterCalendar;
