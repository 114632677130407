import React from 'react';
import { Box, Typography, Paper, Rating } from '@mui/material';

const parseContent = (content) => {
  const parts = content.split('\r\n\r\n');
  const result = {
    headline: '',
    positive: '',
    negative: ''
  };

  parts.forEach(part => {
    if (part.startsWith('Headline:')) {
      result.headline = part.replace('Headline:', '').trim();
    } else if (part.startsWith('Positive:')) {
      result.positive = part.replace('Positive:', '').trim();
    } else if (part.startsWith('Negative:')) {
      result.negative = part.replace('Negative:', '').trim();
    }
  });

  return result;
};

const Reviews = ({ reviews = [] }) => {
  return (
    <Box className="mt-4">
      {!reviews || reviews.length === 0 ? (
        <Typography variant="body1" className="text-gray-500 text-center py-4">
          No reviews available
        </Typography>
      ) : (
        <div className="space-y-4">
          {reviews.map((review, index) => {
            const parsedContent = parseContent(review.content);
            
            return (
              <Paper key={review._id || index} className="p-4 shadow-sm">
                <div className="flex justify-between items-start mb-2">
                  <div>
                    <Typography variant="h6" className="font-semibold">
                      {review.ota_meta?.reviewer?.name || 'Anonymous'}
                    </Typography>
                    <Typography variant="caption" className="text-gray-500">
                      {new Date(review.createdAt).toLocaleDateString()}
                    </Typography>
                  </div>
                  <div className="text-right">
                    <Typography variant="caption" className="text-gray-500">
                      via {review.ota}
                    </Typography>
                  </div>
                </div>

                {/* Content section */}
                <div className="mt-3">
                  {parsedContent.headline && (
                    <Typography variant="subtitle1" className="font-semibold mb-2">
                      {parsedContent.headline}
                    </Typography>
                  )}

                  {parsedContent.positive && (
                    <div className="mb-2">
                      <Typography variant="subtitle2" className="font-semibold text-green-600">
                        Positive
                      </Typography>
                      <Typography variant="body2">
                        {parsedContent.positive}
                      </Typography>
                    </div>
                  )}

                  {parsedContent.negative && (
                    <div className="mb-2">
                      <Typography variant="subtitle2" className="font-semibold text-red-600">
                        Negative
                      </Typography>
                      <Typography variant="body2">
                        {parsedContent.negative}
                      </Typography>
                    </div>
                  )}
                </div>

                {/* Scores section */}
                {review.ota_meta?.scoring && (
                  <div className="mt-3 pt-3 border-t">
                    <Typography variant="subtitle2" className="font-semibold mb-2">
                      Scores
                    </Typography>
                    <div className="grid grid-cols-2 sm:grid-cols-3 gap-2">
                      {Object.entries(review.ota_meta.scoring).map(([category, score]) => (
                        category !== 'review_score' && (
                          <div key={category} className="flex gap-1 items-center">
                            <span className="capitalize text-sm text-gray-600">
                              {category}:
                            </span>
                            <span className="font-semibold text-sm">
                              {score}/10
                            </span>
                          </div>
                        )
                      ))}
                    </div>
                  </div>
                )}
              </Paper>
            );
          })}
        </div>
      )}
    </Box>
  );
};

export default Reviews; 