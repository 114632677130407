import React, { useState, useEffect, useRef } from 'react';
import {
  Typography,
  Box,
  List,
  ListItem,
  ListItemText,
  Avatar,
  Chip,
  TextField,
  CircularProgress,
} from '@mui/material';
import { MessageCircle } from 'lucide-react';
import MailIcon from '@mui/icons-material/Mail';
import { getChatHistory, getDocById } from './services/serverApi.chatConfig';

function ConversationList({
  onSelectConversation,
  socket,
  activeConversation,
}) {
  const [conversations, setConversations] = useState([]);
  const [unreadMessages, setUnreadMessages] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [searchQuery, setSearchQuery] = useState('');
  const [page, setPage] = useState(0);
  const [hasMore, setHasMore] = useState(true);
  const listRef = useRef(null);

  useEffect(() => {
    fetchChatHistory();
  }, [page]);

  useEffect(() => {
    if (socket) {
      socket.on('connect_error', (error) => {
        console.error('Socket connection error:', error);
      });

      socket.on('NEW_RECIVED_MSG', handleNewMessage);
      socket.on('NEW_SENDED_MSG', handleNewMessage);

      return () => {
        socket.off('connect_error');
        socket.off('NEW_RECIVED_MSG', handleNewMessage);
        socket.off('NEW_SENDED_MSG', handleNewMessage);
      };
    }
  }, [socket, activeConversation]);

  const handleNewMessage = async (data) => {
    setConversations((prevConversations) => {
      const findDoc = prevConversations.findIndex((x) => x._id === data._id);

      if (findDoc === -1) {
        getDocById(data._id)
          .then((newData) => {
            setConversations((prev) => sortConversations([...prev, newData]));
            if (!activeConversation || data._id !== activeConversation._id) {
              updateUnreadCount(data._id, data.msg);
            }
          })
          .catch((error) => {
            console.error('Error fetching document:', error);
          });
        return prevConversations;
      } else {
        const updatedConversations = prevConversations.map((conv, index) => {
          if (index === findDoc) {
            const updatedConv = {
              ...conv,
              conversations: [...conv.conversations, data.msg],
              last_date_sent: data.last_date_sent,
              last_date_received: data.last_date_received,
            };
            if (!activeConversation || data._id !== activeConversation._id) {
              updateUnreadCount(data._id, data.msg, updatedConv.conversations);
            }
            return updatedConv;
          }
          return conv;
        });

        return sortConversations(updatedConversations);
      }
    });
  };

  const updateUnreadCount = (conversationId, newMessage, allMessages = []) => {
    if (newMessage.source === 'Customer') {
      setUnreadMessages((prev) => ({
        ...prev,
        [conversationId]: (prev[conversationId] || 0) + 1,
      }));
    } else {
      const reversedMessages = [...allMessages, newMessage].reverse();
      const lastCustomerMessageIndex = reversedMessages.findIndex(
        (msg) => msg.source === 'Customer',
      );
      const lastNonCustomerMessageIndex = reversedMessages.findIndex(
        (msg) => msg.source !== 'Customer',
      );

      if (lastNonCustomerMessageIndex < lastCustomerMessageIndex) {
        setUnreadMessages((prev) => ({ ...prev, [conversationId]: 0 }));
      }
    }
  };

  const sortConversations = (convs) => {
    return convs.sort((a, b) => {
      const dateA = new Date(
        Math.max(new Date(a.last_date_received), new Date(a.last_date_sent)),
      );
      const dateB = new Date(
        Math.max(new Date(b.last_date_received), new Date(b.last_date_sent)),
      );
      return dateB - dateA;
    });
  };

  const fetchChatHistory = async () => {
    try {
      const response = await getChatHistory(page, 20);
      const newConversations = response.data.data;
      setConversations((prev) =>
        sortConversations([...prev, ...newConversations]),
      );
      if (newConversations.length === 0) {
        setHasMore(false);
      }
    } catch (error) {
      console.error('Error fetching chat history:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleSearchChange = (e) => setSearchQuery(e.target.value);

  const filteredConversations = conversations.filter((conversation) =>
    conversation.username.toLowerCase().includes(searchQuery.toLowerCase()),
  );

  const formatDate = (dateStr) => {
    const date = new Date(dateStr);
    if (isNaN(date.getTime())) {
      return 'Invalid date';
    }

    const options = {
      weekday: 'short',
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
      timeZone: 'Africa/Casablanca',
      hour12: false,
    };

    return new Intl.DateTimeFormat('en-US', options)
      .format(date)
      .replace(/,/g, '')
      .replace(/\s+/g, ' ')
      .trim();
  };

  const handleConversationClick = (conversation) => {
    onSelectConversation(conversation);
    setUnreadMessages((prev) => ({ ...prev, [conversation._id]: 0 }));
  };

  const handleScroll = () => {
    const bottom =
      listRef.current.scrollHeight - listRef.current.scrollTop ===
      listRef.current.clientHeight;
    if (bottom && hasMore && !isLoading) {
      setPage((prev) => prev + 1);
    }
  };

  const getLatestDate = (conversation) => {
    const receivedDate = conversation.last_date_received
      ? new Date(conversation.last_date_received)
      : new Date(0);
    const sentDate = conversation.last_date_sent
      ? new Date(conversation.last_date_sent)
      : new Date(0);
    return formatDate(
      receivedDate > sentDate
        ? conversation.last_date_received
        : conversation.last_date_sent,
    );
  };

  const formatMessageCount = (count) => {
    return count >= 1000 ? '+1000' : count;
  };

  return (
    <Box className="flex flex-col h-full">
      <Typography
        variant="h6"
        className="p-4"
        style={{ borderBottom: '1px solid #ccc' }}
      >
        Conversations
      </Typography>
      <TextField
        fullWidth
        variant="outlined"
        placeholder="Search conversations..."
        value={searchQuery}
        onChange={handleSearchChange}
        className="p-4"
      />
      {isLoading && page === 0 ? (
        <Box display="flex" justifyContent="center" alignItems="center" minHeight="200px">
          <CircularProgress />
        </Box>
      ) : filteredConversations.length === 0 ? (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          minHeight="200px"
        >
          <Typography>No conversations found</Typography>
        </Box>
      ) : (
        <List
          ref={listRef}
          onScroll={handleScroll}
          className="overflow-y-auto scrollbar-thin scrollbar-thumb-medium-aquamarine scrollbar-track-gray-100"
          style={{ maxHeight: 'calc(100vh - 240px)', overflowY: 'auto' }}
        >
          {filteredConversations.map((conversation) => (
            <ListItem
              key={conversation._id}
              button
              className="flex gap-2 px-4 py-2 hover:bg-gray-100"
              onClick={() => handleConversationClick(conversation)}
            >
              <Avatar
                src={
                  conversation.openai_language === 'fr'
                    ? 'path/to/french_avatar.png'
                    : 'path/to/other_avatar.png'
                }
                alt="Avatar"
                className="mr-2"
              />
              <ListItemText
                primary={
                  <div className="flex items-center gap-2 justify-between">
                    <div className="flex items-center gap-2">
                      <Typography variant="h6" color="textPrimary">
                        {conversation.username}
                      </Typography>
                    </div>
                    {unreadMessages[conversation._id] > 0 && (
                      <Chip
                        label={`${unreadMessages[conversation._id]}`}
                        color="primary"
                        size="small"
                        icon={<MailIcon fontSize="small" />}
                      />

                    )}
                  </div>
                }
                secondary={
                  <Box>
                    <div className="flex justify-between mt-2 items-center">
                      <Typography
                        color="textSecondary"
                        className="text-gray-300 !text-xs"
                      >
                        {getLatestDate(conversation)}
                      </Typography>
                      {/* <Typography
                        color="textSecondary"
                        className="bg-gray-100 px-1 py-1 rounded-sm !text-xs"
                      >
                        {formatMessageCount(conversation.conversations?.length)} messages
                        </Typography> */}

                      <Box className="flex items-center gap-1">
                        <MessageCircle className="w-4 h-4 text-gray-400" />
                        <Typography variant="caption">
                          {formatMessageCount(conversation.conversations?.length)}
                        </Typography>
                      </Box>
                    </div>
                  </Box>
                }
              />
            </ListItem>
          ))}
          {isLoading && hasMore && (
            <Box display="flex" justifyContent="center" alignItems="center" minHeight="200px">
              <CircularProgress />
            </Box>
          )}
        </List>
      )}
    </Box>
  );
}

export default ConversationList;
