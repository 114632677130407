import React, { useState, useEffect } from 'react';
import { TextField, Switch, Typography, MenuItem, Alert, Button, Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material';
import DashboardLayout from "components/LayoutContainers/DashboardLayout";
import DashboardNavbar from "components/Navbars/DashboardNavbar";
import { getRatePlan, getCities, updateRatePlan, updateUseDynamicPriceByCity } from '../services/serverApi.dynamicPricing';
import MonthWiseRule from '../components/MonthWishRule';
import WeekdayWeekendRule from '../components/WeekdayWeekendRule';
import SeasonRule from '../components/SeasonRule';
import { toast, ToastContainer } from 'react-toastify';
import PublishedWithChangesIcon from '@mui/icons-material/PublishedWithChanges';

const DynamicPricingComponent = () => {
  const [cities, setCities] = useState([]);
  const [selectedCity, setSelectedCity] = useState('');
  const [ratePlan, setRatePlan] = useState(null);
  const [error, setError] = useState(null);
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const [staging, setStaging] = useState(JSON.parse(localStorage.getItem('isStaging')) || false);
  const [openDialog, setOpenDialog] = useState(false);
  const [dialogAction, setDialogAction] = useState(null);

  useEffect(() => {
    fetchCities();
  }, []);

  useEffect(() => {
    const handleStagingToggle = (event) => {
      setStaging(event.detail);

    };

    window.addEventListener('stagingToggle', handleStagingToggle);

    if (selectedCity) {
      fetchRatePlan(selectedCity, staging);
    }

    return () => {
      window.removeEventListener('stagingToggle', handleStagingToggle);
    };
  }, [selectedCity, staging]);

  const fetchCities = async () => {
    try {
      const response = await getCities();
      setCities(response.data);
    } catch (error) {
      console.error('Error fetching cities:', error);
      setError('Failed to fetch cities. Please try again.');
    }
  };

  const fetchRatePlan = async (cityId, staging = false) => {
    setError(null);
    try {
      const response = await getRatePlan(cityId, staging);
      if (response.data.success && response.data.data.length > 0) {
        setRatePlan(response.data.data[0]);
        setHasUnsavedChanges(false);
      } else {
        throw new Error('No rate plan found');
      }
    } catch (error) {
      console.error('Error fetching rate plan:', error);
      setError('Failed to fetch rate plan. Please try again.');
      setRatePlan(null);
    }
  };

  const handleCityChange = (event) => {
    const cityId = event.target.value;
    setSelectedCity(cityId);
    // fetchRatePlan(cityId);
  };

  const handleRulePlanChange = (changes) => {
    setRatePlan(prevRatePlan => ({
      ...prevRatePlan,
      ...changes
    }));
    setHasUnsavedChanges(true);
  };

  const handleSaveChanges = async () => {
    try {
      if (!ratePlan || !ratePlan._id) {
        throw new Error('No rate plan ID available');
      }
      const response = await updateRatePlan(ratePlan._id, ratePlan);
      if (response.data.success) {
        setHasUnsavedChanges(false);
        toast.success('Rate plan updated successfully');
      } else {
        throw new Error(response.data.message || 'Failed to update rate plan');
      }
    } catch (error) {
      console.error('Error updating rate plan:', error);
      setError('Failed to save changes. Please try again.');
    }
  };

  const handleOpenDialog = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleConfirmDialog = async (action) => {
    try {
      const useDynamicPrice = action === 'apply';
      await updateUseDynamicPriceByCity(selectedCity, useDynamicPrice);
      toast.success(`Successfully ${action}ed dynamic pricing for all listings`);
      handleCloseDialog();
    } catch (error) {
      console.error('Error updating dynamic pricing:', error);
      toast.error('Failed to update dynamic pricing');
    }
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <ToastContainer />
      <div className="card p-4">
        <Typography variant="h4" gutterBottom>
          Dynamic Pricing
        </Typography>

        <TextField
          select
          label="Select City"
          value={selectedCity}
          onChange={handleCityChange}
          fullWidth
          margin="normal"
        >
          {cities.map((city) => (
            <MenuItem key={city._id} value={city._id}>
              {city.name}
            </MenuItem>
          ))}
        </TextField>

        {error && <Alert severity="error">{error}</Alert>}



        {ratePlan && (
          <>
            <div className="flex justify-between items-center mb-2">
              {selectedCity && (
                <Typography
                  className="!text-[#00B4B4] !hover:text-[#008080] cursor-pointer mt-2"
                  onClick={handleOpenDialog}
                >
                  Apply or remove dynamic pricing for all listings in the city
                </Typography>
              )}

              <Button
                variant="contained"
                onClick={handleSaveChanges}
                disabled={!hasUnsavedChanges}
                className="!bg-medium-aquamarine flex gap-1 !h-0 !hover:bg-dark-aquamarine !text-white !capitalize"
                onMouseEnter={() => setIsHovered(true)}
                onMouseLeave={() => setIsHovered(false)}
              >
                <PublishedWithChangesIcon className="" />
                <span className={`overflow-hidden  ${isHovered ? 'w-12 opacity-100' : 'w-0 opacity-0'}`}>
                  Save
                </span>
              </Button>
            </div>



            <MonthWiseRule
              ratePlan={ratePlan}
              onChange={handleRulePlanChange}
            />

            <WeekdayWeekendRule
              ratePlan={ratePlan}
              onChange={handleRulePlanChange}
            />

            <SeasonRule
              ratePlan={ratePlan}
              onChange={handleRulePlanChange}
            />

          </>
        )}
      </div>

      <Dialog open={openDialog} onClose={handleCloseDialog}>
        <DialogTitle>
          Dynamic Pricing for Listings in this City
        </DialogTitle>
        <DialogContent>
          <Typography>
            What would you like to do with dynamic pricing for all listings in this city?
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button className='!text-gray-500' onClick={handleCloseDialog}>Cancel</Button>
          <Button
            onClick={() => handleConfirmDialog('apply')}
            variant="contained"
            className="!bg-medium-aquamarine !text-white !hover:bg-dark-aquamarine"
          >
            Apply
          </Button>
          <Button
            onClick={() => handleConfirmDialog('remove')}
            variant="contained"
            className="!bg-red-500 !text-white !hover:bg-red-600"
          >
            Remove
          </Button>
        </DialogActions>
      </Dialog>
    </DashboardLayout>
  );
};

export default DynamicPricingComponent;