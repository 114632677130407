import React from 'react';
import { Card, CardContent, Typography, Box, Divider } from '@mui/material';

const FinalStep = ({ onSubmit, onBack, data }) => {
  const formatTime = (timeObj) => {
    if (!timeObj || !timeObj.enabled) return 'Not configured';
    const { day, hours, minutes } = timeObj;
    return `${day || 0} days, ${hours || 0} hours, ${minutes || 0} minutes`;
  };

  return (
    <Box className="p-2">
      <Card className="rounded-lg shadow-md">
        <CardContent className="space-y-4">
          <Box className="text-center">
            <Typography variant="h6" className="!text-base mb-1">Review and Save</Typography>
            <Typography className="!text-xs text-gray-500">
              Please review your template configuration before saving.
            </Typography>
          </Box>

          <Box className="space-y-4">
            <Box>
              <Typography variant="subtitle1" className="!text-sm mb-2">Template Metadata</Typography>
              <Typography className="!text-xs mb-1">
                <Box component="span" className="font-medium mr-2">Type:</Box> {data.type}
              </Typography>
              
              <Typography className="!text-xs">
                <Box component="span" className="font-medium mr-2">Message Name:</Box> {data.messageName}
              </Typography>
              <Divider className="mt-2" />
            </Box>

            <Box>
              <Typography variant="subtitle1" className="!text-sm mb-2">Content</Typography>
              <Typography className="!text-xs mb-1">
                <Box component="span" className="font-medium block mb-1">French Content:</Box>
                <pre className="bg-gray-100 p-2 rounded text-xs overflow-x-auto max-h-40 overflow-y-auto">
                  {data.content || 'No content'}
                </pre>
              </Typography>
              <Typography className="!text-xs mt-2">
                <Box component="span" className="font-medium block mb-1">English Content:</Box>
                <pre className="bg-gray-100 p-2 rounded text-xs overflow-x-auto max-h-40 overflow-y-auto">
                  {data.contentEng || 'No content'}
                </pre>
              </Typography>
              <Divider className="mt-2" />
            </Box>

            <Box>
              <Typography variant="subtitle1" className="!text-sm mb-2">Reservation Timing</Typography>
              <Typography className="!text-xs mb-1">
                <Box component="span" className="font-medium mr-2">Immediately: </Box> 
                {formatTime(data.reservation?.immediately)}
              </Typography>
              <Typography className="!text-xs">
                <Box component="span" className="font-medium mr-2">After Reservation: </Box> 
                {formatTime(data.reservation?.after)}
              </Typography>
              <Divider className="mt-2" />
            </Box>

            <Box>
              <Typography variant="subtitle1" className="!text-sm mb-2">Arrival Date Settings</Typography>
              <Typography className="!text-xs mb-1">
                <Box component="span" className="font-medium mr-2">Before Arrival: </Box> 
                {formatTime(data.arrivalDate?.before)}
              </Typography>
              <Typography className="!text-xs">
                <Box component="span" className="font-medium mr-2">After Arrival: </Box> 
                {formatTime(data.arrivalDate?.after)}
              </Typography>
              <Divider className="mt-2" />
            </Box>

            <Box>
              <Typography variant="subtitle1" className="!text-sm mb-2">Departure Date Settings</Typography>
              <Typography className="!text-xs mb-1">
                <Box component="span" className="font-medium mr-2">Before Departure: </Box> 
                {formatTime(data.departureDate?.before)}
              </Typography>
              <Typography className="!text-xs">
                <Box component="span" className="font-medium mr-2">After Departure: </Box> 
                {formatTime(data.departureDate?.after)}
              </Typography>
            </Box>
          </Box>

          <Box className="flex justify-end space-x-2">
            <button 
              onClick={onBack} 
              className="!px-4 !py-2 !text-xs !border !rounded !hover:bg-gray-100 !transition-colors"
            >
              Back
            </button>
            <button
              onClick={onSubmit}
              className="!px-4 !py-2 !text-xs !bg-medium-aquamarine !text-white !rounded !hover:bg-opacity-90 !transition-colors"
            >
              Save Template
            </button>
          </Box>
        </CardContent>
      </Card>
    </Box>
  );
};

export default FinalStep;
