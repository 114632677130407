import React, { useState, useEffect } from 'react';
import { TextField, Switch, Typography, IconButton } from '@mui/material';
import { InfoOutlined } from '@mui/icons-material';

const months = ['jan', 'feb', 'mar', 'apr', 'may', 'jun', 'jul', 'aug', 'sep', 'oct', 'nov', 'dec'];

const MonthWiseRule = ({ ratePlan, onChange }) => {
  const [adjustments, setAdjustments] = useState(ratePlan['adjustments-year'] || {});
  const [isActive, setIsActive] = useState(ratePlan['adjustments-year-active'] || false);

  useEffect(() => {
    setAdjustments(ratePlan['adjustments-year'] || {});
    setIsActive(ratePlan['adjustments-year-active'] || false);
  }, [ratePlan]);

  const getMonthlyAdjustment = (month) => {
    return adjustments[month] / 100 || 1;
  };

  const handleSwitchChange = (e) => {
    const newIsActive = e.target.checked;
    setIsActive(newIsActive);
    onChange({
      'adjustments-year-active': newIsActive
    });
  };

  const handleAdjustmentChange = (month, value) => {
    const newAdjustments = { ...adjustments, [month]: Math.round(value * 100) };
    setAdjustments(newAdjustments);
    onChange({
      'adjustments-year': newAdjustments
    });
  };

  return (
    <div className="flex flex-col mb-4">
      <div className="flex items-center mb-2">
        <div className="flex items-center">
          <Typography className="!text-base mr-2">MONTH WISE RULE</Typography>
          <IconButton size="small"><InfoOutlined/></IconButton>
        </div>
        <Switch 
          checked={isActive} 
          onChange={handleSwitchChange} 
          sx={{ 
            '& .MuiSwitch-switchBase.Mui-checked': { color: '#00b4b4' }, 
            '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': { backgroundColor: '#00b4b4' } 
          }} 
        />
      </div>
      
      <div className="flex flex-col">
        <div className="grid grid-cols-6 md:flex md:flex-wrap gap-1 md:gap-2">
          {months.map((month, index) => (
            <div key={month} className="flex flex-col items-center gap-1">
              <Typography className="!text-center !font-bold !text-sm">
                {month.charAt(0).toUpperCase() + month.slice(1)}
              </Typography>
              <TextField
                variant="outlined"
                size="small" 
                value={getMonthlyAdjustment(month)}
                onChange={(e) => handleAdjustmentChange(month, parseFloat(e.target.value))}
                className="w-[100%] md:w-16"
                type="number"
                inputProps={{ min: 0, step: 0.01, style: { textAlign: 'center' } }}
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default MonthWiseRule;