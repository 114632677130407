import React from 'react';
import { Grid, Button, Box } from '@mui/material';
import { Link } from 'react-router-dom';
import AddCircleIcon from '@mui/icons-material/AddCircle';

const styles = {
  buttonGrid: {
    padding: 2,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  button: {
    padding: '0px 15px',
    textTransform: 'none',
    color: '#ffff',
    width: '100%',
    background: 'rgb(0, 180, 180)',
    '&:hover': { backgroundColor: 'rgb(42 201 201)' },
  },
};

function HeaderListing() {
  return (
    <Grid 
      container 
      sx={{ 
        marginBottom: 2,
        flexDirection: 'row'
      }}
    >
      <Grid
        item
        xs={6}
        sm={4}
        sx={{
          padding: 2,
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <Box className="flex items-center gap-2">
          <Box>
            <p className="font-bold text-xl">Listings</p>
          </Box>
        </Box>
      </Grid>

      <Grid item xs={6} sm={8} sx={{ padding: 2 }}>
        <Grid
          container
          sx={{
            marginBottom: { xs: 1, sm: 2 },
            display: 'flex',
            justifyContent: 'flex-end'
          }}
        >
          <Grid 
            item 
            xs={12} 
            lg={'auto'} 
            md={6} 
            sm={12} 
            sx={styles.buttonGrid}
          >
            <Link to="new" style={{ textDecoration: 'none', width: '100%' }}>
              <Button variant="contained" sx={styles.button}>
                Create New <AddCircleIcon fontSize="small" sx={{ ml: 1 }} />
              </Button>
            </Link>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default HeaderListing;