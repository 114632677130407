import React from 'react';
import GuestDetails from './guestInfoComponent/GuestDetails';
import ListingDetails from './guestInfoComponent/ListingDetails';
import ReservationDetails from './guestInfoComponent/ReservationDetails';

const GuestInfo = ({ formik, listingOptions, handleListingChange }) => {
    return (
        <div className="transition-all duration-100 ease-in-out my-4 md:my-8 px-4 md:px-0">
            <GuestDetails formik={formik} />
            <ListingDetails formik={formik} listingOptions={listingOptions} handleListingChange={handleListingChange} />
            <ReservationDetails formik={formik} listingOptions={listingOptions} />
        </div>
    );
};

export default GuestInfo;
