import React from 'react';
import ReservationTable from './ReservationTable';
import { Button } from '@mui/material';

const Exprenses = () => {
    return (
        <div>
            <div className="flex flex-col sm:flex-row sm:justify-between sm:items-center mb-4 gap-2">
                <h5 className="text-2xl font-bold">Financial Reporting</h5>
                <div className="flex gap-2 flex-wrap">
                    <Button className="px-2 !rounded-sm flex items-center gap-1 text-xs sm:text-sm">
                        <i className="pi pi-plus-circle text-[#00b4b4] text-xs sm:text-sm"></i>
                        <span className="ladda-label flex items-center text-[11px] sm:text-[13px] text-[#00b4b4] p-[3px] capitalize"> Add Expense</span>
                    </Button>
                    <Button className="px-2 !bg-[#dcf6f6] !rounded-sm flex items-center gap-1 text-xs sm:text-sm">
                        <span className="ladda-label flex items-center text-[11px] sm:text-[13px] text-[#00b4b4] p-[3px] capitalize">Download Report</span>
                        <i className="pi pi-angle-down text-[#00b4b4] text-xs sm:text-sm"></i>
                    </Button>
                    <Button className="px-2 !bg-[#dcf6f6] !rounded-sm flex items-center gap-1 text-xs sm:text-sm">
                        <i className="pi pi-cog text-[#00b4b4] text-xs sm:text-sm"></i>
                        <span className="ladda-label flex items-center text-[11px] sm:text-[13px] text-[#00b4b4] p-[3px] capitalize">Settings</span>
                    </Button>
                </div>
            </div>
            <ReservationTable />
        </div>
    );
};

export default Exprenses;