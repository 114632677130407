import React, { useState } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, TextField, Box, Typography, Tooltip } from '@mui/material';
import { Formik, Form, Field, FieldArray } from 'formik';
import * as Yup from 'yup';
import { createTaskConfig, updateTaskConfig } from '../../services/serverApi.task';
import { toast } from 'react-toastify';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import AddIcon from '@mui/icons-material/Add';

const validationSchema = Yup.object().shape({
    task: Yup.string().required('Task is required'),
    name: Yup.object().shape({
        Francais: Yup.string().required('French name is required'),
        Darija: Yup.string().required('Darija name is required')
    }),
    subs: Yup.array().of(
        Yup.object().shape({
            type: Yup.string().required('Type is required'),
            name: Yup.object().shape({
                Francais: Yup.string().required('French name is required'),
                Darija: Yup.string().required('Darija name is required')
            })
        })
    ).min(1, 'At least one subtask is required'),
});

const updateValidationSchema = Yup.object().shape({
    task: Yup.string().required('Task is required'),
    name: Yup.string().required('Name is required'),
    subs: Yup.array().of(
        Yup.object().shape({
            type: Yup.string().required('Type is required'),
            name: Yup.string().required('Name is required'),
        })
    ),
});

const TaskConfigModal = ({ open, onClose, setTaskConfigs, taskConfig }) => {
    const [errorMessage, setErrorMessage] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    const handleSubmit = async (values, { setSubmitting }) => {
        setIsLoading(true);
        setErrorMessage('');
        try {
            let response;
            if (taskConfig) {
                const updateData = {
                    task: taskConfig.task,
                    name: values.name,
                    subs: values.subs
                };
                response = await updateTaskConfig(taskConfig._id, updateData);
            } else {
                response = await createTaskConfig(values);
            }

            if (response) {
                setTaskConfigs(prevConfigs => {
                    if (!prevConfigs) return [response.data];
                    if (taskConfig) {
                        return prevConfigs.map(c => c._id === taskConfig._id ? response.data : c);
                    } else {
                        return [...prevConfigs, response.data];
                    }
                });
                setSubmitting(false);
                onClose();
                toast.success(taskConfig ? 'Task configuration updated successfully' : 'Task configuration created successfully');
            } else {
                throw new Error('Unexpected response structure');
            }
        } catch (error) {
            console.error('Error:', error);
            setErrorMessage(error.message || 'An error occurred while processing your request.');
            setSubmitting(false);
            toast.error(taskConfig ? 'Error updating task configuration' : 'Error creating task configuration');
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
            <DialogTitle>{taskConfig ? 'Update Task Configuration' : 'Create New Task Configuration'}</DialogTitle>
            <DialogContent>
                {errorMessage && <Typography color="error">{errorMessage}</Typography>}
                <Formik
                    initialValues={{
                        task: taskConfig ? taskConfig.task : '',
                        name: taskConfig ? taskConfig.name : { Francais: '', Darija: '' },
                        subs: taskConfig ? taskConfig.subs : [{
                            type: '',
                            name: { Francais: '', Darija: '' }
                        }],
                    }}
                    validationSchema={validationSchema}
                    onSubmit={handleSubmit}
                >
                    {({ values, errors, touched, setFieldValue }) => (
                        <Form className="mt-2">
                            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                                {taskConfig ? (
                                    <Typography>{taskConfig.task}</Typography>
                                ) : (
                                    <Field name="task">
                                        {({ field }) => (
                                            <TextField
                                                {...field}
                                                label="Task"
                                                fullWidth
                                                error={touched.task && Boolean(errors.task)}
                                                helperText={touched.task && errors.task}
                                            />
                                        )}
                                    </Field>
                                )}
                                <Box sx={{ display: 'flex', gap: 2 }}>
                                    <TextField
                                        fullWidth
                                        label="Name (French)"
                                        value={values.name.Francais}
                                        onChange={(e) => setFieldValue('name.Francais', e.target.value)}
                                        error={touched.name?.Francais && Boolean(errors.name?.Francais)}
                                        helperText={touched.name?.Francais && errors.name?.Francais}
                                    />
                                    <TextField
                                        fullWidth
                                        label="Name (Darija)"
                                        value={values.name.Darija}
                                        onChange={(e) => setFieldValue('name.Darija', e.target.value)}
                                        error={touched.name?.Darija && Boolean(errors.name?.Darija)}
                                        helperText={touched.name?.Darija && errors.name?.Darija}
                                    />
                                </Box>
                                <FieldArray name="subs">
                                    {({ push, remove }) => (
                                        <>
                                            {values.subs.map((sub, index) => (
                                                <Box key={index} sx={{ display: 'flex', flexDirection: 'column', gap: 2, mb: 2 }}>
                                                    <TextField
                                                        fullWidth
                                                        label="Subtask Type"
                                                        value={sub.type}
                                                        onChange={(e) => setFieldValue(`subs.${index}.type`, e.target.value)}
                                                        error={touched.subs?.[index]?.type && Boolean(errors.subs?.[index]?.type)}
                                                        helperText={touched.subs?.[index]?.type && errors.subs?.[index]?.type}
                                                        disabled={taskConfig && index < taskConfig.subs.length}
                                                    />
                                                    <Box sx={{ display: 'flex', gap: 2 }}>
                                                        <TextField
                                                            fullWidth
                                                            label="Subtask Name (French)"
                                                            value={sub.name.Francais}
                                                            onChange={(e) => setFieldValue(`subs.${index}.name.Francais`, e.target.value)}
                                                            error={touched.subs?.[index]?.name?.Francais && Boolean(errors.subs?.[index]?.name?.Francais)}
                                                            helperText={touched.subs?.[index]?.name?.Francais && errors.subs?.[index]?.name?.Francais}
                                                        />
                                                        <TextField
                                                            fullWidth
                                                            label="Subtask Name (Darija)"
                                                            value={sub.name.Darija}
                                                            onChange={(e) => setFieldValue(`subs.${index}.name.Darija`, e.target.value)}
                                                            error={touched.subs?.[index]?.name?.Darija && Boolean(errors.subs?.[index]?.name?.Darija)}
                                                            helperText={touched.subs?.[index]?.name?.Darija && errors.subs?.[index]?.name?.Darija}
                                                        />
                                                        <Button onClick={() => remove(index)} disabled={taskConfig && index < taskConfig.subs.length}>
                                                            <DeleteOutlineIcon className="!text-gray-500 !w-6 !h-6" />
                                                        </Button>
                                                    </Box>
                                                </Box>
                                            ))}
                                            <Button 
                                                onClick={() => push({ 
                                                    type: '', 
                                                    name: { Francais: '', Darija: '' } 
                                                })} 
                                                startIcon={<AddIcon />}
                                            >
                                                Add Subtask
                                            </Button>
                                        </>
                                    )}
                                </FieldArray>
                            </Box>
                            <DialogActions>
                                <Button onClick={onClose} className="!bg-red-500 !text-white">
                                    Cancel
                                </Button>
                                <Button type="submit" className="!bg-medium-aquamarine !text-white" disabled={isLoading}>
                                    {taskConfig ? 'Update' : 'Create'}
                                </Button>
                            </DialogActions>
                        </Form>
                    )}
                </Formik>
            </DialogContent>
        </Dialog>
    );
};

export default TaskConfigModal;