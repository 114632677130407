import React, { useState, useEffect } from 'react';
import { Switch, FormControlLabel, Typography, IconButton } from '@mui/material';
import { InfoOutlined } from '@mui/icons-material';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import EventItem from './EventComponents/EventItem';
import ConfirmDialog from './EventComponents/ConfirmDialog';
import NotificationSnackbar from './EventComponents/NotificationSnackbar';

const SeasonRule = ({ ratePlan, onChange }) => {
    const [eventAdjustments, setEventAdjustments] = useState({ events: [] });
    const [isActive, setIsActive] = useState(false);
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [openDialog, setOpenDialog] = useState(false);
    const [eventToRemove, setEventToRemove] = useState(null);

    useEffect(() => {
        setEventAdjustments(ratePlan['adjustments-event'] || { events: [] });
        setIsActive(ratePlan['adjustments-event-active'] || false);
    }, [ratePlan]);

    const handleSwitchChange = (e) => {
        const newIsActive = e.target.checked;
        setIsActive(newIsActive);
        onChange({
            'adjustments-event-active': newIsActive
        });
    };

    const handleEventChange = (index, updatedEvent) => {
        const newEvents = [...eventAdjustments.events];
        newEvents[index] = updatedEvent;
        updateRatePlan({ ...eventAdjustments, events: newEvents });
    };

    const addEvent = () => {
        const newEvent = {
            active: true,
            mon: true, tue: true, wed: true, thu: true, fri: true, sat: true, sun: true,
            event_name: "", 
            start_date: new Date().toISOString().split('T')[0],
            end_date: new Date(Date.now() + 7 * 24 * 60 * 60 * 1000).toISOString().split('T')[0],
            rate_percentage: 100
        };
        const newEvents = [...eventAdjustments.events, newEvent];
        updateRatePlan({ ...eventAdjustments, events: newEvents });
        setOpenSnackbar(true);
    };

    const openRemoveDialog = (index) => {
        setEventToRemove(index);
        setOpenDialog(true);
    };

    const handleRemoveEvent = () => {
        if (eventToRemove !== null) {
            const newEvents = eventAdjustments.events.filter((_, i) => i !== eventToRemove);
            updateRatePlan({ ...eventAdjustments, events: newEvents });
            setOpenDialog(false);
            setEventToRemove(null);
        }
    };

    const updateRatePlan = (newAdjustments) => {
        onChange({
            'adjustments-event': newAdjustments
        });
    };

    return (
        <div className="flex flex-col mb-4">
            <div className="flex items-center mb-2">
                <div className="flex items-center">
                    <Typography className="!text-base mr-2">EVENT RULE</Typography>
                    <IconButton size="small"><InfoOutlined /></IconButton>
                </div>
                {/* <Switch 
                    checked={isActive} 
                    onChange={handleSwitchChange} 
                    sx={{ 
                        '& .MuiSwitch-switchBase.Mui-checked': { color: '#00b4b4' }, 
                        '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': { backgroundColor: '#00b4b4' } 
                    }} 
                /> */}
            </div>

            {eventAdjustments.events && eventAdjustments.events.map((event, index) => (
                <EventItem
                    key={index}
                    event={event}
                    onChange={(updatedEvent) => handleEventChange(index, updatedEvent)}
                    onRemove={() => openRemoveDialog(index)}
                    onAdd={addEvent}
                />
            ))}

            {(!eventAdjustments.events || eventAdjustments.events.length === 0) && (
                <IconButton onClick={addEvent} color="primary">
                    <AddCircleOutlineIcon className="!text-medium-aquamarine" />
                </IconButton>
            )}

            <NotificationSnackbar
                open={openSnackbar}
                onClose={() => setOpenSnackbar(false)}
                message="Please enter a name for the new event to save it."
            />

            <ConfirmDialog
                open={openDialog}
                onClose={() => setOpenDialog(false)}
                onConfirm={handleRemoveEvent}
                title="Confirm Removal"
                content="Are you sure you want to remove this event?"
            />
        </div>
    );
};

export default SeasonRule;