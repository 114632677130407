import React, { useState, useEffect } from 'react';
import { Schedull } from './components/Calendar/Schedull';
import DashboardLayout from 'components/LayoutContainers/DashboardLayout';
import DashboardNavbar from "components/Navbars/DashboardNavbar";
import { getStaff } from './services/serverApi.task';
import './Task.css';

function Scheduling() {
    const [staff, setStaff] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);
    const staging = JSON.parse(localStorage.getItem('isStaging')) || false;
    console.log("staging : ", staging);


    const fetchStaff = async () => {
        setIsLoading(true);
        try {
            const response = await getStaff();
            if (response.data.data && Array.isArray(response.data.data)) {
                setStaff(response.data.data);
            } else {
                setStaff([]);
            }
        } catch (error) {
            console.error('Error fetching staff:', error);
            setStaff([]);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        fetchStaff();
    }, []);

    return (
        <DashboardLayout>
            <DashboardNavbar />
            <div className="Task">
                <div className='hidden md:flex gap-3 absolute top-20 left-1/2 transform -translate-x-1/2'>
                    <div className='flex items-center gap-1'><span className='p-1 bg-blue-200 rounded-full '></span><span className='text-xs'>ASSIGNED</span></div>
                    <div className='flex items-center gap-1'><span className='w-2 h-2 bg-orange-200 rounded-full'></span><span className='text-xs'>UNASSIGNED</span></div>
                    <div className='flex items-center gap-1'><span className='w-2 h-2 bg-green-200 rounded-full'></span><span className='text-xs'>ACCEPTED</span></div>
                    <div className='flex items-center gap-1'><span className='w-2 h-2 bg-red-200 rounded-full'></span><span className='text-xs'>REFUSED</span></div>
                </div>
                <div className='md:hidden flex flex-wrap justify-center gap-2 p-2 bg-white border-b'>
                    <div className='flex items-center gap-1'><span className='p-1 bg-blue-200 rounded-full '></span><span className='text-xs'>ASSIGNED</span></div>
                    <div className='flex items-center gap-1'><span className='w-2 h-2 bg-orange-200 rounded-full'></span><span className='text-xs'>UNASSIGNED</span></div>
                    <div className='flex items-center gap-1'><span className='w-2 h-2 bg-green-200 rounded-full'></span><span className='text-xs'>ACCEPTED</span></div>
                    <div className='flex items-center gap-1'><span className='w-2 h-2 bg-red-200 rounded-full'></span><span className='text-xs'>REFUSED</span></div>
                </div>
                <div className="main-content mx-auto w-full max-w-full md:w-auto">
                        <Schedull staff={staff} staging={staging} />
                </div>
            </div>
        </DashboardLayout>
    );
}

export default Scheduling;